import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import customAxios from "../../../axios"
import noMedicalRecords from "./../../../assets/img/consultation/NoMedicalRecord.png"

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab"
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core"
import moment from "moment"
import { colors } from "../../../assets/css/colors"
import { getRandomColor } from "../../../views/helper"
function TimelineView({ patientId }) {
  const [timelineData, setTimelineData] = useState({})
  useEffect(() => {
    customAxios
      .put(`patients/analytics/${patientId}?page_no=1&page_size=100`)
      .then((res) => {
        if (res.data.success) {
          setTimelineData(res.data.data)
          console.log("res56", res.data.data)
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => toast.error("Some error occured"))
  }, [])
  return (
    <Grid item xs={12} container>
      <Grid item xs={12} style={{ marginBottom: "2rem" }}>
      <Typography variant="h6" style={{fontWeight: "600", fontSize: "16px"}}>Past Records</Typography>
      </Grid>
      {/* <Timeline align="right">
        {Object.keys(timelineData).map((ele, keyIdx) => {
          return timelineData[ele].map((file, eleIdx) => {
            return (
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography color="textSecondary">
                    {moment(ele).format("DD MMM, YYYY")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    style={{ background: getRandomColor(), cursor: "pointer" }}
                    title="Open in new tab"
                    onClick={() => window.open(file.url)}
                  >
                    {file.type === "consultation_report" ? (
                      <i
                        class="fas fa-file-prescription"
                        title="Prescription"
                      ></i>
                    ) : (
                      <i class="fas fa-file-alt" title={file.type}></i>
                    )}{" "}
                  </TimelineDot>
                  {keyIdx === Object.keys(timelineData).length - 1 &&
                  eleIdx === timelineData[ele].length - 1 ? null : (
                    <TimelineConnector />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Grid item style={{}}>
                    <Typography
                      variant="h6"
                      title="Click to open in new tab"
                      style={{ cursor: "pointer", position: "relative" }}
                      onClick={() => {
                        window.open(file.url)
                      }}
                    >
                      {file.name.length > 20
                        ? file.name.slice(0, 20) + "..."
                        : file.name}
                    </Typography>
                    <Typography color="secondary">
                      {" "}
                      {file.type === "consultation_report"
                        ? "Prescription"
                        : file.type}
                    </Typography>
                  </Grid>
                </TimelineContent>
              </TimelineItem>
            )
          })
        })}
      </Timeline> */}
      {Object.values(timelineData)?.length ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Record Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(timelineData)?.map((row, idx) => (
                      row?.map((row1, idx1) => (
                        
                      <TableRow
                        key={row1?.id + idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {new Date(row1?.date).toDateString()}
                        </TableCell>
                        <TableCell>{row1?.name}</TableCell>
                        <TableCell> 
                        {row1?.type === "consultation_report" ? (
                            <i
                              class="fas fa-file-prescription"
                              title="Prescription"
                            ></i>
                          ) : (
                            <i class="fas fa-file-alt" title={row1?.type}></i>
                          )}{" "}
                          {row1?.type}</TableCell>
                        <TableCell>
                          <Button 
                            color="primary"
                            outline
                            type="button"
                            pill
                            onClick={() => window.open(row1?.url)}
                          >
                            View Record
                          </Button>
                        </TableCell>
                      </TableRow>
                      ))
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <h3 style={{ textAlign: "center" }}>No Data Found</h3>
            )}
      {Object.keys(timelineData).length === 0 ? (
        <Grid container item xs={12} justifyContent="center">
          <img
            src={noMedicalRecords}
            alt="No medical record"
            style={{ objectFit: "contain", width: "30%" }}
          ></img>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default TimelineView
