import {
  Avatar,
  Backdrop,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core"
import Linkify from "react-linkify"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import doctorSocket from "../../../components/Dashboard/Home/AddAppointmentDialog/doctorSocket"

import { colors } from "../../../assets/css/colors"
import waLoaderAnimation from "./../../../assets/animationData/26025-chat-loader.json"
import dashboardSocket from "../../../socket/dashboardSocket"
import LoaderFullpage from "../../Common/LoaderFullpage"
import moment from "moment"
import customAxios from "../../../axios"
import { toast } from "react-toastify"
import GenericDialogForm from "../../CreatePrescription/GenericDialog"
import { useHistory } from "react-router"
import { Info, InfoOutlined, SettingsInputComponent } from "@material-ui/icons"
import whatsappBg from "./../../../assets/img/chatWindow/whatsappBg.png"
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab"

import stethoscopeIcon from "../../../assets/img/icons/common/stethoscope.png" 
import chatbotIcon from "../../../assets/img/icons/common/bot.png" 
import flashIcon from "../../../assets/img/icons/common/thunder.png" 
import doctorIcon from "../../../assets/img/icons/common/doctor.png" 

import notify from '../../../assets/audios/iphone_sound.mp3'

import {
  convertStringToHtml,
  createElementFromHTML,
  getRandomColor,
} from "../../../views/helper"
import { format, whatsappRules } from "@flasd/whatsapp-formatting"
import mixpanel from "mixpanel-browser"
import { useSelector } from "react-redux"
import GenericAnimation from "../../animation"
import ChatTextField from "./chatTextField"
const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiFab-root": {
      width: "40px",
      height: "40px",
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(-2.5),
      right: theme.spacing(1.5),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}))

function ChatWindow({ ...props }) {
  const classes = useStyles()
  const history = useHistory()
  const {
    patient,
    docId,
    isDoctor,
    setIsOpen,
    appointmentData = null,
    isConsultation,
    setModalData,
    initialMessage = "",
    setMessageLoading,
    messageLoading,
    setOpen = () => {},
    openDrawer,
    setOpenDrawer
  } = props
  const { _id: id } = patient
  const styles = {
    menuIcons: {
      fontSize: "16px",

      color: colors.primary,
      borderRadius: "12px",
    },
    menuItem: {
      color: colors.primary,
    },
  }
  const toastId = useRef(null)
  const [paymentModalOpen, setPaymentModalOpen] = useState(false)
  const [currPage, setCurrPage] = useState(1)
  const sendWhatsAppBookingLink = () => {
    customAxios
      .get(`/patients/send-message/${patient?.phoneNo}`, {})
      .then((res) => {
        if (res?.data?.success) {
          handleSendMessage("link", "wa_link")
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => toast.error("Some error occured"))
  }
  const handleGoogleAuth = () => {
    customAxios
      .post("/auth/authorize-user")
      .then((res) => {
        if (res.data.success) {
          window.open(res.data.data)
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        toast.error("Some error occured")
      })
  }
  const createGmeetLink = () => {
    doctorSocket.emit("create_gmeet", {
      userId: docId,
      userType: isDoctor ? "doctor" : "staff",
      appointmentId: appointmentData?._id,
    })
    doctorSocket.once("create_gmeet", (args) => {
      const text = `Please join the video call using the following link: ${args?.entryPoints[0]?.uri}`
      handleSendMessage(text)
    })
  }
  const [menuOpen, setMenuOpen] = useState(false)
  const [messageList, setMessageList] = useState([])
  const user = useSelector((state) => state.user.user)
  const [menuActionsAddOn, setMenuActionsAddOn] = useState([])
  useEffect(() => {
    if(user?.reviewLink){
      setMenuActionsAddOn([
        {
          icon: "fa-solid fa-link",
          label: "Send Google review Link",
          onClick: () => {
            mixpanel.track("Google review link on textbox")
            handleSendMessage(user?.reviewLink)
          }
        }
      ])
    }
  }, [user, messageList])

  const menuActions = [
    // {
    //   icon: "fas fa-rupee-sign",
    //   label: "Request Payment",
    //   onClick: () => {
    //     setPaymentModalOpen(true)
    //   },
    // },
    {
      icon: "fa-solid fa-clock",
      label: "Schedule Appointment",
      onClick: () => {
        mixpanel.track("Appointment Schedule clicked through chat window")
        setModalData({ ...patient, patientId: patient._id })
        setOpen(true)
      },
    },
    {
      icon: "fa-solid fa-paper-plane",
      label: "Send Booking Link",
      onClick: () => {
        mixpanel.track("Booking link sent rhough chat window")
        sendWhatsAppBookingLink()
      },
    },
    ...menuActionsAddOn
  ]
 

  const [text, setText] = useState("")
  useEffect(() => {
    if (isConsultation) setText("Hey, let's start the online consultation")
    else setText("")
  }, [isConsultation])
  const fileUploaderRef = useRef(null)
  const handleReadMessages = () => {
    dashboardSocket.emit("read_messages", {
      userId: docId,
      patientId: patient?._id,
      userType: isDoctor ? "doctor" : "staff",
    })
  }
  const scrollToBottom = (id = "messageContainer") => {
    let objDiv = document.getElementById(id)
    if (objDiv) {
      // objDiv.scrollTo(0, objDiv.scrollHeight + 200)
      // objDiv.scrollIntoView(false)
      // objDiv.scrollIntoView()
      objDiv.scrollIntoView({ behavior: 'instant', block: 'center' })

      // objDiv.scrollTop = objDiv?.scrollHeight + 200
    }
  }

  const getMessagesSocket = (pageNo = 1, pageSize = 50) => {
    setCurrPage(pageNo)
    setMessageLoading(true)
    dashboardSocket.emit("get_messages", {
      userId: docId,
      userType: isDoctor ? "doctor" : "staff",
      patientId: patient?._id,
      pageNo: pageNo,
      pageSize: pageSize,
    })
    dashboardSocket.off("get_messages").on("get_messages", (args) => {
      setMessageLoading(false)

      // if (args.check === "new_message") {
      //   const audio = new Audio(notify)
      //   const promise = audio.play()
      //   if (promise !== undefined) {
      //     // On older browsers play() does not return anything, so the value would be undefined.
      //     promise
      //       .then(() => {
      //         // Audio is playing.
      //       })
      //       .catch((error) => {
      //         console.log(error)
      //       })
      //   }
      // }

      if (
        args?.data[0]?.from === patient?._id ||
        args.data[0]?.to === patient?._id
      ) {
        console.log("quo1")
        if (args.check === "new_message") {
          console.log("quo2")

          setMessageList((messageList) => [
            ...messageList,
            args.data.slice()[0],
          ])
          scrollToBottom("messageContainer")
        } else {
          console.log("quo3")
          if (args.pageNo === 1) {
            setMessageList([...args.data.slice().reverse()])
            scrollToBottom("messageContainer")
          } else {
            setMessageList((messageList) => [
              ...args.data.slice().reverse(),
              ...messageList.slice(),
            ])
          }
        }
      }
      handleReadMessages()
    })
  }
  useEffect(() => {
    if (id) {
      console.log("123", id)
      setMessageList([])
      getMessagesSocket()
    }
  }, [id])
  const uploadFiles = async (files) => {
    let tempArr = []
    if (files) {
      for (const file of files) {
        let fileSize = file.size / 1024 / 1024
        if (fileSize > 50) toast.error("File size cannot be greater than 50Mb")
        else {
          // const config = {
          //   onUploadProgress: (p) => {
          //     const progress = p.loaded / p.total;

          //     // check if we already displayed a toast
          //     if (toastId.current === null) {
          //       toastId.current = toast("Upload in Progress", {
          //         hideProgressBar: false,
          //         progress,
          //       });
          //     } else {
          //       toast.update(toastId.current, { progress });
          //     }
          //   },
          // };
          let reader = new FileReader()
          setMessageLoading(true)
          reader.onload = function (e) {
            customAxios
              .put(`/auth/upload-document`, {
                document: {
                  file: e.target.result,
                  name: file.name,

                  type: "Addtional Docs",
                  extension: file.type,
                },
                other_data: {
                  patientId: patient?._id,
                  userId: docId,
                  userType: isDoctor ? "doctor" : "staff",
                },
                location: `chat_files/${docId}/${patient._id}/`,
              })
              .then((res) => {
                if (res.data.success) {
                  setMessageLoading(false)

                  handleSendMessage({ ...res.data.data }, file.type)
                  toast.done(toastId.current)
                } else {
                  toast.error("Some error occured")
                }
              })
              .catch((err) => {
                console.log(err)
                toast.error("Some error occured")
              })

            tempArr.push({
              file: e.target.result,
              name: file.name,

              type: "Addtional Docs",
              extension: file.type,
            })
          }

          reader.readAsDataURL(file)
        }
      }
    }
  }
  const getMessageContainer = (chat) => {
    switch (chat?.userType) {
      case "patient":
        return (
          <Grid container xs={12} justifyContent="flex-start">
            <Grid
              item
              style={{
                marginBottom: "1rem",
                maxWidth: "500px",
                textAlign: "left",
              }}
            ></Grid>
          </Grid>
        )
      case "doctor":
        return (
          <Grid container xs={12} justifyContent="flex-end">
            <Grid
              item
              style={{
                marginBottom: "1rem",
                maxWidth: "500px",
                textAlign: "right",
              }}
            ></Grid>
          </Grid>
        )
      case "staff":
        return (
          <Grid container xs={12} justifyContent="flex-end">
            <Grid
              item
              style={{
                marginBottom: "1rem",
                maxWidth: "500px",
                textAlign: "right",
              }}
            ></Grid>
          </Grid>
        )
      default:
        return null
    }
  }

  const theme = useTheme()

  const getLatestChatMessage = (chat, idx) => {
    const contentType = chat?.["contentType"]
    const userType = chat?.["userType"]
    switch (contentType) {
      case "patient_interact":
        return (
          <Grid container xs={12} justifyContent="center" key={idx}>
            <Grid
              item
              style={{
                marginBottom: "1rem",
                maxWidth: "512px",
                textAlign: "left",
              }}
            >
              <div
                style={{
                  borderRadius: "7.5px",

                  backgroundColor: colors.primary,
                  padding: "1rem",
                  width: "fit-content",
                  // position: "relative",
                  color: "white",
                }}
              >
                <i
                  class="fab fa-whatsapp"
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Patient has interacted with your WhatsApp Clinic. Click{" "}
                <span
                  onClick={() => {
                    sendWhatsAppBookingLink()
                  }}
                  style={{
                    color: "aliceblue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  here
                </span>{" "}
                to send appointment booking link to this patient.
                <span
                  style={{
                    fontSize: "12px",
                    marginLeft: "10px",
                    color: "#c4c8c7",
                  }}
                >
                  {moment.utc(chat?.date).format("hh:mm A")}
                </span>{" "}
              </div>
            </Grid>
          </Grid>
        )

      case "text":
        return (
          <Grid
            container
            xs={12}
            spacing={1}

            key={idx}
            justifyContent={userType === "patient" ? "flex-start" : "flex-end"}
          >
            <Grid
              item
              style={{
                marginBottom: "1rem",
                maxWidth: "500px",
                textAlign: "left",
                paddingRight: "1rem"
              }}
            >
              <div
                style={{
                  borderRadius: userType === 'doctor' ? "10px 0.5px 10px 10px" : "0.5px 10px 10px 10px",

                  backgroundColor:
                    userType === "doctor"
                      ? "#f3f3f3"
                      : userType === "staff"
                      ? colors.outgoingMessage
                      : "#85C1E9",
                  padding: "2rem",
                  paddingRight: chat?.content?.length < 70 ? "3rem" : "2rem",
                  width: "fit-content",
                  position: "relative",
                  color: userType === "doctor" ? "black" : "black",
                  opacity: userType === 'patient' ? "65%" : "100%",
                  fontWeight: userType === 'patient' ? 600 : null,
                  // fontWeight: 600
                }}
              >
                {/* <Tooltip
                  title={
                    <div>
                      <Typography>
                        {chat.status==='read'
                          ? `Read  at ${moment
                              .utc(chat.readAt)
                              .format("HH:mm A")}`
                          : "Delivered"}
                      </Typography>
                    </div>
                  }
                  placement="top"
                >
                  <IconButton
                    onMouseOver={() => {}}
                    style={{
                      position: "absolute",
                      right: userType === "patient" ? "-3rem" : "inherit",
                      left: userType === "patient" ? "inherit" : "-3rem",
                    }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </IconButton>
                </Tooltip> */}
                {typeof chat?.content === "object" ? (
                  <>
                    Unsupported Document{" "}
                    <i
                      class="fas fa-exclamation-circle"
                      style={{
                        color: "red",
                        marginLeft: "0.2rem",
                        fontSize: "16px",
                      }}
                    ></i>{" "}
                    <br />
                    The following type of document is currently not supported.
                  </>
                ) : (
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                    textDecorator={(text) => (
                      <div style={{ color: "#53bdeb", display: "flex" }}>
                        {text}
                      </div>
                    )}
                  >
                    {" "}
                    {parse(format(chat?.content))}
                  </Linkify>
                )}
                <br />
                <div
                  style={{
                    textAlign: userType === "doctor" ? "right" : "right",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      marginLeft: "10px",
                      color: userType === "doctor" ? "grey" : "grey",
                      textAlign: userType === "patient" ? "left" : "right",
                      position: "absolute",
                      right: 10,
                      bottom: chat?.content?.length < 3 ? 0 : 10,
                      // paddingBottom: "-1rem"
                      display: "flex",
                      maxWidth: "120px"
                    }}
                  >
                    {moment.utc(chat?.date).format("hh:mm A")}
                    {userType !== "patient" ? (
                      <i
                        class="fa-solid fa-check-double"
                        style={{
                          fontSize: "14px",
                          marginLeft: "0.5rem",
                          color: chat?.templateStatus
                            ? chat?.templateStatus === "read"
                              ? "#53BDEB"
                              : "#f3f3f3"
                            : chat.status === "read"
                            ? "#53BDEB"
                            : "#f3f3f3",
                        }}
                      ></i>
                    ) : null}
                  </span>{" "}
                </div>
              </div>
              {/* {chat?.waId ? (
                <Typography style={{display: "flex", alignItems: "center", opacity: "80%", "fontSize": "12px", marginTop: "5px"}}>
                  <InfoOutlined fontSize="small" style={{marginRight: "5px"}} /> Sent By Your Whatsapp Clinic
                </Typography>
              ) : null} 98979897check*/}
            </Grid>
            
            {userType !== "patient" ? <Grid item>
             {chat?.quick ? (<Avatar style={{opacity: "40%"}} title="Message sent by quick replies">
                  <img src={flashIcon} style={{objectFit: "contain"}} height={"30px"} width={"30px"}/>
                </Avatar>) :
             (
              <Avatar style={{opacity: "70%"}} title={!chat?.waId ? "Message sent by you" : "Message sent by your AI Whatsapp assistant"}>
              <img src={!chat?.waId ? doctorIcon : chatbotIcon} style={{objectFit: "contain"}} height={"30px"} width={"30px"}/>
            </Avatar>
               )
              } 
            </Grid> : null}
          </Grid>
        )

      case "wa_link":
        return (
          <Grid container xs={12} justifyContent="center" key={idx}>
            <Grid
              item
              style={{
                marginBottom: "1rem",
                maxWidth: "500px",
                textAlign: "left",
              }}
            >
              <div
                style={{
                  borderRadius: "7.5px",

                  backgroundColor: colors.primary,
                  padding: "1rem",
                  width: "fit-content",
                  // position: "relative",
                  color: "white",
                }}
              >
                <i class="fas fa-link" style={{ marginRight: "0.4rem" }}></i>{" "}
                Booking Link Sent to WhatsApp
                <span
                  style={{
                    fontSize: "12px",
                    marginLeft: "10px",
                    color: "white",
                  }}
                >
                  {moment.utc(chat?.date).format("hh:mm A")}
                </span>{" "}
              </div>
            </Grid>
          </Grid>
        )
      case "jitsilink":
        return (
          <Grid
            container
            xs={12}
            key={idx}
            justifyContent={userType === "patient" ? "flex-start" : "flex-end"}
          >
            <Grid
              item
              style={{
                marginBottom: "1rem",
                maxWidth: "500px",
                textAlign: "left",
              }}
            >
              <div
                style={{
                  borderRadius: "7.5px",

                  backgroundColor:
                    userType === "doctor"
                      ? colors.staffOutgoingMessage
                      : userType === "staff"
                      ? colors.outgoingMessage
                      : "#f3f3f3",
                  padding: "0.5rem",
                  width: "fit-content",
                  position: "relative",
                  color: userType === "doctor" ? "white" : "black",
                }}
              >
                {typeof chat?.content === "object" ? (
                  <>
                    Unsupported Document{" "}
                    <i
                      class="fas fa-exclamation-circle"
                      style={{
                        color: "red",
                        marginLeft: "0.2rem",
                        fontSize: "16px",
                      }}
                    ></i>{" "}
                    <br />
                    The following type of document is currently not supported.
                  </>
                ) : (
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                    textDecorator={(text) => (
                      <div style={{ color: "#53bdeb", display: "flex" }}>
                        {text}
                      </div>
                    )}
                  >
                    {" "}
                    {parse(format(chat?.content))}
                  </Linkify>
                )}
                <br />
                <div
                  style={{
                    textAlign: userType === "doctor" ? "right" : "right",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      marginLeft: "10px",
                      color: userType === "doctor" ? "white" : "grey",
                      textAlign: userType === "patient" ? "left" : "right",
                    }}
                  >
                    {moment.utc(chat?.date).format("hh:mm A")}
                    {userType !== "patient" ? (
                      <i
                        class="fa-solid fa-check-double"
                        style={{
                          fontSize: "14px",
                          marginLeft: "0.5rem",
                          color: chat.status === "read" ? "#53BDEB" : "#f3f3f3",
                        }}
                      ></i>
                    ) : null}
                  </span>{" "}
                </div>
              </div>
            </Grid>
          </Grid>
        )

      default:
        const mimeType = contentType?.split("/")[0]

        switch (mimeType) {
          case "image":
            return (
              <Grid
                container
                xs={12}
                key={idx}
                justifyContent={
                  userType === "patient" ? "flex-start" : "flex-end"
                }
              >
                <Grid
                  item
                  style={{
                    marginBottom: "1rem",
                    maxWidth: "500px",
                    backgroundColor:
                      userType === "doctor"
                        ? colors.staffOutgoingMessage
                        : userType === "staff"
                        ? colors.outgoingMessage
                        : "#f3f3f3",
                    padding: "1rem",
                    color: userType === "doctor" ? "white" : "black",
                    borderRadius: "7.5px",

                    textAlign: userType === "patient" ? "left" : "right",
                  }}
                >
                  <div
                    onClick={() => {
                      window.open(chat?.content?.url)
                    }}
                  >
                    <img
                      src={chat.content.url}
                      type={chat?.contentType}
                      alt={chat?.content?.name}
                      style={{
                        width: "300px",
                        objectFit: "contain",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    />
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        marginLeft: "10px",
                        color: userType === "doctor" ? "white" : "grey",
                      }}
                    >
                      {moment.utc(chat?.date).format("hh:mm A")}
                      {userType !== "patient" ? (
                        <i
                          class="fa-solid fa-check-double"
                          style={{
                            fontSize: "14px",
                            marginLeft: "0.5rem",
                            color:
                              chat.status === "read" ? "#53BDEB" : "#f3f3f3",
                          }}
                        ></i>
                      ) : null}
                    </span>{" "}
                  </div>
                </Grid>
              </Grid>
            )
          case "video":
            return (
              <Grid
                key={idx}
                container
                xs={12}
                justifyContent={
                  userType === "patient" ? "flex-start" : "flex-end"
                }
              >
                <Grid
                  item
                  style={{
                    marginBottom: "1rem",
                    maxWidth: "500px",
                    color: userType === "doctor" ? "white" : "black",
                    borderRadius: "7.5px",

                    backgroundColor:
                      userType === "doctor"
                        ? colors.staffOutgoingMessage
                        : userType === "staff"
                        ? colors.outgoingMessage
                        : "#f3f3f3",
                    padding: "1rem",

                    textAlign: userType === "patient" ? "left" : "right",
                  }}
                >
                  <div
                    onClick={() => window.open(chat?.content?.url)}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      class="fas fa-video"
                      style={{ marginRight: "0.4rem" }}
                    ></i>
                    {chat?.content?.name.length > 40
                      ? chat?.content?.name.substring(0, 40) + "..."
                      : chat?.content?.name}{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        marginLeft: "10px",
                        color: userType === "doctor" ? "white" : "grey",
                      }}
                    >
                      {moment.utc(chat?.date).format("hh:mm A")}
                      {userType !== "patient" ? (
                        <i
                          class="fa-solid fa-check-double"
                          style={{
                            fontSize: "14px",
                            marginLeft: "0.5rem",
                            color:
                              chat.status === "read" ? "#53BDEB" : "#f3f3f3",
                          }}
                        ></i>
                      ) : null}
                    </span>{" "}
                  </div>
                </Grid>
              </Grid>
            )
          case "invoice":
            return (
              <Grid container xs={12} justifyContent="center" key={idx}>
                <Grid
                  item
                  style={{
                    marginBottom: "1rem",
                    maxWidth: "600px",
                    textAlign: "center  ",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "7.5px",

                      backgroundColor: "#fff",
                      padding: "1rem",
                      width: "fit-content",
                      // position: "relative",
                      border: `1px solid ${colors.primary}`,
                      color: "black",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          textAlign: "center",
                          // display: "flex",
                          // justifyContent: "center",
                        }}
                      >
                        <i
                          class={
                            chat.content.status !== "paid"
                              ? "fas fa-clock"
                              : "fas fa-check-circle"
                          }
                          style={{
                            fontSize: "24px",
                            marginRight: "0.4rem",
                            color: colors.primary,
                          }}
                        ></i>{" "}
                      </div>
                      Payment{" "}
                      {chat.content.status !== "paid" ? (
                        <span style={{ fontWeight: "700" }}>Requested</span>
                      ) : (
                        <span
                          style={{
                            color: colors.primary,
                            fontWeight: "700",
                          }}
                        >
                          Received
                        </span>
                      )}
                      <br />
                      <Typography>Amount- ₹{chat.content.amount}</Typography>
                      {chat?.content?.reason ? (
                        <Typography> Reason- {chat.content.reason}</Typography>
                      ) : null}
                      <span
                        style={{
                          fontSize: "12px",
                          marginLeft: "10px",
                          color: "grey",
                        }}
                      >
                        {moment.utc(chat?.date).format("hh:mm A")}
                      </span>{" "}
                    </div>
                  </div>
                </Grid>
              </Grid>
            )

          case "audio":
            return (
              <Grid
                key={idx}
                container
                xs={12}
                justifyContent={
                  userType === "patient" ? "flex-start" : "flex-end"
                }
              >
                <Grid
                  item
                  style={{
                    borderRadius: "7.5px",

                    marginBottom: "1rem",
                    maxWidth: "500px",
                    color: userType === "doctor" ? "white" : "black",
                    // borderRadius: "7.5px",

                    backgroundColor:
                      userType === "doctor"
                        ? colors.staffOutgoingMessage
                        : userType === "staff"
                        ? colors.outgoingMessage
                        : "#f3f3f3",
                    padding: "1rem",
                    textAlign: userType === "patient" ? "left" : "right",
                  }}
                >
                  <div
                    onClick={() => {
                      window.open(chat?.content?.url)
                    }}
                  >
                    <audio controls>
                      <source src={chat.content.url} type={chat?.contentType} />
                    </audio>
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        marginLeft: "10px",
                        color: userType === "doctor" ? "white" : "grey",
                      }}
                    >
                      {moment.utc(chat?.date).format("hh:mm A")}
                      {userType !== "patient" ? (
                        <i
                          class="fa-solid fa-check-double"
                          style={{
                            fontSize: "14px",
                            marginLeft: "0.5rem",
                            color:
                              chat.status === "read" ? "#53BDEB" : "#f3f3f3",
                          }}
                        ></i>
                      ) : null}
                    </span>{" "}
                  </div>
                </Grid>
              </Grid>
            )

          default:
            return (
              <Grid
                key={idx}
                container
                xs={12}
                justifyContent={
                  userType === "patient" ? "flex-start" : "flex-end"
                }
              >
                <Grid
                  item
                  style={{
                    marginBottom: "1rem",
                    maxWidth: "500px",
                    backgroundColor:
                      userType === "doctor"
                        ? colors.staffOutgoingMessage
                        : userType === "staff"
                        ? colors.outgoingMessage
                        : "#f3f3f3",
                    padding: "1rem",
                    borderRadius: "7.5px",
                    color: userType === "doctor" ? "white" : "black",

                    textAlign: userType === "patient" ? "left" : "right",
                  }}
                >
                  {" "}
                  <div
                    onClick={() => window.open(chat?.content?.url)}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      class="fas fa-file-alt"
                      style={{ marginRight: "0.4rem" }}
                    ></i>{" "}
                    {chat?.content?.name?.length > 40
                      ? chat?.content?.name.substring(0, 40) + "..."
                      : chat?.content?.name}
                  </div>
                  <div
                    style={{
                      textAlign: userType === "doctor" ? "right" : "right",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        marginLeft: "10px",
                        color: userType === "doctor" ? "white" : "grey",
                        textAlign: userType === "patient" ? "left" : "right",
                      }}
                    >
                      {moment.utc(chat?.date).format("hh:mm A")}
                      {userType !== "patient" ? (
                        <i
                          class="fa-solid fa-check-double"
                          style={{
                            fontSize: "14px",
                            marginLeft: "0.5rem",
                            color:
                              chat.status === "read" ? "#53BDEB" : "#f3f3f3",
                          }}
                        ></i>
                      ) : null}
                    </span>{" "}
                  </div>
                </Grid>
              </Grid>
            )
        }
    }
  }


  const handleSendMessage = (message, type = "text", quick = false) => {
    const messageObject = {
      userType: isDoctor ? "doctor" : "staff",
      from: docId,
      to: patient?._id,
      content: message,
      contentType: type,
      date: moment(new Date()).format(`YYYY-MM-DDTHH:mm:ss.SSS`),
      quick: quick
    }
    messageObject.date = messageObject.date + "Z"
    if (type !== "wa_link") dashboardSocket.emit("send_message", messageObject)

    let tempArr = [...messageList.slice()]
    tempArr.push(messageObject)
    mixpanel.track("Message sent in chat", { ...messageObject })
    setMessageList([...tempArr.slice()])
    scrollToBottom("messageContainer")
  }

  useEffect(() => {
    scrollToBottom()
  }, [messageList])

  const getChatTime = (date) => {
    const yesterday = moment.utc().subtract(1, "days")

    if (moment.utc(date).diff(yesterday, "days") === 0) return "Yesterday"
    else if (moment.utc(date).diff(yesterday, "days") < 0)
      return moment.utc(date).format("DD MMM YYYY")
    else return "Today"
  }

  return (
    <Grid
      item
      container
      xs={12}
      style={{ position: "relative" }}
      // justifyContent="space-between"
      direction="row"
    >
      <Grid
        item
        container
        xs={12}
        onClick={() => {
          // mixpanel.track("Profile view through chat window")
          // history.push(`/admin/patientProfile/${patient._id}`)
          setOpenDrawer(true)
        }}
        spacing={2}
        style={{
          position: "sticky",
          top: "0",
          cursor: "pointer",
          zIndex: "100",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
          background: "#f3f3f3",
          padding: "0.5rem",
          height: "70px",
        }}
      >
        <Grid item>
          <Avatar
            style={{
              backgroundColor: patient?.waChatColor,
              height: "40px",
              width: "40px",
            }}
          >
            {patient?.name?.split("")[0]}
          </Avatar>
        </Grid>
        <Grid item style={{ fontFamily: "Inter !important" }}>
          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
            {patient?.name}
          </Typography>
          {patient.name.includes("+") ? null : (
            <Typography variant="body1" color="textSecondary">
              {patient.phoneNo}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
          // id="messageContainer"
          justifyContent=""
        style={{
          padding: "1rem 2rem",
          margin: "1rem 0 0.3rem 0",
          overflowY: "auto",
          height: "100%",

          // backgroundImage: `url(${whatsappBg})`,
          // backgroundSize: "cover",
          // backgroundAttachment: "fixed",
          // backgroundRepeat: "no-repeat",
          overflowX: "hidden",
          // paddingBottom: "-900px"
        }}
      >
         {!messageLoading ? <Grid
          container
          item
          xs={12}
          direction="row"
          // spacing={2}
          style={{
            height: "100%",
            // top: "10px",
            // marginBottom: "-100px"
          }}
        >
          <Grid item xs={12} style={{ textAlign: "center", margin: "1rem 0" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                getMessagesSocket(currPage + 1)
              }}
            >
              Load More!
            </Button>
          </Grid>
          <Grid item xs={12} style={{ margin: "1rem 0" }}>
            <Typography align="center" variant="h6">
              {getChatTime(messageList[0]?.date)}
            </Typography>
          </Grid>
          {messageList?.map((ele, idx) => {
            if (
              idx !== 0 &&
              getChatTime(ele.date) !== getChatTime(messageList[idx - 1].date)
            )
              return (
                <Grid container item xs={12} key={idx}>
                  <Grid item xs={12} style={{ margin: "1rem 0" }}>
                    <Typography align="center" variant="h6">
                      {getChatTime(ele.date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {getLatestChatMessage(ele)}
                  </Grid>
                </Grid>
              )
            else
              return (
                <Grid item xs={12} style={{ position: "relative" }}>
                  {getLatestChatMessage(ele, idx)}
                  {/* <Tooltip
                    title={
                      <div>
                        <Typography>
                          {ele.readAt
                            ? `Read  at ${moment
                                .utc(ele.readAt)
                                .format("HH:mm A")}`
                            : "Delivered"}
                        </Typography>
                      </div>
                    }
                    placement="top"
                  >
                    <IconButton
                      onMouseOver={() => {}}
                      style={{
                        position: "absolute",
                        top: "1rem",
                        left: ele.userType === "patient" ? 0 : "inherit",
                        right: ele.userType === "patient" ? "inherit" : "0",
                      }}
                    >
                      <i class="fas fa-info-circle"></i>
                    </IconButton>
                  </Tooltip> */}
                </Grid>
              )
          })}
            <Grid item id="messageContainer"></Grid>
        </Grid> : <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <GenericAnimation
                  animationData={waLoaderAnimation}
                  height={300}
                  width={300}
                />
          </Grid>
          </Grid>}
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        alignItems="center"
        style={{
          position: "sticky",
          bottom: 0,
          background: "#f3f3f3",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
          height: "150px",
          // height: isConsultation ? "140px" : "100px",
          padding: "1rem",
        }}
      >
        {isConsultation ? (
          <Grid container item xs={12} justifyContent="center">
            <Grid item container alignItems="center" md={4}>
              <Typography>Send video call link to patient</Typography>
              <IconButton
                color="primary"
                title="Video call"
                onClick={() => {
                  if (appointmentData?.link) {
                    const text = `Please join the video call using the following link: ${appointmentData.link}`
                    handleSendMessage(text)
                  } else {
                    doctorSocket.emit("check_gauth", {
                      userId: docId,
                      userType: isDoctor ? "doctor" : "staff",
                    })
                    doctorSocket.once("check_gauth", (args) => {
                      if (args) createGmeetLink()
                      else {
                        toast.info(
                          "Please choose a gmail id on the next screen."
                        )

                        setTimeout(() => handleGoogleAuth(), 3000)
                      }
                    })
                  }
                }}
              >
                <i class="fas fa-video"></i>
              </IconButton>
            </Grid>
            <Grid item container alignItems="center" md={4}>
              <Typography>Write Prescription/End consultation</Typography>
              <IconButton
                color="primary"
                title="Write Prescription"
                onClick={() => {
                  history.push(`/admin/consultation/${appointmentData?._id}`)
                }}
              >
                <i class="fas fa-file-prescription"></i>{" "}
              </IconButton>
            </Grid>
          </Grid>
        ) : null}
        {/* <Grid item xs={2} md={1} style={{ position: "relative" }}> */}
          {/* <div
            style={{
              width: "250px",
              position: "absolute",
              minHeight: "250px",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "white",
              gap: "1.5rem",
              zIndex: "120",
              padding: "1rem",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              bottom: "5rem",
              left: "0",
              borderRadius: "8px",
              transition: "all 0.5s ease",
              display: menuOpen ? "flex" : "none",
            }}
            onClick={() => setMenuOpen(false)}

            // onMouseOut={() => setMenuOpen(false)}
          >
            {menuActions.map((ele, idx) => (
              <Button
                fullWidth
                key={idx}
                // style={{ marginBottom: "1rem" }}
                // variant="outlined"
                startIcon={
                  <i className={ele?.icon} style={{ fontSize: "18px" }} />
                }
                color="primary"
                onClick={() => ele?.onClick()}
              >
                {ele?.label}
              </Button>
            ))}
          </div> */}
          {/* <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={() => setMenuOpen(false)}
            onOpen={() => setMenuOpen(true)}
            open={menuOpen}
            direction="up"
          >
            {menuActions.map((action) => (
              <SpeedDialAction
                key={action.label}
                icon={
                  <i
                    className={action?.icon}
                    style={{ fontSize: "20px", color: getRandomColor() }}
                  />
                }
                tooltipTitle={action.label}
                onClick={() => {
                  setMenuOpen(false)
                  action.onClick()
                }}
              />
            ))}
          </SpeedDial> */}
          {/* <IconButton
            color="primary"
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            <i
              class={
                menuOpen
                  ? "fa-solid fa-circle-chevron-down"
                  : "fa-solid fa-circle-chevron-up"
              }
            ></i>{" "}
          </IconButton> */}
        {/* </Grid> */}
        <input
          onChange={(e) => {
            uploadFiles(e.target.files)
          }}
          hidden
          label="additionalFiles"
          ref={fileUploaderRef}
          title="FilesUploader"
          type="file"
        ></input>
        {/* <Grid item xs={2} md={1}>
          <IconButton
            color="primary"
            onClick={() => fileUploaderRef?.current?.click()}
          >
            <i class="fas fa-paperclip"></i>
          </IconButton>
        </Grid> */}
        {/* <Grid item xs={8} md={9}>
          <TextField
            placeholder="Type a message"
            variant="outlined"
            autoFocus={true}
            value={text}
            onKeyDown={(e) => {
              if (e.key === "Enter" && text) {
                handleSendMessage(text)
                setText("")
              }
            }}
            onChange={(e) => setText(e.target.value)}
            InputProps={{
              endAdornment: text ? (
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (text) {
                      handleSendMessage(text)
                      setText("")
                    }
                  }}
                >
                  <i class="fas fa-paper-plane"></i>
                </IconButton>
              ) : null,
              // <IconButton color="primary">
              //   <i class="fas fa-microphone"></i>
              // </IconButton>
            }}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12} container alignItems="center">
          <ChatTextField handleSendMessage={handleSendMessage} patient={patient} menuActions={menuActions} onFileUpload={() => fileUploaderRef?.current?.click()}/>
        </Grid>
      </Grid>

      {paymentModalOpen ? (
        <GenericDialogForm
          style={{ overflowY: "visible" }}
          dialogTitle={"Request Payment"}
          autoClose={true}
          showRequired={true}
          dialogContent={[
            {
              label: "Amount",
              key: "amount",
              type: "number",
              required: true,
            },
            {
              label: "Bill For",
              key: "reason",
              required: true,
              md: 6,
              type: "autocomplete",
              options: ["Service", "Vaccination", "X-Ray", "Consultation"],
            },
            {
              label: "Details",
              key: "details",
              type: "text",
              md: 12,
              placeholder: "Eg: 15 sessions of Miniscus tear physiotherapy",
              required: false,
            }
          ]}
          formData={{ reason: "Consultation" }}
          open={paymentModalOpen}
          handleSubmit={(data) => {
            handleSendMessage({ ...data }, "invoice")
          }}
          handleClose={() => setPaymentModalOpen(false)}
        />
      ) : null}
    </Grid>
  )
}

export default ChatWindow
