import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";

import Sidebar from "./Sidebar";
import AddVitals from "./AddVitals";
import MedicalHistory from "./MedicalHistory";
import MedicalRecord from "./MedicalRecord";
import History from "./History";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom"
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

function AllPatients() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation()

  const [patientData, setPatientData] = useState({})

  const getActiveTab = (activeTab) => {
    switch (activeTab) {
      case "Add Vitals":
        return (
          <AddVitals state={state} setState={setState} id={id} setLoading={setLoading}></AddVitals>
        );

      case "Patient Assessment":
        return (
          <MedicalHistory
            state={state}
            loading={loading}
            setloading={setLoading}
            setState={setState}
            id={id}
          ></MedicalHistory>
        );

      case "Medical Record":
        return (
          <MedicalRecord
            state={state}
            loading={loading}
            setLoading={setLoading}
            setState={setState}
            id={id}
          ></MedicalRecord>
        );

      case "Appointment History":
        return (
          <History
            state={state}
            setState={setState}
            loading={loading}
            setLoading={setLoading}
            id={id}
            patient={patientData}
          ></History>
        );

      default:
        return <h1>Default</h1>;
    }
  };
  const [state, setState] = useState(
    "example of data passing through all tabs"
  );
  const [activeTab, setActiveTab] = useState("Add Vitals");

  useEffect(() => {
    if(location?.state?.activeStep)
      setActiveTab(location?.state?.activeStep);

  }, [location])

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Row style={{ marginRight: "0px" }}>
        <Sidebar
          setActiveTab={setActiveTab}
          id={id}
          activeTab={activeTab}
          setLoading={setLoading}
          patientData={patientData}
          setPatientData={setPatientData}
        ></Sidebar>

        <Col
          style={{
            paddingTop: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
          }}
        >
          <Card
            style={{ overflowY: "auto", maxHeight: "80vh", height: "80vh" }}
          >
            {getActiveTab(activeTab)}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AllPatients;
