import { Avatar, Chip, Grid, InputAdornment, Menu, MenuItem, TextField, Typography, useTheme } from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import React, { useEffect, useRef, useState } from "react"
import Autosuggest from "react-autosuggest"
import prescriptionSocket from "../../../socket"
import capsuleLogo from "../../../assets/img/icons/common/pill-icon.png"
import gelLogo from "../../../assets/img/icons/common/alcohol-gel.png"
import syrupLogo from "../../../assets/img/icons/common/syrup.png"
import syringeLogo from "../../../assets/img/icons/common/syringe.png"
import { labResultConstantData } from "../../CreatePrescription/LabResults/labResultConstants"
import { useSelector } from "react-redux"


function PrescriptionAutocomplete({ field, inputProps, inputRef, onKeyDown, frequentOptions, suggestedOptions, tableData, activeIndex, setActiveIndex, textFieldsRefs, cardKey, cardState, setCardState }) {
  const [suggestions, setSuggestions] = useState([])
  const theme  = useTheme()

  const docId = useSelector((state) => state?.user?.user?._id)

  const getSuggestions = (value) => {
    if (value) {
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      if (field?.fetchOptionManually) {
        prescriptionSocket.emit("get_meds", {
          search: inputValue,
          docId: docId
        })
  
        prescriptionSocket.once("get_meds", (args) => {
          if (args) {
            // if(cardState?.prefilled)
            //   addOn.push(cardState)
            setSuggestions([...new Set([...(frequentOptions?.length ? inputLength === 0
              ? []
              : frequentOptions?.filter(
                  (ele) => ele?.name ? ele?.name?.toLowerCase().slice(0, inputLength) === inputValue : ele.toLowerCase().slice(0, inputLength) === inputValue
                )?.slice(0, 10) : []), ...args, ...(suggestedOptions?.length ? inputLength === 0
                  ? []
                  : suggestedOptions?.filter(
                      (ele) => ele?.name ? ele?.name?.toLowerCase().slice(0, inputLength) === inputValue : ele.toLowerCase().slice(0, inputLength) === inputValue
                    )?.slice(0, 10) : [])])])
          // } else if(cardState?.prefilled){
          //   addOn.push(cardState)
          //   setSuggestions([...addOn])
          }
        })
      } else {
        setSuggestions(inputLength === 0
          ? []
          : field?.options?.filter(
              (ele) => ele?.name ? ele?.name?.toLowerCase().slice(0, inputLength) === inputValue : ele.toLowerCase().slice(0, inputLength) === inputValue
            ))
      }
    } else {
      setSuggestions([...field.options])
    }
  }

  const childRef = useRef(null);
  const medPrefilledRef = useRef({"first": 0, "prefilled": false, "name": ""});
  useEffect(() => {
    if(field?.medicines && cardState?.prefilled && medPrefilledRef.current?.first === 0) {
      medPrefilledRef.current = {
        "first": 1,
        "prefilled": true,
        "name": cardState?.name
      }
    } else if(cardState?.name !== medPrefilledRef.current?.name && cardState?.prefilled && medPrefilledRef.current?.first === 1){
      medPrefilledRef.current = {
        "first": 2,
        "prefilled": false,
        "name": medPrefilledRef.current?.name
      }
      setCardState({name: cardState?.name})
    } else if(cardState?.name === medPrefilledRef.current?.name && cardState?.prefilled && medPrefilledRef.current?.first === 2){
      medPrefilledRef.current = {
        "first": 1,
        "prefilled": true,
        "name": cardState?.name
      }
    } else if(medPrefilledRef.current?.first === 2){
      medPrefilledRef.current = {
        "first": 0,
        "prefilled": false,
        "name": ""
      }
    }
  }, [cardState]) 

  const [focusedIndex, setFocusedIndex] = useState(null);

  const handleMenuItemFocus = (index, type = "frequent") => {
    setFocusedIndex({"index": index, "type": type});
  };

  // useEffect(() => {
  //   if (childRef.current) {
  //     childRef.current.parentElement.style.height = `${childRef.current.offsetHeight}px`;
  //   }
  // }, []);

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.

  const getFocusedItems = () => {
    return (
      <Grid item xs={8} container spacing={1} alignItems="center" style={{display: "flex"}}>
        <Grid item style={{marginLeft: "auto"}}> 
        ↑ Up
        </Grid>
        <Grid item>
        ↓ Down
        </Grid>
        <Grid item>
        ↵ Select
        </Grid>
      </Grid>
    )
  }

  const getAvatarComponent = (type, index, text, isAddOn=false) => {

    let component = text?.[0]
    if(field?.medicines && !isAddOn) {
      let componentSrc = capsuleLogo

      if(text?.toLowerCase()?.includes("gel") || text?.toLowerCase()?.includes("lotion") || text?.toLowerCase()?.includes("ointment") || text?.toLowerCase()?.includes("cream")){
        componentSrc = gelLogo
      } else if(text?.toLowerCase()?.includes("syrup"))
        componentSrc = syrupLogo
      else if(text?.toLowerCase()?.includes("syring") || text?.toLowerCase()?.includes("inject"))
        componentSrc = syringeLogo
        
      component = (<img
        src={componentSrc}
        style={{
          width: "20px",
          objectFit: "contain",
        }}
      />)
    }

    if (isAddOn) {
      component = <Add />
    }

    if(field?.labResult === "name"){
      let nameData = labResultConstantData?.[text]
      component = <i class="fas fa-tint"></i>
      if(nameData && nameData?.labVitals){
        component = "T"
      }
    }

    return (
      <Avatar style={{
        fontSize: 12,
        width: 25,
        height: 25, 
        marginRight: 10, 
        color: theme.palette.primary.main, 
        backgroundColor: "#D6EAF8",
        border: "solid",
        borderWidth: focusedIndex?.type === type && focusedIndex?.index === index ? 2 : 0
      }}>{component}</Avatar>
    )
  }

  function removeDuplicatesByName(inputList) {
    const uniqueNames = {}; // Temporary object to store unique names
    return inputList.filter((item) => {
      if(typeof(item) === 'object'){
        if (!uniqueNames[item.name]) {
          uniqueNames[item.name] = true;
          return true; // Include the item in the filtered list
        }
        return false; // Exclude the item from the filtered list
      } else return true
    });
  }  


  function renderSuggestionsContainer({ containerProps, children, query }) {

    const defaultItems = removeDuplicatesByName([...suggestions?.filter(f => {
      let tempQuery = query;
      
      if(field?.medicines && tempQuery){
        if(tempQuery?.toLowerCase()?.startsWith("tab")){
          tempQuery = tempQuery?.toLowerCase()?.replace("tab", "");
          tempQuery = tempQuery.trim()
        } else if(tempQuery?.toLowerCase()?.startsWith("cap")){
          tempQuery = tempQuery?.toLowerCase()?.replace("cap", "");
          tempQuery = tempQuery.trim()
        } else if(tempQuery?.toLowerCase()?.startsWith("inj")){
          tempQuery = tempQuery?.toLowerCase()?.replace("inj", "");
          tempQuery = tempQuery.trim()
        }
      }

      return (f?.name ? f?.name?.toLowerCase()?.includes(tempQuery?.toLowerCase()) : f?.toLowerCase()?.includes(tempQuery?.toLowerCase())
      && ![...tableData?.[cardKey]]?.some(value => value && value?.name ? (value?.name?.toLowerCase() === (f?.name ? f?.name : f)?.toLowerCase()) : false))
    })])

    let displayVar = containerOpen? (inputProps?.value ? (defaultItems?.length ? "block" : "none") : (frequentOptions?.length || suggestedOptions?.length || inputProps?.value ? "flex" : "none")) : "none"
    
    if(displayVar === "none" && containerOpen && query){
      // helperTextOfNewMed.current = "NEW DRUG. Your AI will remember this next time!"

      defaultItems.push({
        "name": query,
        "isAddOn": medPrefilledRef.current?.prefilled ? false : true 
      })
      displayVar = "block"

    }

    let varStyle = {
      borderRadius: "4px",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      zIndex: "2",
      width: "100%",
      display: displayVar,
      flexDirection: "column",
      overflowX: "hidden",
      overflowY: "hidden",
      backgroundColor: "#fff",
      position: "absolute",
      // minHeight: "280px",
      padding: "0.5rem 0 ",
    }

    let frequentFilterOptions = !inputProps?.value && frequentOptions?.length ? removeDuplicatesByName([...frequentOptions]?.filter(f => {
      return ![...tableData?.[cardKey]]?.some(value => value?.name?.toLowerCase() === (f?.name ? f?.name?.toLowerCase() : f?.toLowerCase()));
    })) : []

    let suggestedFilterOptions = !inputProps?.value && suggestedOptions?.length ? removeDuplicatesByName([...suggestedOptions]?.filter(f => {
      return ![...tableData?.[cardKey]]?.some(value => value?.name?.toLowerCase() === (f?.name ? f?.name?.toLowerCase() : f?.toLowerCase()));
    })) : []

    
    return (
      <div
      {...containerProps}
        style={{...varStyle}}
      >
        {!inputProps?.value ? <Grid container style={{padding: 5, position: "relative", flexGrow: 1}} spacing={2}>
          
            {(frequentFilterOptions?.length ? 
            <Grid item container xs={inputProps?.value || !suggestedFilterOptions?.length ? 12 : 6} style={{maxHeight: "280px", overflowY: 'auto'}}>
              <Grid item xs={12} style={{alignItems: "center"}}>
                <div style={{alignItems: "center"}}><Chip label="Frequently" color="primary" style={{borderRadius: 5, marginBottom: 5}} /> <span style={{fontSize: 12}}>entered by you</span></div>
              {frequentFilterOptions.map((fOption, fIndex) => (
                <MenuItem innerRef={ref => frequentOptionRefs.current[fIndex] = ref} onMouseDown={(e) => {
                  inputProps?.onChange(null, {"newValue": fOption?.name ? fOption?.name : fOption, "otherData": fOption}, true)
                }}
                onKeyDown={(e) => handleKeyDown(e, "options", {"index": fIndex, "optionType": "frequent", "value": fOption?.name ? fOption?.name : fOption, "otherData": fOption})}
                onFocus={() => handleMenuItemFocus(fIndex, "frequent")}
                // onMouseEnter={() => handleMenuItemFocus(fIndex, "frequent")}
                // onMouseLeave={() => setFocusedIndex(null)}
                style={{backgroundColor: focusedIndex?.type === 'frequent' && focusedIndex?.index === fIndex ? "#F2F3F4": null}}
                >
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={4} container style={{marginLeft: -10}} alignItems="center">
                      <Grid item xs={2}>
                        {getAvatarComponent("frequent", fIndex, fOption?.name ? fOption?.name : fOption)}
                      </Grid>
                      <Grid item xs={10} style={{paddingLeft: suggestedFilterOptions?.length ? 10 : 0, marginLeft: !suggestedFilterOptions?.length ? -20 : 0}}>
                        {fOption?.name ? fOption?.name : fOption}

                        {field?.medicines && fOption?.shortComposition ? <Typography variant="subtitle2" style={{opacity: "60%", fontSize: "12px"}}>
                          {fOption?.shortComposition}
                        </Typography> : null}
                      </Grid>
                    </Grid>
                    {focusedIndex?.type === 'frequent' && focusedIndex?.index === fIndex ? (
                      getFocusedItems()
                    ) : null}
                  </Grid>
                </MenuItem>))}
            </Grid></Grid> : null)}

            {/* #1ABC9C */}
              
          {!inputProps?.value && suggestedFilterOptions?.length? <Grid item container xs={frequentFilterOptions?.length ? 6 : 12} style={{maxHeight: "280px", overflowY: "auto"}}>
            <Grid item xs={12}>
              <div style={{alignItems: "center"}}><Chip label="Associated" style={{borderRadius: 5, marginBottom: 5, backgroundColor: "#1ABC9C", color: "white"}} /> <span style={{fontSize: 12}}>with Symptoms/Diagnosis entered</span></div>
              {suggestedFilterOptions?.map((suggestion, sIndex) => (
                <MenuItem innerRef={ref => suggestedOptionRefs.current[sIndex] = ref} onMouseDown={(e) => {
                  inputProps?.onChange(null, {"newValue": suggestion?.name ? suggestion?.name : suggestion, "otherData": suggestion}, true)
                }}
                onKeyDown={(e) => handleKeyDown(e, "options", {"index": sIndex, "optionType": "suggested", "value": suggestion?.name ? suggestion?.name : suggestion, "otherData": suggestion})}
                onFocus={() => handleMenuItemFocus(sIndex, "associated")}
                // onMouseEnter={() => handleMenuItemFocus(sIndex, "associated")}
                // onMouseLeave={() => setFocusedIndex(null)}
                style={{backgroundColor: focusedIndex?.type === 'associated' && focusedIndex?.index === sIndex ? "#F2F3F4": null}}
                >
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={4} container style={{marginLeft: -10}} alignItems="center">
                      <Grid item xs={2}>
                        {getAvatarComponent("associated", sIndex, (suggestion?.name ? suggestion?.name : suggestion))}
                      </Grid>
                      <Grid item xs={10} style={{paddingLeft: frequentFilterOptions?.length ? 10 : 0, marginLeft: !frequentFilterOptions?.length ? -20 : 0}}>
                        {suggestion?.name ? suggestion?.name : suggestion}

                        {field?.medicines && suggestion?.shortComposition ? <Typography variant="subtitle2" style={{opacity: "60%", fontSize: "12px"}}>
                          {suggestion?.shortComposition}
                        </Typography> : null}
                      </Grid>
                    </Grid>
                    {focusedIndex?.type === 'associated' && focusedIndex?.index === sIndex ? (
                      getFocusedItems()
                    ) : null}
                  </Grid>
                </MenuItem>))}
              </Grid>
          </Grid> : null}
        </Grid> : <Grid container style={{padding: 5, position: "relative", flexGrow: 1}} spacing={2}>
          
          {(defaultItems?.length ? 
          <Grid item container xs={inputProps?.value || !suggestedFilterOptions?.length ? 12 : 6} style={{maxHeight: "280px", overflowY: 'auto'}}>
            <Grid item xs={12}>
            {defaultItems.map((dOption, dIndex) => (
              <MenuItem innerRef={ref => defaultOptionRefs.current[dIndex] = ref}
              onMouseDown={(e) => {
                inputProps?.onChange(null, {"newValue": dOption?.name ? dOption?.name : dOption, "otherData": dOption}, true)
              }}
              onKeyDown={(e) => handleKeyDown(e, "options", {"index": dIndex, "optionType": "default", "items": defaultItems, "value": dOption?.name ? dOption?.name : dOption, "otherData": dOption})}
              onFocus={() => handleMenuItemFocus(dIndex, "default")}
              // onMouseEnter={() => handleMenuItemFocus(dIndex, "default")}
              // onMouseLeave={() => setFocusedIndex(null)}
              style={{backgroundColor: focusedIndex?.type === 'default' && focusedIndex?.index === dIndex ? "#F2F3F4": null}}
              >
                <Grid container justifyContent="space-between" alignItems="center">
                    {!dOption?.isAddOn ? <Grid item xs={4} container style={{marginLeft: -10}} alignItems="center">
                      <Grid item xs={2}>
                        {getAvatarComponent("default", dIndex, (dOption?.name === "" || dOption?.name ? dOption?.name : dOption))}
                      </Grid>
                      <Grid item xs={10} style={{marginLeft: -20}}>
                        {dOption?.name ? dOption?.name : dOption}

                        {field?.medicines && dOption?.shortComposition ? <Typography variant="subtitle2" style={{opacity: "60%", fontSize: "12px"}}>
                          {dOption?.shortComposition}
                        </Typography> : null}
                      </Grid>

                </Grid> : 
                  (<Grid item xs={4} style={{display: "flex", alignItems: "center", marginLeft: -10}}>
                    {getAvatarComponent("default", dIndex, (dOption?.name === "" || dOption?.name ? dOption?.name : dOption), true)}
                    <Typography>Add "{dOption?.name === "" || dOption?.name ? dOption?.name : dOption}"  <span style={{fontSize: 12, marginLeft: 5, color: "grey"  }}>Prescription AI will remember this next time!</span></Typography>
                  </Grid>)}
                  {focusedIndex?.type === 'default' && focusedIndex?.index === dIndex ? (
                      getFocusedItems()
                    ) : null}
                </Grid>
              </MenuItem>))}
          </Grid></Grid> : null)}
          </Grid>
          }
      </div>
    )
  }

  const getSuggestionValue = (suggestion) =>
    field.optionLabel ? suggestion[field?.optionLabel] : suggestion

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion, { query, isHighlighted }) => (
    <MenuItem id={`default-menuitem-${suggestion}`} onClick={() => {
      // inputProps?.onChange(null, {"newValue": dOption}, true)
    }}>
      {field.optionLabel ? suggestion[field?.optionLabel] : suggestion}
    </MenuItem>
  )

  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value)
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const frequentOptionRefs = useRef([]);
  const suggestedOptionRefs = useRef([]);
  const defaultOptionRefs = useRef([]);

  const [containerOpen, setContainerOpen] = useState(false)

  const handleClickOutside = () => setContainerOpen(false)

  useEffect(() => {
    // Add event listener for mousedown event
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event, type = "textField", other = {}) => {
    if (type === "textField")
    switch (event.key) {
      case "ArrowDown":{
        event.preventDefault()
        setContainerOpen(true)

        if(!inputProps?.value){
          if(frequentOptions?.length)
            frequentOptionRefs.current[0]?.focus();

          else if(suggestedOptions?.length)
            suggestedOptionRefs.current[0]?.focus();
        } else {
          // inputRef?.focus();
          // if(defaultOptionRefs.current?.[0]?.length > defaultHighlightIndex+1)
          //   setDefaultHighlightIndex(defaultHighlightIndex+1)
          defaultOptionRefs.current?.[0]?.focus();
          setSuggestions([...suggestions])
        }
        break
      }
      case "ArrowUp":
        event.preventDefault()

        setContainerOpen(false)
        const tableRowLength = [...tableData[cardKey]].length

        if (tableRowLength)
          textFieldsRefs.current?.[tableRowLength-1]?.[0]?.focus()
        
        else if(activeIndex && activeIndex?.index > 0){
          setActiveIndex({"index": activeIndex?.index - 1, "from": activeIndex?.index})
        }
        
        break
      case "Enter":
        inputRef.current.blur()
        setContainerOpen(false)
        if(inputProps?.value) {
          inputProps?.onChange(null, {"newValue": event?.target?.value}, "enter")
        }
        break

      case "Escape":
        setContainerOpen(false)
        break
      
      case "Tab":
        setContainerOpen(false)
        // setActiveIndex({"index": activeIndex?.index + 1, "from": activeIndex?.index})
        break

      default:
        setContainerOpen(true)
        break
    }

    else if (type === "options")
    switch (event.key) {
      case "ArrowDown":{
        // setFocusedIndex(null)
        event.preventDefault()
        
        if (other?.optionType === "frequent") {
          if(frequentOptionRefs.current?.[other?.index + 1])
            frequentOptionRefs.current[other?.index+1]?.focus();
          
          else if(suggestedOptionRefs?.current?.length)
            suggestedOptionRefs.current[0]?.focus()
        }
        else if (other?.optionType === "suggested"){
          if(suggestedOptionRefs.current?.[other?.index + 1])
            suggestedOptionRefs.current[other?.index+1]?.focus();
          
          else if(suggestedOptionRefs?.current?.length)
            frequentOptionRefs.current[0]?.focus()
        }
        else if (other?.optionType === "default")
          defaultOptionRefs.current[other?.index+1]?.focus()
        break
      }
      case "ArrowUp":
        setFocusedIndex(null)
        event.preventDefault()

        if(other?.index === 0)
          inputRef?.current?.focus()

        else {
          if (other?.optionType === "frequent")
            frequentOptionRefs.current[other?.index-1]?.focus();
          else if (other?.optionType === "suggested")
            suggestedOptionRefs.current[other?.index-1]?.focus();
          else if (other?.optionType === "default"){
            defaultOptionRefs.current[other?.index-1]?.focus()
          }
        }
        break

      case "ArrowRight":
        event.preventDefault()
        if (other?.optionType === "frequent"){
          suggestedOptionRefs.current[0]?.focus();
        }        
        break
        
      case "ArrowLeft":
        event.preventDefault()
        if (other?.optionType === "suggested"){
          frequentOptionRefs.current[0]?.focus();
        }        
        break

      case "Enter":
        setFocusedIndex(null)
        inputRef.current.blur()
        setContainerOpen(false)

        inputProps.onChange(inputRef, {"newValue": other?.value, otherData: other?.otherData}, true)
        break
    
      case "Escape":
        setFocusedIndex(null)
        inputRef.current?.focus()
        setContainerOpen(false)
        break

      default:
        setFocusedIndex(null)
        break
    }

    if(onKeyDown) onKeyDown(event)
  }

  const navigateSuggestions = (direction) => {
    const index = suggestions.findIndex((suggestion) =>
      getSuggestionValue(suggestion).toLowerCase().includes(inputRef.current.value.toLowerCase())
    )

    let newIndex = index
    if (direction === "down") {
      newIndex = index === suggestions.length - 1 ? 0 : index + 1
    } else if (direction === "up") {
      newIndex = index === 0 ? suggestions.length - 1 : index - 1
    }

    if (newIndex !== index) {
      const newSuggestion = suggestions[newIndex]
      const newValue = getSuggestionValue(newSuggestion)
      inputRef.current.value = newValue
    }
  }

  return (
    <Autosuggest
      containerProps={{ style: { position: "relative", width: "100%" } }}
      suggestions={suggestions}
      inputProps={inputProps}
      // shouldRenderSuggestions={true}
      alwaysRenderSuggestions={containerOpen}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      shouldRenderSuggestions={() => containerOpen}
      highlightFirstSuggestion={true}   
      renderInputComponent={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            style={{ position: "relative", width: "100%" }}
            variant="outlined"
            inputRef={inputRef}
            onFocus={() => frequentOptions ? setContainerOpen(true) : null}
            onKeyDown={handleKeyDown}
            onFocusCapture={() => {
              const container = inputRef.current;
              container.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }}
            // helperText={inputProps?.value && helperTextOfNewMed?.current ? 
            //   <Typography variant="caption" color='primary' style={{fontSize: 12}}>{helperTextOfNewMed?.current}</Typography>
            //   : ""}

            // onKeyDown={(e) => {
            //   if (
            //     e.key === "Enter" &&
            //     addState?.add &&
            //     e?.target?.value
            //   )
            //     updateTableData();
            //   else {
            //     params.onKeyDown(e);
            //   }
            // }}
            InputProps={{
              startAdornment: (<Search style={{color: "#B2BABB", marginRight: "5px"}}/>),
              endAdornment: inputProps?.value ? <InputAdornment position="end">
                <Typography variant="caption" style={{fontSize: 10}}>↩ Press Enter</Typography>
                </InputAdornment> : null
            }}
          ></TextField>
        )
      }}
    ></Autosuggest>
  )
}

export default PrescriptionAutocomplete
