import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  makeStyles,
  styled,
  useTheme,
} from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import customAxios from "../../axios"
import printIcon from "./../../assets/img/consultation/id-card icon.png"
import {
  AddBoxOutlined,
  BorderColor,
  CheckCircle,
  CheckCircleOutline,
  ChevronRight,
  Description,
  Edit,
  FastForward,
  FileCopy,
  History,
  Home,
  HomeOutlined,
  Info,
  LocationCity,
  PersonPinCircle,
  PinDrop,
  Print,
} from "@material-ui/icons"
import userImg from "./../../assets/user.png"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import { toast } from "react-toastify"
import { useHistory } from "react-router"
import QuickEndModal from "./QuickEndModal"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import noDataImg from "./../../assets/img/consultation/no_data_vector1.jpg"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import displayAge from "../../assets/functions/displayAge"
import GenericDialogFormNew1 from "../CreatePrescription/GenericDialog/newIndex"
import { Skeleton } from "@material-ui/lab"


const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: 300,
  // Enable scrolling
  overflowY: 'scroll',
  // Hide the scrollbar
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 6, // Adjust to desired thickness
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent', // Adjust to desired color
  },
});


const useStyles = makeStyles({
  tab: {
    minWidth: '15%', // Adjust the width as per your requirement
    // padding: 0
  },
});


function Sidebar({
  appointmentData,
  patientData,
  activeStep,
  setActiveStep,
  setLoading,
  state, 
  setState,
  templateList = []
}) {
  const classes = useStyles()
  const history = useHistory()
  const quickEndModalHeaders = [
    {
      label: "Follow-up Date",
      key: "expDate",
      type: "date",
      fullWidth: true,
    },
  ]
  const quickEndAppointment = (modalData) => {
    setModalLoading(true)
    customAxios
      .post("consultation/quick-end", {
        appointmentId: appointmentData._id,
        followUp: modalData.date
          ? { expDate: moment.utc(modalData?.date).format() }
          : null,
      })
      .then((res) => {
        if (res.data.success) {
          setModalLoading(false)

          mixpanel.track("Quick End Consultation with Follow Up")
          mixpanel.people.increment("Quick End Consultation with Follow Up")

          toast.success("Consultaion completed successfully.")
          setQuickEndModalOpen(false)
          history.push("/admin/dashboard")
        } else {
          setModalLoading(false)

          toast.error("Some error occured.")
        }
      })
      .catch((err) => {
        setModalLoading(false)

        mixpanel.track("Quick End Appointment Error", { ...err })

        toast.error("Some error occured.")
        console.log(err)
      })
  }
  const [quickEndModalOpen, setQuickEndModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [modalLoading, setModalLoading] = useState(false)

  const [updatePatientOpen, setUpdatePatientOpen] = useState(false)
  const handleUpdatePatient = (data) => {
    setLoading(true)
    setUpdatePatientOpen(false)
    customAxios
      .put(`patients/update/${appointmentData?.patientId}`, { ...data })
      .then((res) => {
        setLoading(false)
        window.location.reload()
        if (res.data.success) {
          toast.success("Patient details updated successfully.")
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((Err) => {
        toast.error("Some error occured")
        setLoading(false)
      })
  }
  const updatePatientHeaders = [
    {
      label: "Name",
      key: "name",
      type: "text",
      md: 10
    },
    {
      key: "countryCode",
      // error: (data) => data.toString().length !== 10,
      required: true,
      md: "3",
      // placeholder: "",
      // maxLength: "3",
      value: "91",
      freeSolo: true,
      default: "91",
      prefix: "+",
      // innerWidth: "112%",
      label: "Code",
      type: "select-new-1",
      onChange: (val, state, setState) => {
        const temp = { ...state }
        temp['countryCode'] = val
        setState({ ...temp })
      },
      options: ["1", "61", "91", "962", "970", "972"]
    },
    {
      label: "Phone",
      key: "phoneNo",
      // disabled: true,
      type: "number",
      md: 7

    },
    {
      key: "ageString",
      label: "Age",
      unitOptions1: ['Years', "Months", "Days"],
      unitOptions: ['Year', 'Month', 'Day'],
      placeholder: "e.g. 10 Years",
      freeSolo: true,
      type: "unit-autocomplete",
      md: 10
    },
    {
      label: "Gender",
      key: "gender",
      type: "select-chip",
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Other", value: "other" },
      ],
      md: 12
    },
  ]

  const patientInforoutes = [
    {
      label: "Medical Record",
      icon: AddBoxOutlined,
    },
    {
      label: "Patient Assessment",
      icon: Description,
    },
    {
      label: "Appointment History",
      icon: History,
    },
  ]
  const handleGenerateSticker = () => {
    setLoading(true)
    customAxios

      .get(
        `doctor/patient-sticker/${patientData?._id}?org_id=${appointmentData?.organisation?._id}`
      )
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          window.open(res.data.data.url, "_blank")

        } else {
          setLoading(false)
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Some error occured")
      })
  }

  const [selectedTab, setSelectedTab] = useState(0);
  const [avatarSelected, setAvatarSelected] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const theme = useTheme();

  const [pastVisits, setPastVisits] = useState([])
  const [loadingPastVists, setLoadingPastVists] = useState(true)

  const updateState = (data, keyName = "all", medAppend = false) => {

    toast.success("Copied to Pad")

    let tempData = null;
    try {
      tempData = JSON.parse(JSON.stringify(data));
    } catch (err) {
      tempData = [...data];
      console.log(err, 'in deep copy')
    }

    switch(keyName) {
      case "all":{
        let tests = []
        if(tempData?.tests?.length){
          tests = tempData.tests.map(v => ({name: v}))
        }
        setState({...state, ...tempData, tests: [...tests]})
        break
      }
      default:{
        if(medAppend) {
          setState({...state, medicines: [...state?.medicines, ...tempData]})
        } else {
          setState({...state, [keyName]: [...state?.[keyName], ...tempData]})
        }
        break
      }
    }

  }

  const updateVital = (data) => {
    if(data?.length){
      setState({...state, vitals: [...data], vitalUpdate: true});

      toast.success("Copied to Pad")
    }
  }
  
  useEffect(() => {
    setLoadingPastVists(true)
    if(appointmentData?.docId && patientData?._id)
    customAxios
      .get(`consultation/filter?doc_id=${appointmentData?.docId}&patient_id=${patientData?._id}`)
      .then((res) => {
        if (res.data.success) {
          setPastVisits(res?.data?.data?.reverse())
          setLoadingPastVists(false)
        }
      })
  }, [appointmentData]);


  const [vitalList, setVitalList] = useState([])

  useEffect(() => {
    customAxios
      .get(`/vital/${patientData?._id}`)
      .then((res) => {
        if (res.data.success) {
          const temp = res.data.data.vitals;
          temp.reverse();
          setVitalList([...temp]);
        }
      })
      .catch((err) => console.log(err));
  }, [patientData]);


  return (
    <Grid
      container
      style={{
        // width:    "300px",
        height: "95vh",
        padding: "1rem",
        backgroundColor: "#fff",
        borderRadius: "40px 0px 40px 0px",
        position: "relative"
      }}
    >
      <Grid item xs={12} container justifyContent="center" style={{height: "15%", maxHeight: "100%"}}>
        <Grid item xs={12} container justifyContent="flex-end" style={{marginBottom: "-3rem"}}>
        <IconButton
          style={{
            marginLeft: "auto"
          }}
          onClick={() => setUpdatePatientOpen(true)}
        >
          <Edit color="primary" />
        </IconButton>
        </Grid>
        <Grid
          item
          xs={4}
          container
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <Avatar
            style={{
              objectFit: "contain",
              width: "60px",
              height: "60px",
              backgroundColor: "#5e72e4",
            }}
          >
            {patientData?.name?.split("")[0]}
          </Avatar>
          <Button
            onClick={() => handleGenerateSticker()}
            title="Print patient details on a sticker"
            color="primary"
            size="small"
            style={{ borderRadius: "11px", marginTop: "0.5rem" }}
          >
            Print Sticker
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontWeight: "700" }}>
            {patientData?.name}
          </Typography>
          <Typography>
            {displayAge(patientData)} |{" "}
            {patientData?.gender} |{" "}
            {patientData?.birthDate}
          </Typography>
          <Typography>{patientData?.phoneNo}</Typography>
          <Typography>
            <span style={{}}># {patientData?.code}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2} alignItems="center">
          {/* <Grid item>
            <IconButton
              color="primary"
              title="Call Patient"
              onClick={() => {
                var a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.href = `tel:${patientData.phoneNo}`
                a.click()
              }}
            >
              <i class="fas fa-phone-alt fa-flip-horizontal fa-0.75x"></i>{" "}
            </IconButton>
          </Grid> */}
          {appointmentData?.isOnline ? (
            <Grid item>
              <IconButton
                style={{ color: "green" }}
                title="Chat"
                onClick={() => {
                  history.push(`/admin/chat`, {
                    appointmentId: appointmentData._id,
                  })
                }}
              >
                <img
                  src={whatsappLogo}
                  style={{
                    width: "28px",
                    objectFit: "contain",
                  }}
                />{" "}
              </IconButton>
            </Grid>
          ) : null}
          {appointmentData?.link ? (
            <Grid item>
              <IconButton
                color="primary"
                title="Video call"
                onClick={() => {
                  window.open(appointmentData.link)
                }}
              >
                <i class="fas fa-video"></i>
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
        {patientData?.address ? (
          <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
            <Typography>
              <PinDrop color="primary" fontSize="small" />{" "}
              <span style={{}}> {patientData?.address}</span>
            </Typography>
          </Grid>
        ) : null}

          <Grid item xs={12} container justifyContent="center" style={{marginTop: "3%"}}>
            <Grid item>
              <div style={{ bottom: -10, left: 10, fontSize: "13px", }}>
                Powered By 
                <span style={{fontWeight: "600", marginLeft: "4px", fontSize: "13px"}}>  
                  Paper<span style={{
                    color: "#4D63DD",
                    // background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                    // WebkitBackgroundClip: 'text',
                    // WebkitTextFillColor: 'transparent', 
                    // fontWeight: "bold",
                    // marginLeft: 3,
                    // fontSize: "16px"
                  }}>plane</span>
                </span>
              </div>
            </Grid>
          </Grid>

                  <Grid item xs={12} style={{marginTop: "2%"}}>
                    <Divider />
                  </Grid>
        <Grid item container xs={12} style={{marginTop: "2%"}}>
          <Grid item xs={12}>
            <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" style={{maxWidth: "100%", padding: 0}}>
              <Tab
              // style={{maxWidth: "50px", padding: 0}}
              className={classes.tab}
              disableRipple
                value={0}
                label={
                  <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item>
                      <Avatar title="Past Visits" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 0 ? theme.palette.primary.main : "#CACFD2"}}>
                        P
                      </Avatar>
                    </Grid>

                    {selectedTab === 0 ? <Grid item>
                      <Typography variant="subtitle2">
                        Past Visits
                      </Typography>
                    </Grid> : null}
                  </Grid>
                }
              />

              <Tab  
              disableRipple
              className={classes.tab}
              value={1}
              label={
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                      <Avatar title="Saved Templates" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 1 ? theme.palette.primary.main : "#CACFD2"}}>
                        Rx
                      </Avatar>
                    </Grid>
                  {selectedTab === 1 ? <Grid item>
                    <Typography variant="subtitle2">
                      Saved Templates
                    </Typography>
                  </Grid> : null}
                </Grid>
              }              
              />

              <Tab  
              disableRipple
              className={classes.tab}
              value={2}
              label={
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                      <Avatar title="Vitals" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 2 ? theme.palette.primary.main : "#CACFD2"}}>
                        V
                      </Avatar>
                    </Grid>
                  {selectedTab === 2 ? <Grid item>
                    <Typography variant="subtitle2">
                      Vitals
                    </Typography>
                  </Grid> : null}
                </Grid>
              }              
              />

              {/*
               <Tab
              disableRipple
              className={classes.tab}
              value={2}
              label={
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                    <Avatar title="Vitals" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 2 ? theme.palette.primary.main : "#CACFD2"}}>
                      V
                    </Avatar>
                  </Grid>
                  {selectedTab === 2 ? <Grid item>
                    <Typography variant="subtitle2">
                      Vitals
                    </Typography>
                  </Grid> : null}
                </Grid>
              }              />
               <Tab
              disableRipple
              className={classes.tab}
              value={3}
              label={
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                    <Avatar title="Medical Records" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 3 ? theme.palette.primary.main : "#CACFD2"}}>
                      R
                    </Avatar>
                  </Grid>
                  {selectedTab === 3 ? <Grid item>
                    <Typography variant="subtitle2">
                      Medical Reports
                    </Typography>
                  </Grid> : null}
                </Grid>
              }              /> */}
            </Tabs>
          </Grid>

          <CustomGrid item xs={12} style={{maxHeight: "72vh", overflowY: "scroll", paddingTop: "10px"}}>
            {selectedTab === 0 && (
              <Grid container>
                <PastVisit data={pastVisits} loading={loadingPastVists} updateState={updateState}/>
              </Grid>
            )}
            {selectedTab === 1 && (
              <Grid container>
                <PastVisit data={templateList} loading={loadingPastVists} updateState={updateState} forTemplate={true}/>
              </Grid>
            )}
            {selectedTab === 2 && (
              <Grid container>
                <PastVital data={vitalList} updateVital={updateVital} />
              </Grid>
            )}
            {selectedTab === 3 && (
              <Grid container>
                
              </Grid>
            )}
          </CustomGrid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
      </Grid> */}
      
      {/* {patientInforoutes?.map((ele) => {
        const { icon: Icon } = ele
        return (
          <Grid
            item
            xs={12}
            container
            onClick={() => setActiveStep(ele.label)}
            // onMouseEnter={() => setActiveStep(ele.label)}
            alignItems="center"
            style={{
              cursor: "pointer",
              flexWrap: "nowrap",
              backgroundColor: activeStep === ele.label ? "#5e72e4" : "#fff",
              borderRadius: "11px",
              padding: "0.5rem",
              margin: "0.25rem 0 0 0 ",
            }}
            justifyContent="space-between"
          >
            <Grid item container>
              <Icon
                style={{ color: activeStep === ele.label ? "#fff" : "#5e72e4" }}
              />
              <Typography
                style={{
                  paddingLeft: "1rem",
                  color: activeStep === ele.label ? "#fff" : "#696969",
                }}
              >
                {ele.label}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight
                style={{ color: activeStep === ele.label ? "#fff" : "#5e72e4" }}
              />
            </Grid>
          </Grid>
        )
      })}
      <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
        <Typography style={{ fontWeight: "600" }}>Prescription Pad</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        onClick={() => setActiveStep("Write Prescription")}
        alignItems="center"
        // onMouseEnter={() => setActiveStep("Write Prescription")}
        style={{
          cursor: "pointer",
          flexWrap: "nowrap",
          borderRadius: "11px",
          padding: "0.5rem",
          margin: "0.25rem 0 0 0 ",

          backgroundColor:
            activeStep === "Write Prescription" ? "#5e72e4" : "#fff",
        }}
        justifyContent="space-between"
      >
        <Grid item container>
          <BorderColor
            style={{
              color: activeStep === "Write Prescription" ? "#fff" : "#5e72e4",
            }}
          />
          <Typography
            style={{
              paddingLeft: "1rem",
              color: activeStep === "Write Prescription" ? "#fff" : "#696969",
            }}
          >
            Write Prescription
          </Typography>
        </Grid>
        <Grid item>
          <ChevronRight
            style={{
              color: activeStep === "Write Prescription" ? "#fff" : "#5e72e4",
            }}
          />
        </Grid>
      </Grid>{" "}
      {/* <Grid
        item
        xs={12}
        container
        onClick={() => setActiveStep("End Consultation")}
        onMouseEnter={() => setActiveStep("End Consultation")}
        alignItems="center"
        style={{
          cursor: "pointer",
          flexWrap: "nowrap",
          borderRadius: "11px",
          padding: "0.5rem",
          margin: "0.25rem 0 0 0 ",

          backgroundColor:
            activeStep === "End Consultation" ? "#5e72e4" : "#fff",
        }}
        justifyContent="space-between"
      >
        <Grid item container>
          <CheckCircleOutline
            style={{
              color: activeStep === "End Consultation" ? "#fff" : "#5e72e4",
            }}
          />
          <Typography
            style={{
              paddingLeft: "1rem",
              color: activeStep === "End Consultation" ? "#fff" : "#696969",
            }}
          >
            End Consultation
          </Typography>
        </Grid>
        <Grid item>
          <ChevronRight
            style={{
              color: activeStep === "End Consultation" ? "#fff" : "#5e72e4",
            }}
          />
        </Grid>
      </Grid>{" "}
      <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
        <Divider />
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button
          title="Send a follow-up date and end consultation without prescription details"
          startIcon={<FastForward />}
          fullWidth
          onClick={() => setQuickEndModalOpen(true)}
          style={{ color: "#DD4D4D", border: "1px solid #DD4D4D" }}
        >
          Quick End Consultation
        </Button>
      </Grid> */}
      {quickEndModalOpen ? (
        <QuickEndModal
          open={quickEndModalOpen}
          onSubmit={quickEndAppointment}
          setOpen={setQuickEndModalOpen}
          modalData={modalData}
          loading={modalLoading}
          message={
            <div>
              Are you sure you want to{" "}
              <span className="text-danger">end the consultation</span>?
            </div>
          }
          setModalData={setModalData}
          dialogTitle="Quick End Consultation"
          submitButtonText="End Consultation"
        />
      ) : null}
      {updatePatientOpen ? (
        <GenericDialogFormNew1
          style={{ overflowY: "visible" }}
          formData={patientData}
          open={updatePatientOpen}
          handleClose={() => setUpdatePatientOpen(false)}
          dialogContent={updatePatientHeaders}
          dialogTitle="Edit Patient Details"
          handleSubmit={handleUpdatePatient}
        ></GenericDialogFormNew1>
      ) : null}
      
    </Grid>
  )
}

export default Sidebar




const PastVisit = ({ data, loading, updateState, forTemplate }) => {

  function sortByDateDescending(a, b) {
    const dateA = new Date(a?.date);
    const dateB = new Date(b?.date);
    return dateB - dateA;
  }

  return (
    <React.Fragment>
      {!loading ? <Grid item xs={12} container>
        {data?.length ? [...data]?.sort(sortByDateDescending)?.map((val, idx) => (
          <PastVisitCard card={val} index={idx} updateState={updateState} forTemplate={forTemplate}/>
        )) : <Grid item xs={12} container justifyContent="center" alignItems="center" style={{opacity: "70%"}}>
            <Grid item style={{marginTop: "15%", textAlign: "center"}}>
              <img src={noDataImg} style={{height: "50%", width: "50%"}} />
            </Grid>
            <Grid item style={{marginTop: "2rem", textAlign: "center"}}>
              <Typography variant="h6" style={{color: "grey", fontWeight: "bolder"}}>
                {forTemplate ? "No Saved Templates" : "No Past Visits"}
              </Typography>
              <Typography variant="subtilte1" style={{color: "grey"}}>
                {forTemplate ? "Your Saved Templates will be shown here" : "Your patient's past prescriptions will be shown here"}
              </Typography>
            </Grid>
          </Grid>}
      </Grid> : (
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "80px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />

          <Skeleton animation="wave" variant="rect" style={{marginTop: 20, marginBottom: 10, height: "80px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />

          <Skeleton animation="wave" variant="rect" style={{marginTop: 20, marginBottom: 10, height: "80px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />
        </Grid>
      )}
    </React.Fragment>
  )
}


const PastVisitCard = ({card, index, updateState, forTemplate}) => {

  const prescription = card?.prescription;

  const getSymptomText = () => {
    let symptomText = "";
    prescription?.symptoms?.map((s, i) => {
      if(s?.name){
        symptomText += `${s.name}`;
        if(i < prescription?.symptoms?.length - 1){
          symptomText += " | ";
        }
      }
    })

    return symptomText;
  }

  const getTestText = () => {
    let testText = "";
    prescription?.tests?.map((s, i) => {
      if(s){
        testText += `${s}`;
        if(i < prescription?.tests?.length - 1){
          testText += " | ";
        }
      }
    })

    return testText;
  }

  const getDiagnosisText = (withComma = false) => {
    let diagnosisText = "";
    prescription?.diagnosis?.map((s, i) => {
      if(s?.name){
        diagnosisText += `${s.name}`;
        if(i < prescription?.diagnosis?.length - 1 && s?.name){
          diagnosisText += withComma ? ", " : " | ";
        }
      }
    })

    return diagnosisText
  }

  const [medicinesHover, setMedicinesHover] = useState(null);

  const getPastMeds = (med, medIdx) => {

    let medDetails = '';
    const medKeys = ["doseQuantity", "frequence", "timing", "duration", "instructions"];

    medKeys.map((key, ki) => {
      if(med?.[key]){
        medDetails += med[key];
        if(medKeys?.[ki + 1] && med?.[medKeys?.[ki + 1]]){
          medDetails += " | "
        }
      }
    })

    return (
      <Grid item xs={12} container>
        <Grid item xs={12} onMouseEnter={() => {
          setMedicinesHover(medIdx)
        }}
        onMouseLeave={() => {setMedicinesHover(null)}}
        onClick={() => updateState([{...med}], "medicines", true)}
        style={{display: "flex", alignItems: "center"}}
        >
          <Typography style={{fontWeight: 650}} color={medicinesHover === medIdx ? "primary" : "default"}>
            {med?.name}
          </Typography>
          {medicinesHover === medIdx ? <FileCopy fontSize="small" style={{fontSize: "10px", marginLeft: 5}}/> : null}
        </Grid>
        <Grid item xs={12}>
          <Typography style={{opacity: "70%"}}>
            {medDetails}
          </Typography>
        </Grid>
      </Grid>
    )
  }


  const getLabTests = () => {

    let labDetails = '';
    const labKeys = ["name", "reading", "unit", "interpretation"];

    prescription?.labResults?.map((lab, li) => {
      
      labKeys.map((key) => {
        if(lab?.[key]){
          if(lab?.[key] && key === "name"){
            labDetails += `${lab?.[key]}`
          } else if(lab?.[key] && key === 'interpretation'){
            labDetails += ` (${lab?.[key]})`
          } else if(lab?.[key] && key === 'reading'){
            labDetails += `: ${lab?.[key]}`
          } else if(lab?.[key] && key === 'unit'){
            labDetails += ` ${lab?.[key]}`
          }
        }
      })

      if(li < prescription?.labResults?.length - 1){
        labDetails += " | "
      }
    })


    return labDetails
  }


  const [symptomHover, setSymptomHover] = useState(false);
  const [diagnosisHover, setDiagnosisHover] = useState(false);
  const [medHover, setMedHover] = useState(false);
  const [testHover, setTestHover] = useState(false);
  const [labResultHover, setLabResultHover] = useState(false);


  return (
    <Grid item xs={12} container style={{width: "100%", height: "auto", margin: 5}} spacing={2}>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item xs={11}> 
          {!forTemplate ? <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
            {moment.utc(card?.date)?.format("DD MMMM yy")}: {
            prescription?.diagnosis?.length ? <span style={{marginLeft: 1}}>{getDiagnosisText(true)}</span> : null
            }
          </Typography> : (
            <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
              {card?.templateName}
            </Typography>
          )}
        </Grid>
        {prescription?.symptoms?.length || prescription?.medicines?.length || prescription?.diagnosis?.length || prescription?.tests?.length || prescription?.labResults?.length ? <Grid item xs={1}>
          <IconButton size="small" disableRipple onClick={() => updateState({...prescription})}>
            <Tooltip title="Copy to Pad">
              <FileCopy fontSize="small" style={{fontSize: "14px"}}/>
            </Tooltip>
          </IconButton>
        </Grid> : null}
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {prescription?.symptoms?.length ? <Grid item xs={12} container spacing={1} style={{backgroundColor: symptomHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setSymptomHover(true)
        }}
        onMouseLeave={() => {setSymptomHover(false)}}
        onClick={() => updateState([...prescription?.symptoms], "symptoms")}
        >
          <Grid item xs={1}>
            <Tooltip title="Symptoms">
              <i className="fas fa-virus-covid"></i>
            </Tooltip>
          </Grid>
          <Grid item xs={11}>
            {getSymptomText()}
            {symptomHover ? <IconButton size="small" style={{position: "absolute", right: 5, top: 15}}>
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton> : null}
          </Grid>
        </Grid> : null}

        {prescription?.diagnosis?.length ? <Grid item xs={12} container spacing={1} style={{backgroundColor: diagnosisHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setDiagnosisHover(true)
        }}
        onMouseLeave={() => {setDiagnosisHover(false)}}
        onClick={() => updateState([...prescription?.diagnosis], "diagnosis")}
        >
          <Grid item xs={1}>
            <Tooltip title="Diagnosis">
              <i className="fas fa-head-side-virus"></i>
            </Tooltip>
          </Grid>
          <Grid item xs={11}>
            {getDiagnosisText()}
            {diagnosisHover ? <IconButton size="small" style={{position: "absolute", right: 5, top: 15}}>
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton> : null}
          </Grid>
        </Grid> : null}

        {prescription?.medicines?.length ? <Grid item xs={12} container spacing={1} style={{backgroundColor: medHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setMedHover(true)
        }}
        onMouseLeave={() => {setMedHover(false)}}
        >
          <Grid item xs={1}>
            <Tooltip title="Medicines">
              <i className="fas fa-pills"></i>
            </Tooltip>
          </Grid>
          <Grid item xs={11} container spacing={1}>
            {prescription?.medicines?.map((v, i) => getPastMeds(v, i))}
            {medHover ? <Tooltip title="Copy All Medicines"><IconButton title="Copy All Medicines" size="small" style={{position: "absolute", right: 5, top: 15}}
                onClick={() => updateState([...prescription?.medicines], "medicines")}
                >
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton></Tooltip> : null}
          </Grid>
        </Grid> : null}

        {prescription?.tests?.length ? <Grid item xs={12} container spacing={2} style={{backgroundColor: testHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setTestHover(true)
        }}
        onMouseLeave={() => {setTestHover(false)}}
        onClick={() => updateState(prescription?.tests?.filter(v => v)?.map(v => {return {name: v}}), "tests")}
        >
          <Grid item xs={1}>
            <Tooltip title="Tests">
              <i className="fas fa-flask-vial"></i>
            </Tooltip>
          </Grid>
          <Grid item xs={11} container>
             {"  "}{getTestText()}
            {testHover ? <IconButton size="small" style={{position: "absolute", right: 5, top: 15}}>
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton> : null}
          </Grid>
        </Grid> : null}

        {prescription?.labResults?.length ? <Grid item xs={12} container spacing={1} style={{backgroundColor: labResultHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setLabResultHover(true)
        }}
        onMouseLeave={() => {setLabResultHover(false)}}
        onClick={() => updateState([...prescription?.labResults], "labResults")}
        >
          <Grid item xs={1}>
            <Tooltip title="Lab Results">
              <i className="fas fa-microscope"></i>
            </Tooltip>
          </Grid>
          <Grid item xs={11} container>
            {getLabTests()}
            {labResultHover ? <IconButton size="small" style={{position: "absolute", right: 5, top: 15}}>
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton> : null}
          </Grid>
        </Grid> : null}

      </Grid>
      <Grid item xs={12} style={{marginTop: 5}}>
        <Divider />
      </Grid>
    </Grid>
  )
}




const PastVital = ({ data, updateVital }) => {


  const showVitalCard = (vitalData) => {

    return vitalData?.data?.length ? (
      <React.Fragment>
        <Grid item xs={12} container spacing={1} alignItems="center"
        >
          <Grid item xs={11}>
            <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
                {moment.utc(vitalData?.date)?.format("DD MMMM yy")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton size="small" disableRipple onClick={() => updateVital([...vitalData?.data])}>
              <Tooltip title="Copy to Pad">
                <FileCopy fontSize="small" style={{fontSize: "14px"}}/>
              </Tooltip>
            </IconButton>
          </Grid>

          <Grid item xs={12} container spacing={1} style={{paddingLeft: 5 }}>
            {vitalData?.data ? vitalData?.data?.map(vital => (
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography style={{fontWeight: 550, opacity: "80%", fontSize: "15px"}}>
                    {vital?.metricName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{fontWeight: 650}}>
                    {vital?.metricQuantity} {` ${vital?.metricUnit}`}
                  </Typography>
                </Grid>
              </Grid>
            )) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </React.Fragment>
    ) : null
  }

  return (
    <React.Fragment>
      <Grid item xs={12} container spacing={2} style={{marginTop: '10px'}}>
       {data?.length ? data?.map((vitalData) => (
        showVitalCard(vitalData)
       )) : <Grid item xs={12} container justifyContent="center" alignItems="center" style={{opacity: "70%"}}>
            <Grid item style={{marginTop: "15%", textAlign: "center"}}>
              <img src={noDataImg} style={{height: "50%", width: "50%"}} />
            </Grid>
            <Grid item style={{marginTop: "2rem", marginLeft: "5px", textAlign: "center"}}>
              <Typography variant="h6" style={{color: "grey", fontWeight: "bolder"}}>
                {"No Vitals Found"}
              </Typography>
              <Typography variant="subtilte1" style={{color: "grey"}}>
                {"Your patient's vitals will be shown here"}
              </Typography>
            </Grid>
          </Grid>}
      </Grid>
    </React.Fragment>
  )
}