//For auth
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT_FAIL = "LOGOUT_FAIL"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const ONBOARDING_SUCCESS = "ONBOARDING_SUCCESS"
export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const USER_LOADING = "USER_LOADING"
export const USER_LOADED = "USER_LOADED"
export const END_LOADING = "END_LOADING"
export const START_LOADING = "START_LOADING"
export const REMOVE_AUTHENTICATION_STATUS = "REMOVE_AUTHENTICATION_STATUSS"
//For errors
export const GET_ERRORS = "GET_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"
//Organisation
export const SET_USER_ORGANISATIONS = "SET_USER_ORGANISATIONS"
export const SET_CURRENT_ORGANISATION = "SET_CURRENT_ORGANISATION"
export const SET_PATIENTS = "SET_PATIENTS"
//Users
export const SET_SORT_ORDER = "SET_SORT_ORDER"
export const SET_CURRENT_DATE_TYPE = "SET_CURRENT_DATE_TYPE"
export const SET_CURRENT_DATE_TYPE_VALUE = "SET_CURRENT_DATE__TYPE_VALUE"
export const GET_DOCTOR = "GET_DOCTOR"
export const GET_USER_LOADING = "GET_USER_LOADING"
export const GET_USER_LOADED = "GET_USER_LOADED"
export const SET_USER = "SET_USER"
export const SET_USER_LANG = "SET_USER_LANG"
export const REMOVE_USER = "REMOVE_USER"
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES"
export const SET_REMINDER_APPOINTMENT = "SET_REMINDER_APPOINTMENT"

export const SET_CUSTOM_VITALS = "SET_CUSTOM_VITALS"

export const REMOVE_REMINDER_APPOINTMENT = "REMOVE_REMINDER_APPOINTMENT"
export const SET_ALL_CLINICS = "SET_ALL_CLINICS"
export const SET_APPOINTMENT_STATUS = "SET_APPOINTMENT_STATUS"
export const SET_FILTER_OPEN = "SET_FILTER_OPEN"
//Consultation
export const POST_PRESCRIPTION_DETAILS = "POST_PRESCRIPTION_DETAILS"
export const CLEAR_PRESCRIPTION_DETAILS = "CLEAR_PRESCRIPTION_DETAILS"
export const POST_MEDICAL_HISTORY = "POST_MEDICAL_HISTORY"
export const CLEAR_MEDICAL_HISTORY = "CLEAR_MEDICAL_HISTORY"
export const POST_VITALS = "POST_VITALS"
export const CLEAR_VITALS = "CLEAR_VITALS"

export const SAVE_APPOINTMENT_DATA = "SAVE_APPOINTMENT_DATA"
export const DELETE_APPOINTMENT_DATA = "DELETE_APPOINTMENT_DATA"

export const POST_PRESCRIPTION_TEMPLATE = "POST_PRESCRIPTION_TEMPLATE"
export const CLEAR_PRESCRIPTION_TEMPLATE = "CLEAR_PRESCRIPTION_TEMPLATE"

export const SET_DOCTOR_PRESCRIPTION_PREFERENCE =
  "SET_DOCTOR_PRESCRIPTION_PREFERENCE"
export const CLEAR_DOCTOR_PRESCRIPTION_PREFERENCE =
  "CLEAR_DOCTOR_PRESCRIPTION_PREFERENCE"
