import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import Row from 'reactstrap/lib/Row';
import customAxios from '../../../../axios';
import LoadingAnimation from '../LoadingAnimation';
import HidingAnimation from './HidingAnimation';

function ChangePassword({ state, setState, setActiveStep }) {
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const handleSubmit = (state) => {
    setIsLoading(true);
    const temp = { ...state };
    temp['phoneNo'] =                   (temp?.countryCode ? `${temp?.countryCode} ${temp?.phoneNo}` : '+91'+temp?.phoneNo)     ;
    if (password === state.password) {
      customAxios
        .put('/auth/update-password', temp)
        .then((res) => {
          if (res.data.success) {
            history.push('/auth/login');
          }

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, 'err');
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };
  return (
    <Row>
      <Col style={{ justifyContent: 'center' }} className='row'>
        <HidingAnimation />
      </Col>

      <Col md={12} className='text-center'>
        <h1>Reset password</h1>
      </Col>
      <Col md={12}>
        <InputGroup className='p-4 ' style={{ alignItems: 'center' }}>
          <Input
            value={state.password}
            placeholder='Enter you new password'
            type='password'
            onChange={(e) => {
              const temp = { ...state };
              temp['password'] = e.target.value;
              setState({ ...temp });
            }}></Input>
        </InputGroup>
      </Col>
      <Col md={12}>
        <InputGroup className='p-4'>
          <Input
            value={password}
            placeholder='Confirm your new password'
            type='password'
            onChange={(e) => {
              setPassword(e.target.value);
            }}></Input>
        </InputGroup>
      </Col>
      <Col md={12} className='text-center'>
        <Col className='text-center'>
          {isLoading && <LoadingAnimation></LoadingAnimation>}
          {!isLoading && (
            <Button
              onClick={() => handleSubmit(state)}
              className='p-3'
              color='primary'
              block
              type='button'>
              Change Password
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
}

export default ChangePassword;