import React, { useState, useEffect } from "react"
import Button from "reactstrap/lib/Button"
import Col from "reactstrap/lib/Col"
import FormGroup from "reactstrap/lib/FormGroup"
import mixpanel from "mixpanel-browser"

import Input from "reactstrap/lib/Input"
import InputGroup from "reactstrap/lib/InputGroup"
import InputGroupAddon from "reactstrap/lib/InputGroupAddon"
import Label from "reactstrap/lib/Label"
import Row from "reactstrap/lib/Row"
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns" // choose your lib
import moment from "moment"
import { connect } from "react-redux"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField, Typography } from "@material-ui/core"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { isBetween } from "moment"
import { SlowMotionVideoOutlined } from "@material-ui/icons"
import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"
import { useHistory } from "react-router-dom"
import customAxios from "../../axios"
import LoaderFullpage from "../Common/LoaderFullpage"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import prescriptionSocket from "../../socket"

import appointmentLink from "../../assets/img/brand/addAppointment.png"


function PastAppointmentModal({
  open,
  setOpen,
  setModalData,
  setUpdater = () => {},
  modalData = {},
  defaultTabValue = 0,
}) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const currOrg = useSelector((state) => state.user.currOrganisation)
  const [dateSlotsList, setDateSlotsList] = useState([])
  const [timeSlotsList, setTimeSlotsList] = useState([])
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const userId = useSelector((state) => state.user.user._id)
  const user = useSelector((state) => state.user.user)
  const [patientList, setPatientList] = useState([])
  const [updateData, setUpdateData] = useState({})
  const [phoneNo, setPhoneNo] = useState("")
  const [addPatientOpen, setAddPatientOpen] = useState(false)
  const history = useHistory()
  const allOrganisations = useSelector((state) =>
    state?.user?.organisations.map((ele) => ({
      label: ele?.locationName,
      value: ele?._id,
      ...ele,
    }))
  )
  const [doctorList, setDoctorList] = useState([])
  const publicNumbers = useSelector((state) => state?.user?.user?.publicNumbers)
  const [loadingMessage, setLoadingMessage] = useState("")

  const [currOrgId, setCurrOrgId] = useState(() =>
    currOrg?._id !== 0 ? currOrg?._id : allOrganisations?.[0]?._id
  )
  const organisation = useSelector((state) =>
    state?.user?.organisations?.find((ele) => ele._id === currOrgId)
  )
  useEffect(() => {
    if (open) {
      setDialogOpen(true)
    }
  }, [open])

  const handleSubmit = (data) => {
    customAxios
      .put("/appointments/book-patient ", {
        ...data,
        start: moment.utc(new Date(data['start'])).add(330, 'minutes').toISOString(),
      })
      .then((res) => {
        if (res.data.success) {
          if(setUpdater)
            setUpdater((prev) => !prev)
            
          toast.success("Appointment booked successfully.")
          mixpanel.people.increment("Add Past Appointment")
          mixpanel.track("Add Past Appointment")

          // window.location.reload();
        } else {
          if (res.data.message === "Slot is blocked")
            toast.error("Slot is blocked by the doctor.")
          else toast.error("Some error occured")
        }
      })
      .catch((err) => toast.error("Some error occured"))
  }

  const handleCreatePatient = (data, book = true) => {

    customAxios
      .post("/patients/create", { ...data, isNew: true })
      .then((res) => {
        if (res.data.success) {
          const temp = res.data.data

          if (book) {
            handleSubmit({
              ...data,
              patientId: temp._id,
            })
          } else {
            setUpdateData({
              name: data.name,
              age: data.age,
              ageString: data.ageString,
              gender: data.gender,
              patientId: temp._id,
            })

            toast.success("Patient created successfully.")
          }
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        toast.error("Some error occured")
      })
  }

  useEffect(() => {
    if (!isDoctor) {
      customAxios.get(`/doctor/`, {}).then((res) => {
        if (res?.data?.success) {
          const finalArr = res.data.data.map((ele) => ({
            label: ele.name,
            value: ele._id,
          }))
          setDoctorList([...finalArr])
        } else {
          toast.error(
            "No organisations found. Please tell admin to grant location permissions"
          )
        }
      })
    }
  }, [isDoctor])

  const handleSearchPatient = (query, setState) => {
    if (query.length === 10) {
      prescriptionSocket.emit("search_patient", {
        phoneNo: query,
        userId: isDoctor ? userId : allOrganisations[0].admin,
      })
      prescriptionSocket.once("search_patient", (args) => {
        if (args.isExists) {
          console.log(Array.isArray(args.patient), "po")
          if (Array.isArray(args.patient)) {
            const temp = args.patient.map((ele) => ({
              label: ele.name,
              value: ele.name,
              ...ele,
            }))
            setPatientList([...temp])
            setState((prev) => ({
              ...prev,
              name: args.patient[0].name,
              phoneNo: args.patient[0].phoneNo,
              age: args.patient[0].age,
              ageString: args.patient[0]?.ageString,

              gender: args.patient[0].gender,
              // ...args?.patient,
              patientId: args.patient[0]._id,
            }))
          } else
            setState((prev) => ({
              ...prev,
              name: args.patient.name,
              phoneNo: args.patient.phoneNo,
              age: args.patient.age,
              ageString: args.patient[0]?.ageString,

              gender: args.patient.gender,
              // ...args?.patient,
              patientId: args.patient._id,
            }))
        }
      })
    } else {
      setState((prev) => ({
        ...prev,
        patientId: null,
        name: null,
        gender: "male",
        age: 0,
      }))
    }
    setPatientList([])
  }

  const addAppointmentDialogContent = [
    {
      key: "phoneNo",
      required: true,
      placeholder: "Search for patients",
      maxLength: "10",
      label: "Phone number",
      onChange: (value, state, setState) => {
        setState({ ...state, phoneNo: value })
        setPhoneNo(value)
        handleSearchPatient(value, setState)
      },
    },
    {
      key: "name",
      label: "Name",
      type: patientList.length ? "select" : "text",
      options: patientList,
      required: true,
      otherProps: {
        helperText: patientList.length ? (
          <Typography>
            <span
              style={{
                color: "primary",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                setAddPatientOpen(true)
              }}
            >
              Click here
            </span>{" "}
            to add new patient
          </Typography>
        ) : null,
      },
      onChange: (value, state, setState) => {
        if (patientList.length) {
          const patient = patientList.find((ele) => value === ele.value)
          setState((prev) => ({
            ...prev,
            patientId: patient._id,
            name: value,
            phoneNo: patient.phoneNo,
            age: patient.age,
          }))
        } else {
          setState((prev) => ({ ...prev, name: value }))
        }
      },
    },
    {
      key: "ageString",
      label: "Age",
      unitOptions1: ['Years', "Months", "Days"],
      unitOptions: ['Year', 'Month', 'Day'],
      placeholder: "e.g. 10 Years",
      freeSolo: true,
      type: "unit-autocomplete"
    },
    {
      label: "Gender",
      key: "gender",
      type: "select",
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Other", value: "other" },
      ],
    },
    {
      md: 12,
      key: "orgId",
      label: "Location",

      type: "select",
      required: true,
      options: allOrganisations,
      onChange: (value, state, setState) => {
        setCurrOrgId(value)

        setState((prev) => ({ ...prev, orgId: value, date: null, time: null }))
      },
    },
    {
      md: 12,
      key: "start",
      required: true,
      label: "Date of Appointment",
      type: "datetime",
      onChange: (value, state, setState) => {
        let temp = { ...state }
        temp["start"] = moment.utc(value)?.toISOString()
        console.log("pola43", value, temp)
        setState({ ...temp })
      }
    },
  ]
 
  return (
    <>
      <div>
      {" "}
      <LoaderFullpage loading={loading} message={loadingMessage} />
      {dialogOpen ? (
        <GenericDialogForm
          updateData={updateData}
          autoClose={true}
          maxWidth={"sm"}
          fullWidth={true}
          tabs={[
            {
              label: "Add Past appointment",
              value: 0,
              dialogContent: addAppointmentDialogContent,

              message: (
                <div
                  style={{
                    display: "flex",
                    direction: "column",
                    alignItems: "center",
                    marginBottom: "1rem",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="Whatsapp Logo"
                    src={appointmentLink}
                    style={{
                      marginLeft: "0.2rem",
                      color: "green",
                      width: "100%",
                      borderRadius: "8px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ),
            },
          ]}
          formData={{
            gender: "male",
            orgId:
              currOrg?._id !== 0 ? currOrg._id : allOrganisations?.[0]?._id,
            docId: allOrganisations[0]?.doctors[0].doctorId,
            date: timeSlotsList.length ? new Date(dateSlotsList[0].date) : null,
            time: timeSlotsList.length ? timeSlotsList[0].display : null,
            start: timeSlotsList.length ? timeSlotsList[0].start : null,
            end: timeSlotsList.length ? timeSlotsList[0].end : null,
            ...modalData,
          }}
          dialogTitle={"Add Past appointment"}
          showRequired={true}
          open={dialogOpen}
          submitWithEnter={true}
          defaultTabValue={0}
          handleClose={() => {
            setOpen(false)
            setDialogOpen(false)
          }}
          handleSubmit={(data, tab) => {
            switch (tab) {
              case 0:{
                if(data.patientId)
                  handleSubmit(data)
                else
                  handleCreatePatient(data, true)
              }
                break
              default:
            }
          }}
        />
      ) : null}
      {addPatientOpen ? (
        <GenericDialogForm
          open={addPatientOpen}
          setOpen={setAddPatientOpen}
          dialogContent={[
            {
              key: "name",
              label: "Name",
              required: true,
            },
            {
              key: "ageString",
              label: "Age",
              unitOptions1: ['Years', "Months", "Days"],
              unitOptions: ['Year', 'Month', 'Day'],
              placeholder: "e.g. 10 Years",
              freeSolo: true,
              type: "unit-autocomplete"
            },
            {
              label: "Gender",
              key: "gender",
              type: "select",
              options: [
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ],
            },
          ]}
          formData={{ gender: "male", phoneNo: phoneNo }}
          handleSubmit={(data) => {
            setPatientList([])

            handleCreatePatient(data, false)
          }}
          dialogTitle="Add new patient"
        />
      ) : null}
    </div>
    </>
  )
}

export default PastAppointmentModal
