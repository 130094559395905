import React, { useState, useEffect } from "react";
import { FormGroup, Input, Col, Row, Modal } from "reactstrap";
import Button from "reactstrap/lib/Button";
import CustomInput from "reactstrap/lib/CustomInput";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import Label from "reactstrap/lib/Label";
import customAxios from "../../axios";
import moment from "moment";
import { toast } from "react-toastify";
import InputGroupText from "reactstrap/lib/InputGroupText";
function AddStaff({
  modalData,
  isOpen,
  setModalData,
  callback,
  setIsOpen,
  handleSubmit,
}) {
  const [allOrganisations, setAllOrganisations] = useState([]);
  useEffect(() => {
    customAxios
      .get("/organisation/all")
      .then((res) => {
        const temp = res?.data?.data?.map((ele) => {
          if (modalData?.organisations?.find((element) => element === ele._id))
            return { ...ele, isSelected: true };
          else 
          return { ...ele, isSelected: false };
        });
        setAllOrganisations([...temp]);
      })
      .catch((err) => console.log(err));
  }, [modalData.organisations]);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {modalData.new ? "Add Staff" : "Edit Staff"}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setIsOpen(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col md="6">
            <FormGroup>
              <Label className="md-2">Name</Label>
              <Input
                id="exampleFormControlInput1"
                value={modalData.name}
                onChange={(e) => {
                  setModalData({
                    ...modalData,
                    name:
                      e.target.value?.length < 40
                        ? e.target.value
                        : modalData?.name,
                  });
                }}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="md-2">Email</Label>
              <Input
                id="exampleFormControlInput1"
                value={modalData.email}
                onChange={(e) => {
                  setModalData({ ...modalData, email: e.target.value });
                }}
                type="email"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="md-2">Phone</Label>
              <InputGroup>
                {/* <InputGroupAddon>+91</InputGroupAddon> */}

                <Input
                  id="exampleFormControlInput1"
                  value={modalData.phoneNo}
                  onChange={(e) => {
                    setModalData({ ...modalData, phoneNo: e.target.value });
                  }}
                  type="number"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i
                      class="far fa-copy"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigator.clipboard
                          .writeText(modalData.phoneNo)
                          .then((res) => {
                            toast.success("Phone Number copied successfully.");
                          })
                      }
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="md-2">Date of Birth</Label>

              <Input
                id="exampleFormControlInput1"
                value={modalData.birthDate}
                onChange={(e) => {
                  setModalData({ ...modalData, birthDate: e.target.value });
                }}
                type="date"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="md-2">Has Access to:</Label>
            </FormGroup>
          </Col>
          {allOrganisations.map((ele, key) => {
            console.log(ele.isSelected);
            return (
              <Col md="6" className="m-0">
                {/* <Card>
                  <CardBody> */}
                <FormGroup>
                  <CustomInput
                    className="m-4"
                    type="switch"
                    key={key}
                    checked={ele.isSelected}
                    onChange={(e) => {
                      const temp = [...allOrganisations];
                      console.log("e", e.target.checked);
                      temp[key]["isSelected"] = e.target.checked;
                      console.log("temp", temp);
                      setAllOrganisations([...temp]);
                    }}
                    id={key}
                    label={ele.locationName}
                  />
                </FormGroup>
                {/* </CardBody>
                </Card> */}
              </Col>
            );
          })}
          <Col md="12" style={{ marginBottom: "-2%" }}>
            <Label className="md-2" style={{ fontWeight: "bold" }}>
              Default Password: paperplane
              <i
                class="far fa-copy"
                style={{ paddingLeft: "10px", cursor: "pointer" }}
                onClick={() =>
                  navigator.clipboard.writeText("paperplane").then((res) => {
                    toast.success("Password copied successfully.");
                  })
                }
              ></i>{" "}
            </Label>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => setIsOpen(false)}
        >
          Discard
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={() => handleSubmit(modalData, allOrganisations)}
        >
          {modalData.new ? "Save" : "Save changes"}
        </Button>
      </div>
    </Modal>
  );
}

export default AddStaff;
