import { Backdrop, CircularProgress, makeStyles, Grid, DialogTitle, IconButton, DialogContent, Dialog, DialogActions, Button } from "@material-ui/core"
import AWS from "aws-sdk"

import React, { useEffect, useRef, useState } from "react"
import { useParams, useHistory, useLocation } from "react-router"
import customAxios from "../../../axios"
import Sidebar from "../../../components/Consultation(new)"
import CreatePrescription from "../../../components/CreatePrescription"
import { toast } from "react-toastify"
import moment from "moment"
import MedicalRecord from "../../../components/Consultation(new)/MedicalRecord"
import PatientAssessment from "../../../components/Consultation(new)/PatientAssessment"
import AppointmentHistory from "../../../components/Consultation(new)/AppointmentHistory"
import { useSelector } from "react-redux"
import EndConsultation from "../../../components/Consultation(new)/EndConsultation"
import mixpanel from "mixpanel-browser"
import LoaderFullpage from "../../../components/Common/LoaderFullpage"
import successAnimation from "./../../../assets/animationData/success.json"
import celebrationAnimation from "./../../../assets/animationData/celebration.json"
import { Close } from "@material-ui/icons"
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}))
function Consultation() {
  const routeHistory = useHistory()
  const location = useLocation()

  const classes = useStyles()
  const { id } = useParams()
  const preferences = useSelector((state) => state?.user.preferences)
  const doctor = useSelector((state) => state?.user.user)

  const isOH = useSelector((state) => state.user?.user?.ohID)


  const [loading, setLoading] = useState(false)
  const [historyColumnFreeze, setHistoryColumnFreeze] = useState(false)

  const [appointmentData, setAppointmentData] = useState()
  const [previousState, setPreviousState] = useState(null)
  const [loadingEndPrescription, setLoadingEndPrescription] = useState(false)
  const [state, setState] = useState({
    saveTemplate: false,
    medicines: [],
    diagnosis: [],
    symptoms: [],
    tests: [],
    labResults: [],
    vitals: [],
    exercises: [],
  })
  const [activeStep, setActiveStep] = useState("Write Prescription");
  const requiredKeys = [
    // { key: "diagnosis", label: "Diagnosis" },
    // { key: "symptoms", label: "Symptoms" },
  ]
  const getActiveStep = (activeStep) => {
    switch (activeStep) {
      case "Write Prescription":
        return (
          <CreatePrescription
            state={state}
            previousState={previousState}
            historyColumnFreeze={historyColumnFreeze}
            setHistoryColumnFreeze={setHistoryColumnFreeze}
            docId={appointmentData?.docId}
            patientData={appointmentData?.patient}
            appointmentData={appointmentData}
            setLoading={setLoading}
            handleEndConsultation={handleEndConsultation}
            printModalData={printModalData}
            setPrintModalData={setPrintModalData}
            loading={loading}
            requiredKeys={requiredKeys}
            setState={setState}
            setActiveStep={setActiveStep}
            preferences={preferences}
            loader={loader}
            templateList={templateList}
            setTemplateList={setTemplateList}
          />
        )
      case "Medical Record":
        return (
          <MedicalRecord
            patientData={appointmentData?.patient}
            setLoading={setLoading}
            loading={loading}
          />
        )
      case "Patient Assessment":
        return (
          <PatientAssessment
            patientData={appointmentData?.patient}
            setLoading={setLoading}
            loading={loading}
          />
        )
      case "Appointment History":
        return (
          <AppointmentHistory
            patientData={appointmentData?.patient}
            setLoading={setLoading}
            loading={loading}
          />
        )

      default:
        ;<h1>Error</h1>
    }
  }

  useEffect(() => {
    if(doctor?.additionalDetail?.presLang){
      setState((prev) => ({ ...prev, language: doctor.additionalDetail.presLang }))
    }
  }, [doctor])

  useEffect(() => {
    if (appointmentData)
      customAxios
        .get(
          `/appointments/get-last-appointment/${appointmentData.patient._id}`
        )
        .then((res) => {
          if (res.data.success) {
            customAxios
              .get(
                `/consultation/filter?appointment_id=${res.data.data.appointmentId}&is_template=false`
              )
              .then((res) => {
                if (res.data.success) {
                  if (res.data.data?.length) {
                    const template = res?.data?.data[0]
                    setPreviousState((prev) => ({
                      ...prev,
                      ...template,
                      ...template?.prescription,
                      medicines: template?.prescription?.medicines
                        ? template?.prescription?.medicines
                        : [],
                      diagnosis: template?.prescription?.diagnosis
                        ? template?.prescription?.diagnosis
                        : [],
                      symptoms: template?.prescription?.symptoms
                        ? template?.prescription?.symptoms
                        : [],
                      tests: template?.prescription?.tests
                        ? template?.prescription?.tests?.map((ele) => ({
                            name: ele,
                          }))
                        : [],
                    }))
                  }
                } else {
                  console.log("err")
                }
              })
              .catch((err) => console.log(err))
          } else {
            console.log("err")
          }
        })
        .catch((err) => console.log("err"))
  }, [appointmentData])

  useEffect(() => {
    setLoading(true)
    //call fecth appointment api
    customAxios
      .get(`/appointments/${id}`)
      .then((res) => {
        if (res) {
          if (res.data.data.completed) {
            customAxios
              .get(
                `/consultation/filter?appointment_id=${res.data.data._id}&is_template=false`
              )
              .then((res) => {
                if (res.data.success) {
                  if (res.data.data?.length) {
                    const template = res?.data?.data[0]

                    setState((prev) => ({
                      ...prev,
                      ...template?.prescription,
                      medicines: template?.prescription?.medicines
                        ? template?.prescription?.medicines
                        : [],
                      diagnosis: template?.prescription?.diagnosis
                        ? template?.prescription?.diagnosis
                        : [],
                      symptoms: template?.prescription?.symptoms
                        ? template?.prescription?.symptoms
                        : [],
                      tests: template?.prescription?.tests
                        ? template?.prescription?.tests?.map((ele) => ({
                            name: ele,
                          }))
                        : [],
                    }))
                  } else {
                    toast.warning("No previous prescription found")
                    setState({
                      saveTemplate: false,
                      medicines: [],
                      diagnosis: [],
                      symptoms: [],
                      tests: [],
                      vitals: [],
                      exercises: [],
                    })
                  }
                }
              })
          }
          setAppointmentData(res.data.data)
          customAxios
            .get(
              `/vital/${res.data.data?.patient?._id}?doc_id=${res?.data?.data?.docId}`
            )
            .then((res) => {
              if (res.data.success) {
                if (res.data.data) {
                  const temp = res.data.data.vitals
                  temp.reverse()
                  setState((prev) => ({ ...prev, vitals: temp?.[0].data }))
                  setPreviousState((prev) => ({
                    ...prev,
                    vitals: temp?.[0].data,
                  }))
                }
              }
            })
            .catch((err) => console.log(err))
        }
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      })
      .catch((err) => {
        console.log("Error", err)
        setLoading(false)
      })
  }, [id])
  const handleDownloadPrescription = (
    data,
    download = false,
    print = false
  ) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    // AWS.config.update({
    //   accessKeyId: "AKIAUSXZZ7HZK47LXEVL",
    //   secretAccessKey: "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",
    // })
    // var s3 = new AWS.S3()
    // let params = {
    //   Bucket: "paperplane-s3",
    //   Key: `${data._id}`,
    // }

    fetch(data?.url)
        .then((response) => {
          if (response.ok) {
            return response.blob()
          }}).then(blob => {

            if(blob){
              if (download) {
                a.href = window.URL.createObjectURL(blob)
                a.download = `${appointmentData?.patient?.name}_(#${appointmentData?.patient?.code})_PrescriptionPreview`
                a.click()
                window.URL.revokeObjectURL(data?.url)
              }
              if (print) {
                let pdfw = window.open(window.URL.createObjectURL(blob))
                // you need to call print after window loads like this
                if (pdfw)
                  pdfw.onload = function () {
                    pdfw.print()
                  }
                else {
                  window.alert("Please allow popups")
                }
              }
            }
            setLoading(false)
          })
          
    // s3.getObject(params, (err, res) => {
    //   let blob = new Blob([res.Body], { type: res.ContentType })
    //   if (download) {
    //     a.href = window.URL.createObjectURL(blob)
    //     a.download = `${appointmentData?.patient?.name}_(#${appointmentData?.patient?.code})_PrescriptionPreview`
    //     a.click()
    //     window.URL.revokeObjectURL(data?.url)
    //   }
    //   if (print) {
    //     let pdfw = window.open(window.URL.createObjectURL(blob))
    //     // you need to call print after window loads like this
    //     if (pdfw)
    //       pdfw.onload = function () {
    //         pdfw.print()
    //       }
    //     else {
    //       window.alert("Please allow popups")
    //     }
    //   }
    //   setLoading(false)
    // })
  }

  const [printModalData, setPrintModalData] = useState({"open": false, "imgUrls": []})

  const [templateList, setTemplateList] = useState([])

  const [loader, setLoader] = useState({type: "", loading: false})

  const handleEndConsultation = (config) => {
    const tests = state?.tests?.map((v) => v.name)
    setLoading(true)

    if(config?.print || config?.download || !config?.onlyPrescription){
      setLoading(false)
      setLoader({type: config?.print ? "print": (!config?.onlyPrescription ? "end": "download"), loading: true})
    }

    let error = {}
    if (
      config?.additionalPhoneNumber &&
      config?.additionalPhoneNumber?.toString().length !== 10
    )
      error = {
        check: true,
        message: "The addtional phone number must be of 10 digits",
      }

    if (!appointmentData?.patient)
      error = { check: true, message: "Patient not found" }

    requiredKeys.map((v) => {
      if (!state[v.key] || !state[v.key].length) {
        error = { check: true, message: `${v.label} is required` }
      }
    })
    if (error?.check) {
      setLoading(false)

      toast.error(error.message)
    } else {
      const addOnData = {}
      if (config?.additionalPhoneNumber) {
        addOnData["additionalNos"] = [config?.additionalPhoneNumber]
      }

      if(config?.endConsultation) {
          customAxios
            .put(`consultation/generate-prescription/${id}`, {
              ...state,
              newPrescription: appointmentData.completed ? false : true,
              ...config,
              ...addOnData,
              tests: tests,
            })
            .then((res) => {
              if (res.data?.success) {
                if (!config?.onlyPrescription) {
                  setLoading(false)
                  setLoader({type: "", loading: false})
                  setPrintModalData({...printModalData, open: false})

                  mixpanel.track("Appointment Completed")
                  mixpanel.people.increment("Appointment Completed")
                  mixpanel.track("Prescription Sent")
                  mixpanel.people.increment("Prescription Sent")
                  setLoadingEndPrescription(true)
                  setTimeout(() => {
                    setLoadingEndPrescription(false)
                    
                    if (!isOH) {
                      routeHistory.push("/admin/dashboard")
                    } else {
                      window.open("https://partner.orangehealth.in/write-prescription", '_self')
                    }
                  }, 3000)
                } else {
                  handleDownloadPrescription(
                    res.data.data,
                    config.download,
                    config.print
                  )
                }
              } else {
                setLoading(false)
                setLoader({type: "", loading: false})

                toast.error("Some error occured")
              }
            })
            .catch((err) => {
              setLoading(false)
              setLoader({type: "", loading: false})
    
              console.log(err)
            }) 
          } else {
            if (state?.vitals?.length) {
              const objectToSend = {}
              const objInViatls = {
                date: moment.now(),
                data: [...(state["vitals"].filter(f => f?.metricQuantity === 0 || f?.metricQuantity))],
              }
              objectToSend.vitals = []
              objectToSend.vitals.push(objInViatls)
              customAxios
                .put(
                  `/vital/${appointmentData?.patient["_id"]}?doc_id=${appointmentData?.docId}`,
                  objectToSend
                )
                .then((response) => {
                  console.log("response on saving vitals", response)
                  if(config?.onlyPrescription ){
                    // if(config?.print) {
                    //   customAxios.put(`consultation/Rx-preview`, {
                    //     ...state,
                    //     ...config,
                    //     ...addOnData,
                    //     patientId: appointmentData?.patientId,
                    //     orgId: appointmentData?.orgId,
                    //     tests: tests
                    //   }).then((response) => {
                    //     setLoading(false)
                    //     if(response.data.success) {
                    //       setPrintModalData({"open": true, "imgUrls": response.data.data})
                    //       // handlePrint1(response?.data?.data?.[0])
                    //     }
                    //   })
                    // } else {
                      customAxios
                  .put(`consultation/generate-prescription/${id}`, {
                    ...state,
                    newPrescription: appointmentData.completed ? false : true,
                    ...config,
                    ...addOnData,
                    tests: tests,
                  })
                  .then((res) => {
                    if (res.data?.success) {
                      setLoading(false)
                      setLoader({type: "", loading: false})
                      
                      if (!config?.onlyPrescription) {
                        setPrintModalData({...printModalData, open: false})
                        mixpanel.track("Appointment Completed")
                        mixpanel.people.increment("Appointment Completed")
                        mixpanel.track("Prescription Sent")
                        mixpanel.people.increment("Prescription Sent")
                        setLoadingEndPrescription(true)
                        setTimeout(() => {
                          setLoadingEndPrescription(false)
                          
                          if (!isOH) {
                            routeHistory.push("/admin/dashboard")
                          } else {
                            window.open("https://partner.orangehealth.in/write-prescription", '_self')
                          }
                        }, 3000)
                      } else {
                        if(config.print){
                          setPrintModalData({"open": true, "data": res.data.data, url: res.data?.data?.url})
                        } else {
                          handleDownloadPrescription(
                            res.data.data,
                            config.download,
                            config.print
                          )
                        }
                      }
                    } else {
                      setLoading(false)
                      setLoader({type: "", loading: false})

                      toast.error("Some error occured")
                    }
                  })
                  .catch((err) => {
                    setLoading(false)
                    setLoader({type: "", loading: false})
                    setPrintModalData({...printModalData, open: false})
          
                    console.log(err)
                  }) 
                    }
            
                  } 
                  // }
                )
                  
            } else {
      
              // if(config?.onlyPrescription && config?.print){
        
              //   customAxios.put(`consultation/Rx-preview`, {
              //     ...state,
              //     ...config,
              //     ...addOnData,
              //     patientId: appointmentData?.patientId,
              //     orgId: appointmentData?.orgId,
              //     tests: tests
              //   }).then((response) => {
              //     setLoading(false)
              //     if(response.data.success) {
              //       setPrintModalData({"open": true, "imgUrls": response.data.data})
              //       // handlePrint1(response?.data?.data?.[0])
              //     }
              //   })
        
              // } else{
                customAxios
                  .put(`consultation/generate-prescription/${id}`, {
                    ...state,
                    newPrescription: appointmentData.completed ? false : true,
                    ...config,
                    ...addOnData,
                    tests: tests,
                  })
                  .then((res) => {
                    if (res.data?.success) {
                      setLoading(false)
                      setLoader({type: "", loading: false})

                      if (!config?.onlyPrescription) {
                        setPrintModalData({...printModalData, open: false})
                        mixpanel.track("Appointment Completed")
                        mixpanel.people.increment("Appointment Completed")
                        mixpanel.track("Prescription Sent")
                        mixpanel.people.increment("Prescription Sent")
                        setLoadingEndPrescription(true)
                        setTimeout(() => {
                          setLoadingEndPrescription(false)

                          if (!isOH) {
                            routeHistory.push("/admin/dashboard")
                          } else {
                            window.open("https://partner.orangehealth.in/write-prescription", '_self')
                          }
                        }, 3000)
                      } else {
                        if(config.print){
                          setPrintModalData({"open": true, "data": res.data.data, url: res.data?.data?.url})
                        } else {
                          handleDownloadPrescription(
                            res.data.data,
                            config.download,
                            config.print
                          )
                        }
                      }
                    } else {
                      setLoading(false)
                      setLoader({type: "", loading: false})
                      toast.error("Some error occured")
                    }
                  })
                  .catch((err) => {
                    setLoading(false)
                    setLoader({type: "", loading: false})
          
                    console.log(err)
                  }) 
                // }
            }
      }
      }
  }

  const handlePrint = (imageUrl) => {
    // const content = document.getElementById("dialog-content");
    // const pri = window.open("", "", "height=600,width=800");
    // pri.document.write(content.innerHTML);
    // pri.document.close();
    // pri.focus();
    // content.print();
    // pri.close();
    // const iframeWindow = iframeRef.current.contentWindow;
    // iframeWindow.document.body.innerHTML = content.innerHTML;
    // iframeWindow.print();

    const content = contentRef.current;

    const printWindow = iframeRef.current.contentWindow;
    printWindow.document.write('<html><head><title>Print</title></head><body></body></html>');
    printWindow.document.body.innerHTML = (content.innerHTML);
    printWindow.document.close();
    printWindow.print();
  };

  
  const contentRef = useRef(null);
  const iframeRef = useRef(null);
  const handlePrint1 = (imageUrl) => {
    alert(imageUrl)
    const iframeWindow = iframeRef.current.contentWindow;
    iframeWindow.document.body.innerHTML =  
    `<html>
    <head>
      <style>
        @media print {
          img {
            width: 100%;
            height: auto;
          }
        }
      </style>
    </head>
    <body>
      <img src="${imageUrl}" />
    </body>
  </html>`;
    iframeWindow.print();
  };

  return (
    <>
      <LoaderFullpage />
      {loadingEndPrescription ? (
        <LoaderFullpage
          enableSound={true}
          loading={loadingEndPrescription}
          message="Consultation ended successfully. Prescription sent on WhatsApp"
          animationData={{
            animationData: celebrationAnimation,
            height: 600,
            width: 600,
          }}
        />
      ) : null}

      {/* <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop> */}
      <div style={{ padding: "1rem" }}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Sidebar
              setLoading={setLoading}
              appointmentData={appointmentData}
              patientData={appointmentData?.patient}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleEndConsultation={handleEndConsultation}
              state={state}
              setState={setState}
              templateList={templateList}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <div
              style={{
                height: "95vh",
                overflowY: "auto",

                padding: "0rem 1.5rem",
              }}
            >
              {getActiveStep(activeStep)}
              
            </div>
          </Grid>
        </Grid>
        {/* <Dialog open={printModalData?.open} onClose={() => setPrintModalData({...printModalData, open: false})}>
        <DialogTitle>
          Image
          <IconButton
            aria-label="close"
            onClick={() => setPrintModalData({...printModalData, open: false})}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent id="dialog-content" ref={contentRef}>
        <iframe title="Print Frame" style={{ display: 'none' }} ref={iframeRef} />
          <img src={printModalData?.imgUrls?.[0] ? printModalData?.imgUrls?.[0]: ""} alt="image"  style={{ maxWidth: '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handlePrint(printModalData?.imgUrls?.[0])} color="primary">
            Print
          </Button>
          <Button onClick={() => setPrintModalData({...printModalData, open: false})} color="primary">
            Close
          </Button>
        </DialogActions>      </Dialog> */}
      </div>
    </>
  )
}

export default Consultation
