import React, { useEffect, useState } from "react"
import "./medical.history.styles.css"
import { Row, Col } from "reactstrap"
import noMedicalHistory from "./../../assets/img/consultation/NoMedicalHistory.png"
import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core"
import { connect } from "react-redux"
//Actions
import customAxios from "../../axios"
import moment from "moment"
import { useHistory } from "react-router"
function MedicalHistory({ id, doctor }) {
  const [patientHistory, setPatientHistory] = useState(null)
  const history = useHistory()
  const getTextField = (type, ele, idx) => {
    switch (type) {
      case "text":
        return (
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              inputProps={{ readOnly: true }}
              value={ele.answer}
              type="text"
              variant="outlined"
              color="primary"
            ></TextField>
          </Grid>
        )
      case "date":
        return (
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              inputProps={{ readOnly: true }}
              variant="outlined"
              type="date"
              color="primary"
              value={ele.answer}
            ></TextField>
          </Grid>
        )
        case "age":
          return (
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                inputProps={{ readOnly: true }}
                variant="outlined"
                type="date"
                color="primary"
                value={ele.answer}
              ></TextField>
            </Grid>
          )
      case "checkbox":
        const answer = ele?.answer ? JSON.parse(ele.answer) : []
        console.log("answer", answer)
        return (
          <Grid item xs={12}>
            {ele.options.map((option, optionIndex) => {
              return (
                <FormControlLabel
                  label={option}
                  disabled
                  checked={
                    answer.findIndex((element) => element === optionIndex) >= 0
                      ? true
                      : false
                  }
                  control={
                    <Checkbox
                      disabled
                      color="primary"
                      inputProps={{ disabled: true }}
                    />
                  }
                />
              )
            })}
          </Grid>
        )
      case "radio":
        return (
          <Grid item xs={12}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={ele.answer}
              disabled
            >
              {ele?.options?.map((option, optionIndex) => (
                <FormControlLabel
                  label={option}
                  control={
                    <Radio
                      inputProps={{ readOnly: true }}
                      color="primary"
                      disabled
                    />
                  }
                  value={option}
                />
              ))}
            </RadioGroup>
          </Grid>
        )
      default:
        return (
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              inputProps={{ readOnly: true }}
              value={ele.answer}
              type="text"
              variant="outlined"
              color="primary"
            ></TextField>
          </Grid>
        )
    }
  }
  useEffect(() => {
    customAxios
      .get(`/patients?patient_id=${id}`)
      .then((res) => {
        if (res.data.success) {
          const response = res.data.data.patientCharacterstic.find((ele) => {
            return ele.docId === doctor.user._id
          })
          if (Array.isArray(response?.data)) setPatientHistory(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    // eslint-disable-next-line
  }, [id])

  return (
    <Row className="p-4 no-gutters" style={{ minHeight: "60vh" }}>
      <Col xs="12">
        <Col md="12" xs="12" style={{marginBottom: "3rem"}}>
          {patientHistory ? <Typography variant="h5" style={{fontWeight: "600"}}>
                {" "}
                Last updated by patient on{" "}
                {moment.utc(patientHistory?.date).format("DD-MM-YYYY")}
          </Typography> : null}
          <Typography variant="body1">
            This form is shared after an appointment is booked through
            WhatsApp. Create your own form{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                history.push("/admin/customise-form")
              }}
            >
              here
            </span>
          </Typography>
        </Col>
        {patientHistory ? (
          <div className="col-md-12 m-1">
            {/* <Col md="11" className="text-center mb-4">
              <h2>
              </h2>
            </Col> */}
            {patientHistory?.data?.map((ele, idx) => (
              <Row className="no-gutters mb-3" style={{marginTop: "1.5rem"}}>
                <Col md="11" className="mb-2" style={{display: "flex", alignItems: "center"}}>
                  <Typography style={{fontWeight: "600", fontSize: "16px"}}>
                    {idx + 1}.
                  </Typography>
                  <Typography style={{fontWeight: "600", fontSize: "16px", marginLeft: "5px"}}>
                    {ele.question}
                  </Typography>
                </Col>
                <Col md="10" style={{marginLeft: "15px"}}>{getTextField(ele.type, ele, idx)}</Col>{" "}
              </Row>
            ))}
          </div>
        ) : (
          <Col className="text-center  ">
            <img src={noMedicalHistory} alt="No medical history"></img>
            <h1>No Records Found.</h1>
          </Col>
        )}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  doctor: state.user,
  appointment: state.consultation.appointment,
})

export default connect(mapStateToProps)(MedicalHistory)
