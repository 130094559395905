import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
  makeStyles,
  Avatar,
} from "@material-ui/core"
import { CalendarTodayOutlined } from "@material-ui/icons"
import moment from "moment"
import ReactDatetime from "react-datetime"

const useStyles = makeStyles((theme) => ({
  inputDate: {
    width: "90%",
    "& input": {
      height: "57px",
      border: "1px solid #ccc",
      "&:hover": {
        borderColor: "black",
      },
    },
  },
  dateButton: {
    width: "10%",
    cursor: "default !important",
  },
}))

function FollowUp({ state, setState, setActiveIndex, activeIndex, padIndex }) {
  const re = /^[0-9\b]+$/
  const classes = useStyles()

  const [followUp, setFollowUp] = useState(
    state.followUp?.[0] ? state?.followUp?.[0] : {}
  )
  const [radioInput, setRadioInput] = useState({ type: "", unit: "" })

  const currentDate = moment.now()
  const disablePastDt = (current) => {
    return current.isAfter(currentDate)
  }

  useEffect(() => {
    if (radioInput?.type && radioInput?.unit) {
      const afterDate = moment().add(Number(radioInput?.unit), radioInput?.type)
      setFollowUp({
        ...followUp,
        date: new Date(
          new Date(afterDate).getTime() + 330 * 60000
        ).toISOString(),
      })
    }
  }, [radioInput])

  useEffect(() => {
    if (followUp?.date) setState({ ...state, followUp: [{ ...followUp }] })
  }, [followUp])

  const radioHeaders = [
    { label: "Days", value: "day" },
    { label: "Weeks", value: "week" },
    { label: "Months", value: "month" },
  ]

  const textFieldsRefs = useRef([]);

  function handleKeyDown(event, index) {
    if (event.key === "ArrowRight" && index < textFieldsRefs.current.length - 1) {
      textFieldsRefs.current[index + 1].focus();
    } else if (event.key === "ArrowLeft" && index > 0) {
      textFieldsRefs.current[index - 1].focus();
    }
    else if (event.key === "ArrowUp" && padIndex > 0) {
      setActiveIndex({"index": padIndex - 1, "from": padIndex})
    } else if (event.key === "ArrowDown")
      setActiveIndex({"index": padIndex + 1, "from": padIndex})
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
        <Avatar style={{
          fontSize: 18,
          width: 30,
          height: 30, 
          marginRight: 10, 
          color: "#5e72e4", 
          backgroundColor: "white",
        }}>
                    <i className="fas fa-user-clock"></i>
                  </Avatar>
          <Typography variant="h6" style={{fontWeight: "600"}}>Follow Up</Typography>
        </Grid>
        <Grid item container xs={12} spacing={1} alignItems="center">
          <Grid item xs={2} md={"auto"}>
            <Typography>After</Typography>
          </Grid>
          <Grid item xs={3} md={1}>
            <TextField
              variant="outlined"
              value={radioInput?.unit}
              onChange={(e) =>
                e.target.value === "" || re.test(e.target.value)
                  ? setRadioInput({ ...radioInput, unit: e.target.value })
                  : null
              }
              placeholder="00"
              inputRef={(ref) => textFieldsRefs.current[0] = ref}
              onKeyDown={(e) => handleKeyDown(e, 0)}
            />
          </Grid>
          <Grid item xs={7} md={4}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={radioInput?.type}
                onChange={(e) =>
                  setRadioInput({ ...radioInput, type: e.target?.value })
                }
                innerRef={(ref) => textFieldsRefs.current[1] = ref}
                onKeyDown={(e) => handleKeyDown(e, 1)}
              >
                {radioHeaders.map((v) => (
                  <FormControlLabel
                    value={v?.value}
                    label={v?.label}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            style={{ display: "flex", flex: "flex-wrap" }}
          >
            <Button
              color="primary"
              variant="contained"
              className={classes.dateButton}
              disableElevation
              disableFocusRipple
              disableRipple
            >
              <CalendarTodayOutlined />
            </Button>
            <ReactDatetime
              dateFormat="DD/MM/YYYY"
              inputProps={{
                placeholder: "Your Selected Date",
              }}
              className={classes.inputDate}
              timeFormat={false}
              ref={(ref) => textFieldsRefs.current[2] = ref}
              onKeyDown={(e) => handleKeyDown(e, 2)}
              renderDay={(props, currentDate, selectedDate) => {
                let classes = props.className
                return (
                  <td {...props} className={classes}>
                    {currentDate.date()}
                  </td>
                )
              }}
              value={followUp?.date ? moment.utc(followUp?.date) : null}
              onChange={(e) => {
                setFollowUp({
                  ...followUp,
                  date: e
                    ? new Date(
                        new Date(e).getTime() + 330 * 60000
                      ).toISOString()
                    : null,
                })
              }}
              isValidDate={disablePastDt}
            />
          </Grid>
        </Grid>
        {followUp?.date ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Follow Up Advice:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 100 }}
                value={followUp?.advice ? followUp?.advice : ""}
                onChange={(e) =>
                  setFollowUp({ ...followUp, advice: e.target?.value })
                }
                placeholder="eg: Drink 8 litres of water everyday"
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  )
}

export default FollowUp
