/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react"
import { Autocomplete } from "@material-ui/lab"
import ReactGA from "react-ga4"
// nodejs library that concatenates classes
import classnames from "classnames"
import profileIcon from "../../assets/icon.jpg"
// nodejs library to set properties for components
import PropTypes from "prop-types"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
  Media,
  Navbar,
  Nav,
  Container,
} from "reactstrap"
import { useHistory } from "react-router"
import { logout } from "../../redux/actions/auth.actions"
import { useDispatch, useSelector } from "react-redux"
import NavItem from "reactstrap/lib/NavItem"
import { SET_CURRENT_ORGANISATION } from "../../redux/constants"
import customAxios from "../../axios"
import { CircularProgress, ListSubheader, TextField, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import InputGroup from "reactstrap/lib/InputGroup"
import InputGroupAddon from "reactstrap/lib/InputGroupAddon"
import InputGroupText from "reactstrap/lib/InputGroupText"
import { useLocation } from "react-router-dom"
import logo from "./../../assets/logos/Group 36999.png"
import displayAge from "../../assets/functions/displayAge"
import { VariableSizeList } from 'react-window';
import { loadPatients } from "../../redux/actions/user.actions"

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    //   color: "purple",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
}))


const VirtualizedOptionsList = React.forwardRef(function VirtualizedOptionsList(props, ref) {
  const { children, ...other } = props;
  const itemCount = React.Children.count(children);
  const itemHeight = 50;
  const visibleItems = 1000;
  const listHeight = Math.min(itemHeight * visibleItems, itemCount * itemHeight);

  // State to keep track of the current scroll offset
  const [scrollOffset, setScrollOffset] = useState(0);

  // Handle the scroll event and update the scroll offset
  // const handleScroll = (event) => {
  //   const { scrollTop } = event.target;
  //   setScrollOffset(Math.floor(scrollTop / itemHeight));
  // };

  return (
    <div ref={ref} style={{ maxHeight: "220px", overflowY: 'auto' }}>
      {React.Children.map(children, (child, index) => {
        if (index >= scrollOffset && index < scrollOffset + visibleItems) {
          return (
            <div {...other} style={{padding: 0, margin: 0}}>
              {child}
            </div>
            );
        }
        return null;
      })}
    </div>
  );
});


function AdminNavbar(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const userPatients = useSelector(state => (state?.user?.patients))

  // function that on mobile devices makes the search open
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown")
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show")
      document.body.classList.add("g-navbar-search-hiding")
    }, 150)
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding")
      document.body.classList.add("g-navbar-search-hidden")
    }, 300)
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden")
    }, 500)
  }

  const classes = useStyles()
  const [patients, setPatients] = useState(userPatients && userPatients?.length ? userPatients : [])
  //   const [options,setOptions] = useState([]);

  //eslint-disable-next-line
  const [search, setSearch] = useState("")
  const [patientLoader, setPatientLoader] = useState(false)

  useEffect(() => {
    if(!patients || !patients?.length)
    dispatch(loadPatients())
  }, [])

  useEffect(() => {
    if(userPatients && userPatients?.length)
    setPatients([...userPatients])
  }, [userPatients])

  const [virtualizedPatientList, setVirtualizedPatientList] = useState([])

  useEffect(() => {
    if(patients && patients?.length){
      setVirtualizedPatientList([...patients].slice(0, 1000))
    }
  }, [patients])

  useEffect(() => {
    if(search && search.length){
      let filterOption = [...patients]?.filter((option) =>
        option?.['name']?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) || option?.['phoneNo']?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) || option?.['code']?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
      )

      setVirtualizedPatientList([...filterOption.slice(0, 1000)])
    } else if(patients && patients?.length){
      setVirtualizedPatientList([...patients].slice(0, 1000))
    } 
  }, [search])

  //   useEffect(()=>{
  // 	  document.addEventListener('mousedown',handleClickOutside)
  // 	  return () => {
  // 		  document.removeEventListener("mousedown",handleClickOutside)
  // 	  }
  //   },[])

  //   const handleClickOutside = event =>{
  // 		const {current:wrap} = wrapperRef
  // 		if(wrap && !wrap.contains(event.target))
  //   }

  return (
    <>
      <Navbar
        style={{
          padding: !location.pathname.includes("consultation")
            ? "1rem 0"
            : "0.25rem",
        }}
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-secondary": props.theme === "dark" },
          { "navbar-light bg-secondary": props.theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <div
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": props.theme === "dark" },
                { "navbar-search-dark": props.theme === "light" }
              )}
            >
              <div>
                {location.pathname.includes("consultation") ? null : (
                  <div style={{display: "flex", alignItems: "center"}}><Autocomplete
                    popupIcon={
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ fontSize: "12px" }}
                      ></i>
                    }
                    color="primary"
                    options={virtualizedPatientList}
                    getOptionLabel={(option) =>
                      option.name + " " + option?.phoneNo + " " + option.code
                    }
                    fullWidth
                    classes={classes}
                    // ListboxComponent={VirtualizedOptionsList}
                    
                    onClick={() => {
                      ReactGA.event({
                        category: "Patient Search",
                        action: "Patient Search",
                      })
                    }}
                    filterOptions={(options, {inputValue}) => {
                      return options?.filter((option) =>
                        option?.['name']?.toString()?.toLowerCase()?.includes(inputValue?.toLowerCase()) || option?.['phoneNo']?.toString()?.toLowerCase()?.includes(inputValue?.toLowerCase()) || option?.['code']?.toString()?.toLowerCase()?.includes(inputValue?.toLowerCase())
                      )
                    }}

                    onChange={(e, v) => {
                      if (v) {
                        history.push(`/admin/patientProfile/${v?._id}`)
                      } else setSearch(null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="primary"
                        placeholder={"Patient Search"}
                        fullWidth
                        style={{
                          //  backgroundColor: "white",
                          //  borderRadius: "10px",
                          width: "400%",
                        }}
                        onChange={(e) => {
                          setSearch(e?.target?.value)
                        }}
                        // variant="contained"
                      />
                    )}
                    renderOption={handleRenderOption}
                  />
                  </div>
                )}
              </div>
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: props.sidenavOpen },
                    { "sidenav-toggler-dark": true }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" style={{backgroundColor: "black"}}/>
                    <i className="sidenav-toggler-line" style={{backgroundColor: "black"}}/>
                    <i className="sidenav-toggler-line" style={{backgroundColor: "black"}}/>
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              {/* {location && location.pathname.split("/")[2] !== "CreateSlot" ? (
                <InputGroup>
                  <Input
                    style={{ fontWeight: "bolder", fontSize: "1rem" }}
                    type="select"
                    value={currOrganisation?._id}
                    onChange={(e) => {
                      dispatch({
                        type: SET_CURRENT_ORGANISATION,
                        payload: organisations?.find(
                          (ele) => ele._id === e.target.value
                        ),
                      });
                    }}
                  >
                    {organisations?.map((location, index) => (
                      <option
                        key={index}
                        label={location.locationName}
                        value={location._id}
                      ></option>
                    ))}
                  </Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      {!currOrganisation?.isOnline ? (
                        <i
                          class="fas fa-walking  fa-flip-horizontal"
                          style={{ color: "#4d63dd", fontSize: "20px" }}
                        ></i>
                      ) : (
                        <i
                          class="far fa-dot-circle"
                          style={{ color: "#49cc3d", fontSize: "20px" }}
                        ></i>
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              ) : (
                <span></span>
              )} */}
              <img style={{ width: "150px" }} src={logo} />
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={profileIcon} />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold"></span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  {/* <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem> */}
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault()
                      history.push("/admin/profile")
                    }}
                  >
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>

                  {/* <DropdownItem
                    href='#pablo'
                    onClick={(e) => {
                      e.preventDefault();
                    }}>
                    <i class='fas fa-key'></i> <span>Change Password</span>
                  </DropdownItem> */}

                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch(logout())
                      ReactGA.event({
                        category: "Logout",
                        action: "Pressed Logout Button",
                      })
                      history.push("/auth/login")
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
}
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
}

export default AdminNavbar
const handleRenderOption = (option, { inputValue }) => {
  const matches = match(option.name, inputValue)
  const parts = parse(option.name, matches)

  const otherString = ` ${option?.phoneNo},${option?.code}, ${option?.gender}`

  const otherMatches = match(otherString, String(inputValue), {
    insideWords: true,
  })
  const otherParts = parse(otherString, otherMatches)

  const highlightStyle = {
    fontWeight: 700,
    padding: "5px 1px",
    backgroundColor: "yellow",
  }

  return (
    <div>
      {parts.map((part, index) => (
        <span key={index} style={part.highlight ? highlightStyle : {}}>
          {part.text}
        </span>
      ))}
      <div>
        {otherParts.map((part, index) => (
          <span
            key={index}
            style={
              part.highlight
                ? { fontSize: 12, opacity: 0.6, ...highlightStyle }
                : { fontSize: 12, opacity: 0.7 }
            }
          >
            {part.text}
          </span>
        ))}
      </div>
    </div>
  )
}
