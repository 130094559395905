import React, { useEffect, useState } from "react"
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core"
import customAxios from "../../axios"
import {
  Add,
  AddBoxOutlined,
  BorderColor,
  CheckCircle,
  CheckCircleOutline,
  ChevronRight,
  Description,
  Edit,
  EditAttributesOutlined,
  FastForward,
  History,
  Home,
  HomeOutlined,
  Info,
  LocationCity,
  PersonPinCircle,
  PinDrop,
  Print,
} from "@material-ui/icons"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import "./sider.styles.css"
import userImage from "./../../assets/user.png"
import moment from "moment"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import { useHistory } from "react-router"
import displayAge from "../../assets/functions/displayAge"
import GenericDialogFormNew1 from "../CreatePrescription/GenericDialog/newIndex"
function Sidebar({ setActiveTab, activeTab, id, setLoading, patientData, setPatientData }) {
  // const { id } = useParams()
  // const history = useHistory()
  const [updater, setUpdater] = useState(false)
  const orgId = useSelector((state) =>
  state.auth.isDoctor
  ? state.user.organisations?.[0]?._id
  : state?.user?.user?._id
  )
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const history = useHistory()
  
  const [updatePatientOpen, setUpdatePatientOpen] = useState(false)
  const handleUpdatePatient = (data) => {
    setLoading(true)
    customAxios
      .put(`patients/update/${id}`, { ...data })
      .then((res) => {
        setLoading(false)
        if (res.data.success) {
          setUpdater((prev) => !prev)
          toast.success("Patient details updated successfully.")
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((Err) => {
        toast.error("Some error occured")
        setLoading(false)
      })
  }
  const updatePatientHeaders = [
    {
      label: "Name",
      key: "name",
      type: "text",
      md: 10
    },
    {
      key: "countryCode",
      // error: (data) => data.toString().length !== 10,
      required: true,
      md: "3",
      // placeholder: "",
      // maxLength: "3",
      value: "91",
      freeSolo: true,
      default: "91",
      prefix: "+",
      // innerWidth: "112%",
      label: "Code",
      type: "select-new-1",
      onChange: (val, state, setState) => {
        const temp = { ...state }
        temp['countryCode'] = val
        setState({ ...temp })
      },
      options: ["1", "61", "91", "962", "970", "972"]
    },
    {
      label: "Phone",
      key: "phoneNo",
      // disabled: true,
      type: "number",
      md: 7

    },
    {
      key: "ageString",
      label: "Age",
      unitOptions1: ['Years', "Months", "Days"],
      unitOptions: ['Year', 'Month', 'Day'],
      placeholder: "e.g. 10 Years",
      freeSolo: true,
      type: "unit-autocomplete",
      md: 10

    },
    {
      label: "Gender",
      key: "gender",
      type: "select-chip",
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Other", value: "other" },
      ],
      md: 12
    },
  ]
  useEffect(() => {
    customAxios
      .get(`/patients?patient_id=${id}`)
      .then((res) => {
        const responseData = res.data.data
        setPatientData({
          ...responseData,
        })
      })
      .catch((error) => console.log(error))
    //eslint-disable-next-line
  }, [id, updater])

  const patientInforoutes = [
    {
      label: "Add Vitals",
      icon: Add,
    },
    {
      label: "Medical Record",
      icon: AddBoxOutlined,
    },
    {
      label: "Patient Assessment",
      icon: Description,
    },
    {
      label: "Appointment History",
      icon: History,
    },
  ]
  const handleGenerateSticker = () => {
    setLoading(true)
    customAxios

      .get(`doctor/patient-sticker/${patientData?._id}?org_id=${orgId}`)
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          let sticker = window.open(res.data.data.url, "_blank")
          if (sticker)
            sticker.onLoad = function () {
              sticker.print()
            }
        } else {
          setLoading(false)
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Some error occured")
      })
  }
  return (
    <Grid
      container
      style={{
        height: "80vh",
        width: "300px",
        padding: "1rem",
        backgroundColor: "#fff",
        margin: "1rem 1rem 1rem 2rem",
        marginLeft: "2rem",
        boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)",
        borderRadius: "40px 0px 40px 0px",
      }}
    >
      <Grid
        item
        xs={12}
        container
        // alignItems="center"
        justifyContent="center"
        style={{ position: "relative" }}
      >
        <IconButton
          style={{
            position: "absolute",
            top: "18%",
            zIndex: "10",
            backgroundColor: "white",
            padding: "0.5rem",
            left: "55%",
            boxShadow: "0 1px 5px rgba(0, 0, 0, 0.2)"
          }}
          onClick={() => setUpdatePatientOpen(true)}
        >
          <Edit style={{ fontSize: "16px" }} color="primary" />
        </IconButton>

        <Grid
          item
          // xs={4}
          container
          style={{
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{
              objectFit: "contain",
              width: "80px",
              height: "80px",
              backgroundColor: "#5e72e4",
            }}
          >
            {patientData?.name?.split("")[0]}
          </Avatar>
        </Grid>
        <Grid item style={{textAlign: "center"}}>
          <Typography style={{ fontWeight: "700" }}>
            {patientData?.name}
          </Typography>
          <Typography>
            {displayAge(patientData)} {" "}
            {patientData.gender ? ` | ${patientData?.gender}` : ''} {" "}
            {patientData.birthDate ? ` | ${patientData?.birthDate}` : ''}
          </Typography>
          <Typography>{patientData?.phoneNo}</Typography>
          <Typography>
            <span style={{}}># {patientData?.code}</span>
          </Typography>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            marginTop: "5px",
            textAlign: "center",
          }}>
            <Button
              onClick={() => handleGenerateSticker()}
              title="Print patient details on a sticker"
              color="primary"
              style={{ borderRadius: "11px", height: "20%" }}
              variant="outlined"
            >
              Print Sticker
            </Button>

            <IconButton
              style={{ color: "green" }}
              title="Chat"
              onClick={() => {
                history.push(`/admin/chat`, { patient: patientData })
              }}
            >
              <img
                src={whatsappLogo}
                style={{
                  width: "28px",
                  objectFit: "contain",
                }}
              />{" "}
            </IconButton>
          </div>
        </Grid>
        {patientData?.address ? (
          <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
            <Typography>
              <PinDrop color="primary" fontSize="small" />{" "}
              <span style={{}}> {patientData?.address}</span>
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        <div style={{maxHeight: "100px", height: "100px"}}></div>
      </Grid> */}
      {/* <Grid item xs={12}>
      </Grid> */}
      <Grid item container xs={12}>
        <Typography style={{ fontWeight: "600", fontSize: "16px" }}>Patient Info</Typography>
        {patientInforoutes?.map((ele) => {
          const { icon: Icon } = ele
          if (!isDoctor && ele?.onlyDoctor) return null
          else
            return (
              <Grid
                item
                xs={12}
                container
                onClick={() => setActiveTab(ele.label)}
                // onMouseEnter={() => setActiveTab(ele.label)}
                alignItems="center"
                style={{
                  cursor: "pointer",
                  flexWrap: "nowrap",
                  backgroundColor: activeTab === ele.label ? "#5e72e4" : "#fff",
                  borderRadius: "11px",
                  padding: "0.5rem ",
                  // margin: "0.5rem 0 0 0 ",
                  // marginTop: "",
                  alignItems: 'center'
                }}
                justifyContent="space-between"
              >
                <Grid item container style={{alignItems: "center"}}>
                  <Icon
                    style={{
                      color: activeTab === ele.label ? "#fff" : "#5e72e4",
                    }}
                  />
                  <Typography
                    style={{
                      paddingLeft: "1rem",
                      fontWeight: "600",
                      color: activeTab === ele.label ? "#fff" : "#696969",
                    }}
                  >
                    {ele.label}
                  </Typography>
                </Grid>
                <Grid item>
                  <ChevronRight
                    style={{
                      color: activeTab === ele.label ? "#fff" : "#5e72e4",
                    }}
                  />
                </Grid>
              </Grid>
            )
        })}
      </Grid>
      <Grid item xs={12}></Grid>
      {updatePatientOpen ? (
        <GenericDialogFormNew1
          style={{ overflowY: "visible" }}
          formData={patientData}
          open={updatePatientOpen}
          handleClose={() => setUpdatePatientOpen(false)}
          dialogContent={updatePatientHeaders}
          dialogTitle="Edit Patient Details"
          handleSubmit={handleUpdatePatient}
        ></GenericDialogFormNew1>
      ) : null}
    </Grid>
  )
}

export default Sidebar
