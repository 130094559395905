import {
  TablePagination,
  Button,
  IconButton,
  Typography,
  Grid,
  useTheme,
  TextField,
  MenuItem,
} from "@material-ui/core"
import moment from "moment"

import React, { useEffect, useState } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { Card, CardHeader, Table, Row, Col } from "reactstrap"

import Input from "reactstrap/lib/Input"

import customAxios from "../../axios"
import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"
import FileUploadModal from "../Common/FileUploadModal"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import PersonAddIcon from "./../../assets/img/icons/common/personadd1.png"

import PastAppointmentModal from "./PastAppointmentModal"
import displayAge from "../../assets/functions/displayAge"
import { useDispatch, useSelector } from "react-redux"
import { loadPatients } from "../../redux/actions/user.actions"
import GenericDialogFormNew1 from "../CreatePrescription/GenericDialog/newIndex"
import { Add, Check, Delete, Replay } from "@material-ui/icons"
import AddAppointmentDialog from "../Dashboard/Home/AddAppointmentDialog"
import { Autocomplete } from "@material-ui/lab"
function AllPatinetsTable({ data, ...props }) {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const userPatients = useSelector(state => state?.user?.patients)
  const user = useSelector(state => state?.user?.user)

  const isGazaAdmin = useSelector(state => state?.user?.user?.gazaAdmin || state?.user?.user?.gazaStaff)
  const userSpecialisation = useSelector(state => state?.user?.user?.additionalDetail?.qualification)


  const [dialogLoading, setDialogLoading] = useState(false)
  const [open, setIsOpen] = useState(false)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [pastAppointmentOpen, setPastAppointmentOpen] = useState(false)
  const [csvFile, setCsvFile] = useState([])
  const [locations, setLocations] = useState([])
  const [currentLocation, setCurrentLocation] = useState("")
  const [tableData, setTableData] = useState(userPatients && userPatients?.length ? [...userPatients].reverse() : [])
  const [isLoading, setIsLoading] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  useEffect(() => {
    dispatch(loadPatients())
  }, [])

  const patientUpdateFunc = () => {
    if(userPatients && userPatients?.length){

      if(userSpecialisation) {
        customAxios
        .put("/patients/filter", {gazaSpeciality: userSpecialisation})
        .then((res1) => {
          setIsLoading(false)
          setTableData([...res1?.data?.data, ...([...userPatients])?.reverse()]);
        })
        .catch((err) => console.log(err));
      } else {
        setIsLoading(false)
        setTableData([...userPatients]?.reverse())
      }
    } else if(userSpecialisation) {
        customAxios
        .put("/patients/filter", {gazaSpeciality: userSpecialisation})
        .then((res1) => {
          setIsLoading(false)
          setTableData([...res1?.data?.data]);
        })
        .catch((err) => console.log(err));
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    patientUpdateFunc()
  }, [userPatients])


  const handleBulkUpload = (files) => {
    setDialogLoading(true)
    customAxios
      .put("/patients/upload-csv", { ...files[0] })
      .then((res) => {
        if (res.data.success) {
          setUploadOpen(false)
          toast.success("File uploaded successfully. ")
          toast.info("It might take upto 5 minutes to update the patient list.")
        } else {
          setDialogLoading(false)

          toast.error(
            "File upload failed. Please check the format of the sample file for reference."
          )
        }
        setCsvFile([])
      })
      .catch((err) => {
        setDialogLoading(false)
        toast.error("Some error occured")
      })
  }
  const getAllPatients = () => {
    if(currentLocation){
      setIsLoading(true)
  
      customAxios
        .put(`/patients/filter?org_id=${currentLocation}`,
          {}
        )
        .then((res) => {
          setIsLoading(false)
          const temp = res.data.data.reverse()

          if(userSpecialisation && !isGazaAdmin) {
            customAxios
            .put("/patients/filter", {gazaSpeciality: userSpecialisation})
            .then((res1) => {
              setTableData([...res1?.data?.data, ...temp]);
            })
            .catch((err) => console.log(err));
          } else {
            setTableData([...temp])
          }

        })
        .catch((err) => console.log(err))
    } else{
    dispatch(loadPatients())
    }
  }
  useEffect(() => {
    customAxios
      .get(`/organisation/all`)
      .then((res) => {
        if (res.data.data.length !== 0) {
          setLocations(res.data.data)
        }
      })
      .catch((err) => console.log(err))

    //eslint-disable-next-line
  }, [currentLocation])
  useEffect(() => {
    getAllPatients()
    //eslint-disable-next-line
  }, [currentLocation])


  const getPatientComplaint = (patientEle) => {
    let complaintAns = ""

    if(patientEle?.patientCharacterstic && patientEle?.patientCharacterstic?.[0] && patientEle?.patientCharacterstic?.[0]?.data && patientEle?.patientCharacterstic?.[0]?.data?.length){

      patientEle.patientCharacterstic?.[0]?.data?.map(v => {
        if(v?.question === "ما هي شكواك مع الكثير من التفاصيل؟") {
          complaintAns = v.answer
        }
        return v
      })
    }

    return complaintAns
  }

  const [staffList, setStaffList] = useState([])
  const [gzDocList, setGzDocList] = useState([])

  useEffect(() => {
    if(isGazaAdmin){
      customAxios
        .put("/staff/filter")
        .then((res) => {
          setStaffList(res.data.data);
        })
        .catch((err) => console.log(err));

      // customAxios.get("/doctor/gaza/get-doctors").then((res) => {
      //   let tempList = res?.data?.data;
      //   tempList?.reverse();
      //   setGzDocList([...tempList])
      // })
      // .catch((err) => console.log(err));
    }
  }, []);



  const [addPatientOpen, setAddPatientOpen] = useState(false)
  const [defaultTabForAddDialog, setDefaultTabForAddDialog] = useState(1)
  const [modalData, setModalData] = useState({})


  const gazaSpecializations = [  'Cardiology',  'Dentistry',  'Dermatology',  'Emergency Medicine / Trauma',  'Endocrinology',  'Family Medicine',  'Gastroenterology',  'Infectious Disease',  'Intensivist',  'Mental Health',  'Nephrology',  'Neurology',  'OB/GYN',  'Oncology',  'Ophthalmology',  'Orthopedics',  'Pediatrics',  'Physical Medicine',  'Plastics / Burns',  'Pulmonology',  'Radiology',  'Urology'];

  const [currentGazaSpec, setCurrentGazaSpec] = useState("All")

  
  
  const updateGazaParam = (key, value, patientId) => {
    customAxios.put(`/patients/gaza/update-param/${patientId}`, {[key]: value}).then(res => {
      console.log(res?.data)
      if(key === "accepted"){
        getAllPatients()
      }
    })
  }

  const deletPatient = (patientId) => {
    customAxios.put(`/patients/gaza/patient-delete/${patientId}`).then(res => {
      console.log(res?.data)
      dispatch(loadPatients())
      toast.success("Patient Deleted Successfuly")
      setIsLoading(true)
    })
  }

  // const [gazaFormPatients, setGazaFormPatients] = useState([])
  const [localAccepted, setLocalAccepted] = useState([])
  

  return (
    <Col className="mt-2">
      <Card>
        <CardHeader className="border-0">
          <Row style={{ alignItems: "center" }}>
            <Col md="3">
              <h3 className="mb-0">All Patients</h3>
            </Col>
            <Col md={isGazaAdmin ? "4" : "2"}></Col>
            {!isGazaAdmin ? <Col md="2" style={{justifyContent: "right", textAlign: "right"}}>
              <Button
                color="primary"
                startIcon={<Replay />}
                variant="text"
                onClick={() => {
                  setIsLoading(true);
                  patientUpdateFunc()
                }}
              >
                Refresh
              </Button>
            </Col> : null}
            <Col md="3">
               <Input
                type="select"
                value={currentLocation}
                onChange={(e) => {
                  setCurrentLocation(e.target.value)
                }}
              >
                {[{ locationName: "All", value: "" }, ...locations]?.map(
                  (location, index) => (
                    <option
                      key={index}
                      label={location.locationName}
                      value={location._id}
                    ></option>
                  )
                )}
              </Input> 
              {/* : <Input
                type="select"
                value={currentGazaSpec}
                onChange={(e) => {
                  setCurrentGazaSpec(e.target.value)
                }}
              >
                {["All", ...gazaSpecializations]?.map(
                  (spec, index) => (
                    <option
                      key={index}
                      label={spec}
                      value={spec}
                    ></option>
                  )
                )}
              </Input>}  */}
            </Col>
            
            <Col md="2">
              <Button
                color="primary"
                startIcon={<i className="fas fa-plus "></i>}
                fullWidth
                variant="contained"
                onClick={() => {
                  setIsOpen(true)
                }}
              >
                Add new patient
              </Button>
            </Col>{" "}
            {/* <Col md="2">
              <Button
                color="primary"
                startIcon={<i class="fas fa-file-import"></i>}
                fullWidth
                variant="contained"
                onClick={() => {
                  setUploadOpen(true)
                }}
              >
                Import Patients
              </Button>
            </Col> */}
            {/* <Col md="2">
              <Button
                color="primary"
                startIcon={<i class="fa fa-clock-o"></i>}
                fullWidth
                variant="contained"
                onClick={() => {
                  setPastAppointmentOpen(true)
                }}
              >
                Add Past Appointment
              </Button>
            </Col> */}
          </Row>
        </CardHeader>

        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              {!isGazaAdmin ? (
              <React.Fragment>
                <th scope="col">Name</th>
                <th scope="col">Code</th>
                <th scope="col">Contact Number</th>
  
                <th scope="col">Complaint</th>
  
                <th scope="col">Action</th>
              </React.Fragment>) :
                (<React.Fragment>
                  <th scope="col">Name</th>
                  <th scope="col">Contact Number</th>

                  <th scope="col">Date</th>
    
                  <th scope="col">Complaint</th>
                  <th scope="col">Status</th>
                  <th scope="col">Triaged By</th>
                  <th scope="col">Speciality Assigned</th>
                  <th scope="col">Assigned Doctor</th>
                  {/* <th scope="col">Assign Clinic</th> */}
                  <th scope="col">Notes</th>
                  {/* <th scope="col">Action</th> */}
                </React.Fragment>)
            }
            </tr>
          </thead>
          {isLoading && (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <LoadingAnimation></LoadingAnimation>
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && tableData?.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={6} style={{ textAlign: "center" }}>
                  <h3>No patient found for this location.</h3>
                </td>
              </tr>
            </tbody>
          )}
          <tbody className="list">
            {!isLoading &&
              tableData &&
              tableData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <React.Fragment key={index}>
                    {!isGazaAdmin ? <tr>
                      <th scope="row">
                        <div className="">
                        <span style={{padding: 0, color: theme.palette.primary.main, cursor: "pointer", fontSize: "15px"}} onClick={() => {
                            history.push(`/admin/patientProfile/${data._id}`)
                          }}>{data?.name}{" "}</span>
                          <br />
                          {data.age ? displayAge(data) + "," : null}{" "}
                          {data?.gender}
                        </div>
                      </th>
                      <th>{data.code}</th>
                      <td>{data?.countryCode ? `+${data?.countryCode} ` : ''}{data.phoneNo}</td>
                      {/* <td>{data?.lastVisited ? moment
                        ?.utc(data?.lastVisited)
                        ?.format("ddd, DD MMM YYYY") : "-"}</td> */}
                        <td style={{maxWidth: "100px", wordWrap: "break-word", overflowWrap: "break-word", overflow: "hidden", whiteSpace: "pre-wrap" }}>
                          {data?.gazaParam?.complaint ?? getPatientComplaint(data)}
                        </td>
                      <td>

                        <IconButton
                          style={{ color: "green" }}
                          title="Chat"
                          onClick={() => {
                            history.push(`/admin/chat`, { patient: data })
                          }}
                        >
                          <img
                            src={whatsappLogo}
                            style={{
                              width: "30px",
                              objectFit: "contain",
                            }}
                          />{" "}
                        </IconButton>
                        {(data?.gazaParam?.accepted ? !([...data?.gazaParam?.accepted]?.find(a => a === user?.['_id'])) : true) && data?.gazaForm && userSpecialisation ? (
                          <Button variant="outlined" color="primary" startIcon={<Check />} onClick={() => {
                            if(data?.gazaParam?.accepted ? !([...data?.gazaParam?.accepted]?.find(a => a === user?.['_id'])) : true)
                              updateGazaParam("accepted", user?.['_id'], data?.['_id'])
                            history.push(`/admin/chat`, { patient: data })
                            }}>
                            Accept
                          </Button>
                        ) : (
                          <Button variant="outlined" color="primary" startIcon={<Add /> } onClick={() => {
                            setModalData({
                              ...data,
                              patientId: data?._id
                            })

                            setDefaultTabForAddDialog(1)
                            setAddPatientOpen(true)
                          }}>
                            Add New Appointment
                          </Button>
                        )}
                      </td>
                    </tr> : <tr>
                      <th scope="row">
                        <div className="">
                        <span style={{padding: 0, color: theme.palette.primary.main, cursor: "pointer", fontSize: "15px"}} onClick={() => {
                            history.push(`/admin/patientProfile/${data._id}`)
                          }}>{data?.name}{" "}</span>
                          <br />
                          {data.age ? displayAge(data) + "," : null}{" "}
                          {data?.gender}
                        </div>
                      </th>
                      <td>{data?.countryCode ? `+${data?.countryCode} ` : ''}{data.phoneNo}</td>
                      <td>{data?.filledOn ? moment
                        ?.utc(data?.filledOn)
                        ?.format("ddd, DD MMM YYYY") : "-"}</td>
                      <td>
                        <TextField 
                          variant="outlined"
                          multiline
                          inputProps={{style: {fontSize: "12px", width: "100px"}}}
                          size="small"
                          value={data?.gazaParam?.complaintEdit ? data?.gazaParam?.complaint : getPatientComplaint(data)}
                          onChange={(e) => {
                            let dataIdx = (page * rowsPerPage) + index;
                            let tempTableData = [...tableData];
                            tempTableData[dataIdx] = {
                              ...tempTableData[dataIdx],
                              "gazaParam": {
                              ...(tempTableData?.[dataIdx]?.gazaParam ?? {}),
                              "complaint": e?.target?.value,
                              "complaintEdit": true
                              }
                            }

                            setTableData([...tempTableData]);
                          }}
                          onBlur={(e) => {
                            updateGazaParam("notes", e?.target?.value, data?._id)
                          }}
                        />
                      </td>
                      <td>
                      <TextField 
                          variant="outlined"
                          inputProps={{style: {fontSize: "12px"}}}
                          size="small"
                          InputProps={{
                            style: {
                              fontSize: 12,
                              width: '150px', // Set the fixed width as per your requirement
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: 12,
                            },
                          }}
                          select
                          value={data?.gazaParam?.status ?? "Not Started"}
                          onChange={(e) => {
                            let dataIdx = (page * rowsPerPage) + index;
                            let tempTableData = [...tableData];
                            tempTableData[dataIdx] = {
                              ...tempTableData[dataIdx],
                              "gazaParam": {
                              ...(tempTableData?.[dataIdx]?.gazaParam ?? {}),
                              "status": e?.target?.value
                              }
                            }

                            setTableData([...tempTableData]);
                            updateGazaParam("status", e?.target?.value, data?._id)
                          }}
                        >
                          <MenuItem style={{fontSize: "12px"}} value="Not Started">Not Started</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="In Progress">In Progress</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Seen by Doctor">Seen by Doctor</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Triage Completed">Triage Completed</MenuItem>
                        </TextField>
                      </td>
                      <td>
                      <TextField 
                          variant="outlined"
                          inputProps={{style: {fontSize: "12px"}}}
                          size="small"
                          InputProps={{
                            style: {
                              fontSize: 12,
                              width: '150px', // Set the fixed width as per your requirement
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: 12,
                            },
                          }}
                          disabled={!staffList?.length}
                          value={data?.gazaParam?.triagedBy ?? "Not Selected"}
                          onChange={(e) => {
                            let dataIdx = (page * rowsPerPage) + index;
                            let tempTableData = [...tableData];
                            tempTableData[dataIdx] = {
                              ...tempTableData[dataIdx],
                              "gazaParam": {
                              ...(tempTableData?.[dataIdx]?.gazaParam ?? {}),
                              "triagedBy": e?.target?.value
                              }
                            }

                            setTableData([...tempTableData]);
                            updateGazaParam("triagedBy", e?.target?.value, data?._id)
                          }}
                          select
                        >
                          <MenuItem style={{fontSize: "12px"}} value="Not Selected">Not Selected</MenuItem>
                          {staffList?.map(v => (
                          <MenuItem style={{fontSize: "12px"}} value={v?.name}>{v?.name}</MenuItem>
                          ))}
                        </TextField>
                      </td>
                      <td>
                      {/* <TextField 
                          variant="outlined"
                          inputProps={{style: {fontSize: "12px"}}}
                          size="small"
                          InputProps={{
                            style: {
                              fontSize: 12,
                              width: '150px', // Set the fixed width as per your requirement
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: 12,
                            },
                          }}
                          value={data?.gazaParam?.speciality ?? "Not Selected"}
                          onChange={(e) => {
                            let dataIdx = (page * rowsPerPage) + index;
                            let tempTableData = [...tableData];
                            tempTableData[dataIdx] = {
                              ...tempTableData[dataIdx],
                              "gazaParam": {
                              ...(tempTableData?.[dataIdx]?.gazaParam ?? {}),
                              "speciality": e?.target?.value
                              }
                            }

                            setTableData([...tempTableData]);
                            updateGazaParam("speciality", e?.target?.value, data?._id)
                          }}                          
                          select
                        >
                          <MenuItem style={{fontSize: "12px"}} value="Not Selected">Not Selected</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Cardiology">Cardiology</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Dentistry">Dentistry</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Dermatology / General Wound Care">Dermatology / General Wound Care</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Emergency Medicine / Trauma">Emergency Medicine / Trauma</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Endocrinology">Endocrinology</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Family Medicine">Family Medicine</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Gastroenterology">Gastroenterology</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Infectious Disease">Infectious Disease</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Intensivist / Internal Medicine">Intensivist / Internal Medicine</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Mental Health / Psychiatry">Mental Health / Psychiatry</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Nephrology">Nephrology</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Neurology">Neurology</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="OB/GYN">OB/GYN</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Oncology">Oncology</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Ophthalmology">Ophthalmology</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Orthopedics">Orthopedics</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Pediatrics">Pediatrics</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Physical Medicine / Rehabilitation">Physical Medicine / Rehabilitation</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Plastics / Burns">Plastics / Burns</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} valu>
                          <MenuItem style={{fontSize: "12px"}} value="Radiology">Radiology</MenuItem>
                          <MenuItem style={{fontSize: "12px"}} value="Urology">Urology</MenuItem>
                        </TextField> */}
                        <Autocomplete
                          multiple
                          options={gazaSpecializations}
                          getOptionLabel={(option) => option}
                          // defaultValue={[]}
                          filterSelectedOptions
                          value={data?.gazaParam?.speciality ? (typeof(data?.gazaParam?.speciality) === 'string' ? [data?.gazaParam?.speciality] : [...data?.gazaParam?.speciality]) : []}
                          onChange={(e, vals) => {
                            let dataIdx = (page * rowsPerPage) + index;
                            let tempTableData = [...tableData];
                            tempTableData[dataIdx] = {
                              ...tempTableData[dataIdx],
                              "gazaParam": {
                              ...(tempTableData?.[dataIdx]?.gazaParam ?? {}),
                              "speciality": vals
                              }
                            }

                            setTableData([...tempTableData]);
                            updateGazaParam("speciality", vals, data?._id)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              multiline
                              variant="outlined"
                              inputProps={{...params?.inputProps, style: {fontSize: "12px"}}}
                              size="small"
                              InputProps={{
                                ...params?.InputProps,
                                style: {
                                  fontSize: 12,
                                  width: 'auto', // Set the fixed width as per your requirement
                                },
                              }}
                              InputLabelProps={{
                                ...params?.InputLabelProps,
                                style: {
                                  fontSize: 12,
                                },
                              }}
                              // label="Choose items"
                              // placeholder="Items"
                            />
                          )}
                        />
                      </td>
                      <td>
                       {data?.gazaParam?.doctorName ? ((typeof(data?.gazaParam?.doctorName) === 'string' ? [data?.gazaParam?.doctorName] : [...data?.gazaParam?.doctorName])?.map((drN) => (
                        <div>
                          Dr {drN}
                        </div>
                       ))) : "--"}
                      </td>
                      {/* <td>
                        <Autocomplete 
                          disabled={data?.gazaParam?.accepted}
                          value={data?.gazaParam?.assignedTo ?? null}
                          options={[...gzDocList]}
                          style={{width: "150px"}}
                          onChange={(e, val) => {
                            let dataIdx = (page * rowsPerPage) + index;
                            let tempTableData = [...tableData];
                            tempTableData[dataIdx] = {
                              ...tempTableData[dataIdx],
                              "gazaParam": {
                              ...(tempTableData?.[dataIdx]?.gazaParam ?? {}),
                              "assignedTo": val
                              }
                            }

                            setTableData([...tempTableData]);
                            updateGazaParam("assignedTo", val, data?._id)
                          }}
                          getOptionLabel={option => option?.name}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth variant="outlined"
                            inputProps={{ ...params?.inputProps, style: {fontSize: "12px", width: "150px"}}}
                            size="small" />
                          )}
                        />
                      </td> */}
                      <td>
                      <TextField 
                          variant="outlined"
                          multiline
                          inputProps={{style: {fontSize: "12px", maxWidth: "120px", width: "120px"}}}
                          size="small"
                          value={data?.gazaParam?.notes ?? ""}
                          onChange={(e) => {
                            let dataIdx = (page * rowsPerPage) + index;
                            let tempTableData = [...tableData];
                            tempTableData[dataIdx] = {
                              ...tempTableData[dataIdx],
                              "gazaParam": {
                              ...(tempTableData?.[dataIdx]?.gazaParam ?? {}),
                              "notes": e?.target?.value
                              }
                            }

                            setTableData([...tempTableData]);
                          }}  
                          onBlur={(e) => {
                            updateGazaParam("notes", e?.target?.value, data?._id)
                          }}                          
                        />
                      </td>
                      {/* <td>
                        <IconButton onClick={() => deletPatient(data?._id)}>
                          <Delete />
                        </IconButton>
                      </td> */}
                    </tr> }
                  </React.Fragment>
                ))}
            <tr>
              <td colSpan={10}>
                <TablePagination
                  align="center"
                  rowsPerPageOptions={[
                    5,
                    10,
                    20,
                    50,
                    { label: "All", value: tableData?.length },
                  ]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, page) => {
                    setPage(page)
                  }}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(e.target.value)
                    setPage(0)
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
      <GenericDialogFormNew1
        dialogContent={
          [
            {
              label: "Name",
              key: "name",
              type: "text",
              md: 10
            },
            {
              key: "countryCode",
              // error: (data) => data.toString().length !== 10,
              required: true,
              md: "3",
              // placeholder: "",
              // maxLength: "3",
              value: "91",
              freeSolo: true,
              default: "91",
              prefix: "+",
              // innerWidth: "112%",
              label: "Code",
              type: "select-new-1",
              onChange: (val, state, setState) => {
                const temp = { ...state }
                temp['countryCode'] = val
                setState({ ...temp })
              },
              options: ["1", "61", "91", "962", "970", "972"]
            },
            {
              label: "Phone",
              key: "phoneNo",
              // disabled: true,
              type: "number",
              md: 7
        
            },
            {
              key: "ageString",
              label: "Age",
              unitOptions1: ['Years', "Months", "Days"],
              unitOptions: ['Year', 'Month', 'Day'],
              placeholder: "e.g. 10 Years",
              freeSolo: true,
              type: "unit-autocomplete",
              md: 10
        
            },
            {
              label: "Gender",
              key: "gender",
              type: "select-chip",
              options: [
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ],
              md: 12
            },
          ]}
        showRequired={true}
        open={open}
        handleClose={() => setIsOpen(false)}
        handleSubmit={(data) => {
          console.log(data)
          customAxios
            .post("/patients/create", { ...data })
            .then((res) => {
              if (res.data.success) {
                toast.success("Patient added successfully!")
                setIsOpen(false)
                dispatch(loadPatients())
              } else {
                toast.error("Some error occured")
                setIsOpen(false)
              }
            })
            .catch((err) => {
              toast.error("Some error occured")
              setIsOpen(false)
            })
        }}
        dialogTitle="Add New Patient"
      ></GenericDialogFormNew1>
      <FileUploadModal
        multiple={false}
        allowedFileTypes={".csv"}
        message={
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <Typography>
              <ul>
                <li>
                  Upload a <strong>.csv</strong> file to import exisiting
                  patients from your database
                </li>
                <li>
                  <strong>Phone number</strong> is a <strong>required</strong>{" "}
                  field
                </li>
                <li>
                  First row should be the same as mentioned in the file below
                </li>
                <li>
                  It might take <strong>upto 5 minutes</strong> to update the
                  patient list.
                </li>
              </ul>
              Download a sample csv from
              <span
                onClick={() => [
                  window.open(
                    "https://storage.googleapis.com/website-paperplane/Bulk_Upload_Sample.csv"
                  ),
                ]}
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                {" "}
                here
              </span>
            </Typography>
          </Grid>
        }
        open={uploadOpen}
        setOpen={setUploadOpen}
        state={csvFile}
        loading={dialogLoading}
        dialogTitle="Upload .csv"
        setState={setCsvFile}
        onSubmit={() => handleBulkUpload(csvFile)}
      ></FileUploadModal>
      <PastAppointmentModal 
       open={pastAppointmentOpen}
       currOrg={currentLocation}
      //  modalData={modalData}
      //  setModalData={setModalData}
       setOpen={setPastAppointmentOpen}
       setUpdater={null}
       defaultTabValue={0}
     />
     {addPatientOpen && (
        <AddAppointmentDialog
          open={addPatientOpen}
          currOrg={currentLocation}
          modalData={modalData}
          setModalData={setModalData}
          setOpen={setAddPatientOpen}
          setUpdater={() => {
                      
            history.push(
              '/admin/dashboard'
            )
          }}
          // setAllPatients={setAllPatients}
          // allPatients={allPatients}
          defaultTabValue={defaultTabForAddDialog}
        />
      )}
    </Col>
  )
}

export default withRouter(AllPatinetsTable)
