import {
    Avatar,
    Backdrop,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
    Button,
    makeStyles,
    Tooltip,
    Tabs,
    Tab,
    Box,
    Chip,
    InputAdornment,
    Card,
    CardActionArea,
    CardMedia,
  } from "@material-ui/core"
  import Linkify from "react-linkify"
  import parse from "html-react-parser"
  import React, { useEffect, useRef, useState } from "react"
  import doctorSocket from "../../../components/Dashboard/Home/AddAppointmentDialog/doctorSocket"

  import uuid from "uuid";
  
  import { colors } from "../../../assets/css/colors"
  import dashboardSocket from "../../../socket/dashboardSocket"
  import LoaderFullpage from "../../Common/LoaderFullpage"
  import moment from "moment"
  import customAxios from "../../../axios"
  import { toast } from "react-toastify"
  import GenericDialogForm from "../../CreatePrescription/GenericDialog"
  import { useHistory } from "react-router"
  import { AddBoxTwoTone, ArrowForward, Cancel, Clear, Close, Delete, Done, Edit, KeyboardReturn, MailOutline, Remove, SettingsInputComponent, TagFaces } from "@material-ui/icons"
  import whatsappBg from "./../../../assets/img/chatWindow/whatsappBg.png"
  import { Autocomplete, Skeleton, SpeedDial, SpeedDialAction, SpeedDialIcon, createFilterOptions } from "@material-ui/lab"
  import {
    convertStringToHtml,
    createElementFromHTML,
    getRandomColor,
  } from "../../../views/helper"
  import { format, whatsappRules } from "@flasd/whatsapp-formatting"
  import mixpanel from "mixpanel-browser"
import Add from "@material-ui/icons/Add"
import { useSelector } from "react-redux";

import { Document, Page } from 'react-pdf';
import GenericDialogFormNew1 from "../../CreatePrescription/GenericDialog/newIndex"

const filter = createFilterOptions();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }
  
  function ChatDrawer({ ...props }) {
    const history = useHistory()
    const {
      patient,
      setPatient,
      setOpenDrawer,
      isDoctor
    } = props

    const [updater, setUpdater] = useState(1)

    const listRef = useRef();

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      // Check if the user has scrolled down (you can adjust the threshold as needed)
      if ((scrollHeight - scrollTop - clientHeight < 2) && updater > 0) {
        setUpdater(updater+1);
        // alert(1)
      }
    }

  
    return (
    <>
        {/* <Grid item xs={12}>
            <Grid container xs={12} style={{ margin: "1rem"}}>
                {/* <Grid item xs={11}><IconButton style={{float: "right"}} onClick={() => setOpenDrawer(false)}><Close /></IconButton></Grid> 
                <Grid item container xs={12} justifyContent='center' style={{ fontFamily: "Inter !important" }}>
                    <Typography variant="h6">
                    <b>{patient?.name}</b>
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="center">
                    <Typography style={{ fontSize: "14px", fontWeight: "500" }}>{`+91-${patient.phoneNo}`}</Typography>
                </Grid>
            </Grid>
        </Grid> */}

    <Grid
      item
      container
      xs={12}
      style={{ position: "relative", backgroundColor: "#f3f3f3", maxHeight: "88vh" }}
      justifyContent="space-between"
      direction="row"
    >
      <Grid
        item
        container
        xs={12}
        // onClick={() => {
        //   mixpanel.track("Profile view through chat window")
        //   history.push(`/admin/patientProfile/${patient._id}`)
        // }}
        spacing={2}
        alignItems="center"
        style={{
          position: "sticky",
          top: "0",
          // cursor: "pointer",
          zIndex: "100",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
          background: "#f3f3f3",
          padding: "0.5rem",
          height: "70px",
        }}
      >
        <Grid item>
         <IconButton onClick={() => {setOpenDrawer(false)}}>
            <Close />
         </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Patient Info
          </Typography>
        </Grid>
      </Grid>


      <Grid
        container
        item
        xs={12}
        style={{
          padding: "0.5rem 0rem",
          margin: "1rem 0 0.3rem 0",
          overflowY: "auto",
          height: "100%",
          background: "#f3f3f3",
          overflowX: "hidden",
          // position: "absolute"
        }}
      >

        <Grid item xs={12} spacing={1} container style={{backgroundColor: "white", borderRadius: "1px", padding: "5px", height: "24vh"}}>
          {/* <MedicalReports patientData={patient}/> */}
          <PatientChatProfile patientData={patient} setPatientData={setPatient}/>
        </Grid>


        <Grid item xs={12} spacing={1} container style={{backgroundColor: "white", borderRadius: "1px", padding: "5px", height: "55vh", overflowY: "auto", marginTop: "-5vh"}}
          ref={listRef}
          onScroll={handleScroll}
        >
          <MedicalReports patientData={patient} updater={updater} setUpdater={setUpdater}/>
        </Grid>
{/* 
        <Grid item xs={12} style={{backgroundColor: "transparent", borderRadius: "1px", height: "350px", marginTop: "10px"}}>
        
        </Grid>

        <Grid item xs={12} style={{backgroundColor: "transparent", borderRadius: "1px", height: "1px", marginTop: "10px"}}>
          
        </Grid> */}
      </Grid>


      <Grid
        item
        container
        xs={12}
        spacing={1}
        alignItems="center"
        style={{
          background: "#f3f3f3",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
          height: "150px",
        //   height: isConsultation ? "140px" : "100px",
          padding: "1rem",
        }}
      ></Grid>
    </Grid>
  </>
    )
  }
  
  export default ChatDrawer
  



const PatientChatProfile = ({patientData, setPatientData}) => {


  const [updatePatientOpen, setUpdatePatientOpen] = useState(false)
  const [updater, setUpdater] = useState(0)

  const handleUpdatePatient = (data) => {
    customAxios
      .put(`patients/update/${patientData?._id}`, { ...data })
      .then((res) => {
        if (res.data.success) {
          setUpdater((prev) => prev+1)
          toast.success("Patient details updated successfully.")
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((Err) => {
        toast.error("Some error occured")
      })
  }

  const updatePatientHeaders = [
    {
      label: "Name",
      key: "name",
      type: "text",
      md: 10
    },
    {
      key: "countryCode",
      // error: (data) => data.toString().length !== 10,
      required: true,
      md: "3",
      // placeholder: "",
      // maxLength: "3",
      value: "91",
      freeSolo: true,
      default: "91",
      prefix: "+",
      // innerWidth: "112%",
      label: "Code",
      type: "select-new-1",
      onChange: (val, state, setState) => {
        const temp = { ...state }
        temp['countryCode'] = val
        setState({ ...temp })
      },
      options: ["1", "61", "91", "962", "970", "972"]
    },
    {
      label: "Phone",
      key: "phoneNo",
      // disabled: true,
      type: "number",
      md: 7

    },
    {
      key: "ageString",
      label: "Age",
      unitOptions1: ['Years', "Months", "Days"],
      unitOptions: ['Year', 'Month', 'Day'],
      placeholder: "e.g. 10 Years",
      freeSolo: true,
      type: "unit-autocomplete",
      md: 10

    },
    {
      label: "Gender",
      key: "gender",
      type: "select-chip",
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Other", value: "other" },
      ],
      md: 12
    },
  ]

  useEffect(() => {
    if(updater>0)
    customAxios
      .get(`/patients?patient_id=${patientData?._id}`)
      .then((res) => {
        const responseData = res.data.data
        setPatientData({
          ...patientData,
          ...responseData,
        })
      })
      .catch((error) => console.log(error))
    //eslint-disable-next-line
  }, [updater])

  return (
    <React.Fragment>
      <Grid item xs={12} container justifyContent="center" alignItems="center" style={{overflowY: "auto"}}>
        <Grid item>
          <Avatar style={{height: "120px", width: "120px", backgroundColor: patientData?.waChatColor, fontSize: "40px", fontWeight: "bold"}}>
            {patientData?.name?.[0]}
          </Avatar>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item container justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" style={{marginLeft: "25px", fontWeight: "bold"}}>
              {patientData?.name}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton style={{padding: 5, marginLeft: 5}} size="small" onClick={() => setUpdatePatientOpen(true)}>
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
        
        <Grid item xs={12}></Grid>
        <Grid item>
          <Typography variant="subtitle2">
            +{patientData?.countryCode ?? "91"}-{patientData?.phoneNo}
          </Typography>
        </Grid>
      </Grid>
      {updatePatientOpen ? (
        <GenericDialogFormNew1
          style={{ overflowY: "visible" }}
          formData={patientData}
          open={updatePatientOpen}
          handleClose={() => setUpdatePatientOpen(false)}
          dialogContent={updatePatientHeaders}
          dialogTitle="Edit Patient Details"
          handleSubmit={handleUpdatePatient}
        ></GenericDialogFormNew1>
      ) : null}
    </React.Fragment>
  )
}





const MedicalReports = ({ patientData, updater, setUpdater }) => {

  const [timelineData, setTimelineData] = useState([])

  const [dataLoader, setDataLoader] = useState(true)

  const [loadMore, setLoadMore] = useState(true)

  const [pageNo, setPageNo] = useState(1)

  const [addOnLoader, setAddOnLoader] = useState(false)

  useEffect(() => {
    if(loadMore && updater > 1 && !addOnLoader){
      setAddOnLoader(true)
      customAxios
        .put(`patients/analytics/${patientData?._id}?page_no=${pageNo+1}&page_size=20&thumbnail_load=${true}`)
        .then((res) => {
          setAddOnLoader(false)
          if (res.data.success) {
            let tempData = []

            Object.values(res?.data?.data)?.map((data) => {
              tempData = tempData.concat(data)
            })
            setTimelineData([...timelineData, ...tempData])
            setPageNo(pageNo+1)

            if(tempData?.length < 19){
              setLoadMore(false)
              setUpdater(0)
            }
          } else {
            toast.error("Some error occured")
          }
        })
        .catch((err) => toast.error("Some error occured"))
    }
  }, [updater])


  useEffect(() => {
    setDataLoader(true)
    customAxios
      .put(`patients/analytics/${patientData?._id}?page_no=1&page_size=20&thumbnail_load=${true}`)
      .then((res) => {
        setDataLoader(false)
        if (res.data.success) {
          let tempData = []

          Object.values(res?.data?.data)?.map((data) => {
            tempData = tempData.concat(data)
          })
          setTimelineData([...tempData])
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => toast.error("Some error occured"))
  }, [])

  return (
    <React.Fragment>
      <Grid item xs={12} spacing={2} container style={{height: "auto", maxHeight: "50px"}}>
        <Grid item>
          <Typography variant="h6">
            Documents
          </Typography>
        </Grid>
        {/* <Grid item style={{marginLeft: "auto"}}>
          <IconButton>
            <ArrowForward />
          </IconButton>
        </Grid> */}
      <Grid item container xs={12} spacing={2} style={{paddingLeft: 20}}>

        {timelineData?.length && !dataLoader ? timelineData?.map(v => (
            <React.Fragment>
            <Grid item xs={12} spacing={1} container style={{overflowY: "hidden", marginTop: "10px", overflowX: "hidden"}} alignItems="center">
              <Grid item xs={4} style={{maxHeight: "60px", maxWidth: "100%", overflow: "hidden", cursor: "pointer" }} onClick={() => window.open(v?.url)}>
                {/* <iframe src={getGoogleDocsViewerUrl(v?.url)} height={"500%"} width={"100%"} style={{opacity: "70%", pointerEvents: "none"}} loading="lazy"/> */}
                <FileCard file={v} />
              </Grid>
              <Grid item xs={8} container spacing={1}>
                <Grid item xs={12}>
                  <Typography style={{marginLeft: "10px", fontSize: "13px", fontWeight: "bold", color: "grey"}}>
                    {v?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{marginLeft: "10px", fontSize: "13px", fontWeight: "bold", color: "grey"}}>
                    {moment.utc(v?.date)?.format("hh:mm A ddd, DD MMM'YY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        )) : null}

          {addOnLoader ? <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          {/* <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} /> */}
          </Grid> : null}

        {dataLoader ? <Grid item xs={12}>
        <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "80px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />

          <Skeleton animation="wave" variant="rect" style={{marginTop: 20, marginBottom: 10, height: "80px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />
        </Grid> : null}

      </Grid>
      </Grid>
      {!dataLoader && !timelineData?.length ? <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
            <Typography variant="h6" style={{opacity: "60%", fontWeight: "bold"}}>
              No Medical Records Found!
            </Typography>
          </Grid> : null}
    </React.Fragment>
  )
}


const FileCard = ({ file }) => {
   // Function to create the Google Docs Viewer URL for the file
  const getGoogleDocsViewerUrl = (fileUrl) => {
    return `https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true`;
    // return `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
  };


  const displayFile = () => {
    if(file?.extension?.includes("image")){
      return <img src={file?.url} style={{objectFit: "none"}} />
    } else if(file?.extension?.includes("pdf")){
      return <img src={file?.thumbnailUrl} />

      // return (
      //   <iframe src={file?.url} />
      // )
    } else if(file?.extension?.includes("/vnd.open")){
      return (
        <i class="fas fa-file-excel" style={{fontSize: "50px", marginLeft: "30px"}} />
      )
    } else if(file?.extension?.includes("audio")){
      return (
        <i class="fas fa-file-audio" style={{fontSize: "50px", marginLeft: "30px"}} />
      )
    }else if(file?.extension?.includes("video")){
      return (
        <i class="fas fa-file-video" style={{fontSize: "50px", marginLeft: "30px"}} />
      )
    } else {
      return (
        <i class="fas fa-file" style={{fontSize: "50px", marginLeft: "30px"}} />
      )
    }
  }


  return (
    <React.Fragment>
      {displayFile()}
    </React.Fragment>
  );
};