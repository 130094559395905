/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/pages/dashboards/Dashboard.js"
// import Appoinments from "./views/pages/appointments/index";
import Marketing from "./views/pages/Marketing/Marketing"
import CreateSlot from "./views/pages/CreateSlot/CreateSlot"
import Login from "./views/pages/examples/Login"
import Userprofile from "./views/pages/user/Userprofile"
import Analytics from "./views/pages/Analytics/Analytics"
import AllPatinetsTable from "./components/AllPatients/allPatientsTable"
import PatientProfile from "./views/pages/patient/index"
import History from "./views/pages/patient/history"
import StaffManagement from "./components/staff/index.js"
import AllPatients from "./components/AllPatients/index.js"
import ForgetPassword from "./views/pages/examples/Forget-Password/index.js"
import Jitsy from "./views/pages/Jitsy/JitsyScreen"
import Revenue from "./views/pages/Revenue/Revenue.js"
// import Pharmacy from './views/pages/pharmacy/Pharmacy.js';
import SignUp from "./views/pages/register/Forget-Password/index.js"
import AllAppointments from "./views/pages/AllAppointments/AllAppointments"
import Settings from "./components/settings/index.js"
import CustomiseForm from "./components/CustomiseForm/index.js"
import WebsiteForm from "./components/WebsiteForm/index.js"
import Consultation from "./views/pages/Consultation/index.jsx"
import ChatDashboard from "./views/pages/ChatDashboard/index.js"
import { Badge } from "@material-ui/core"
import whatsappPng from "./assets/img/icons/common/whatsapp.png"
import marketingAnalytics from "./assets/img/icons/common/analytics1.png"
import { useEffect } from "react"
import dashboardSocket from "./socket/dashboardSocket.js"
import MarketingAnalytics from "./components/MarketingAnalytics/index.jsx"

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa-solid fa-box text-blue",
    component: Dashboard,
    layout: "/admin",
    sidebar: true,
    isStaff: true,
  },
  {
    path: "/chat",
    component: ChatDashboard,
    name: "WhatsApp",
    notifications: true,
    // icon: "fa-brands fa-whatsapp fa-2xl  text-green",
    icon: "fab fa-whatsapp text-green ",
    layout: "/admin",
    img: whatsappPng,
    sidebar: true,
  },
  {
    path: "/CreateSlot",
    name: "Timings",
    icon: "fa-solid fa-clock text-yellow",
    component: CreateSlot,
    layout: "/admin",
    // onlyDoctor: true,
    sidebar: true,
  },

  {
    path: "/patientProfile/:id",
    name: "Patient Profile",
    icon: "ni ni-calendar-grid-58 text-info",
    component: AllPatients,
    layout: "/admin",
    sidebar: false,
  },

  {
    path: "/allPatients",
    name: "All Patients",
    icon: "fa-solid fa-clipboard text-orange",
    component: AllPatinetsTable,
    layout: "/admin",
    sidebar: true,
  },
  {
    path: "/staff",
    name: "Staff",
    icon: "fa-solid fa-staff-aesculapius text-default",
    sidebar: true,
    component: StaffManagement,
    layout: "/admin",
    onlyDoctor: true,
  },

  // {
  //   path: "/Marketing",
  //   // new: true,
  //   name: "Marketing",
  //   icon: "fa-solid fa-bullhorn text-primary",
  //   component: Marketing,
  //   layout: "/admin",
  //   sidebar: true,
  // },
  // {
  //   path: "/Analytics",
  //   name: "Analytics",
  //   icon: "fa-solid fa-chart-line text-success",
  //   component: Analytics,
  //   layout: "/admin",
  //   sidebar: true,
  // },
  {
    path: "/profile",
    name: "People",
    icon: "ni ni-circle-08 text-default",
    component: Userprofile,
    layout: "/admin",
  },
  {
    path: "/patient-profile/:id",
    name: "Patient Prescription",
    icon: "ni ni-circle-08 text-default",
    component: PatientProfile,
    layout: "/admin",
  },
  {
    path: "/all-appointments",
    name: "All Appintments",
    icon: "ni ni-circle-08 text-default",
    component: AllAppointments,
    layout: "/admin",
  },
  {
    path: "/patient-profile/history",
    name: "Patient History",
    icon: "ni ni-circle-08 text-default",
    component: History,
    layout: "/admin",
  },
  {
    path: "/revenue",
    name: "Revenue",
    icon: "fa-solid fa-wallet text-info",
    component: Revenue,
    layout: "/admin",
    sidebar: true,
    isStaff: true,
  },
  
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    component: SignUp,
    layout: "/auth",
  },
  {
    path: "/forgotPassword",
    component: ForgetPassword,
    layout: "/auth",
  },

  {
    path: "/consultation/:id",
    sidebar: false,
    name: "Consultation",
    icon: "fas fa-stethoscope text-primary",
    component: Consultation,
    layout: "/admin",
    onlyDoctor: true,
  },
  {
    path: "/settings",
    sidebar: false,
    name: "Settings",
    icon: "fas fa-stethoscope text-primary",
    component: Settings,
    layout: "/admin",
    onlyDoctor: true,
  },
  {
    path: "/VideoConsultation/:id",
    sidebar: false,
    name: "Jitsy",
    icon: "fas fa-camera-compact text-primary",
    component: Jitsy,
    layout: "/meet",
  },
  {
    path: "/customise-form",
    name: "Customise Form",
    icon: "fa-solid fa-clipboard-question text-yellow",
    component: CustomiseForm,
    layout: "/admin",
    sidebar: true,
    onlyDoctor: true,
  },
  // {
  //   path: "/marketing-analytics",
  //   component: MarketingAnalytics,
  //   name: "Google Profile",
  //   // notifications: true,
  //   // icon: "fa-brands fa-whatsapp fa-2xl  text-green",
  //   icon: "fab fa-chart-simple",
  //   layout: "/admin",
  //   img: marketingAnalytics,
  //   onlyDoctor: true,
  //   sidebar: true,
  //   new: true
  // },
  // {
  //   path: "/revenue",
  //   name: "Google My Business",
  //   icon: "fa-solid fa-store text-blue",
  //   component: Revenue,
  //   layout: "/admin",
  //   onlyDoctor: true,
  //   sidebar: true,
  //   isStaff: true,
  // },
]

export default routes
