import moment from "moment"
import {
  GET_USER_LOADING,
  GET_USER_LOADED,
  SET_USER,
  REMOVE_USER,
  SET_USER_ORGANISATIONS,
  SET_CURRENT_ORGANISATION,
  SET_REMINDER_APPOINTMENT,
  REMOVE_REMINDER_APPOINTMENT,
  SET_CURRENT_DATE_TYPE,
  SET_ALL_CLINICS,
  SET_APPOINTMENT_STATUS,
  SET_DOCTOR_PRESCRIPTION_PREFERENCE,
  CLEAR_DOCTOR_PRESCRIPTION_PREFERENCE,
  SET_SORT_ORDER,
  SET_CURRENT_DATE_TYPE_VALUE,
  SET_FILTER_OPEN,
  SET_USER_LANG,
  SET_CUSTOM_VITALS,
  SET_PATIENTS,
} from "../constants/index"

const initialState = {
  user: {},
  filterOpen: true,
  userDataExists: false,
  isLoading: false,
  allClinics: true,
  organisations: [],
  currOrganisation: { _id: 0, locationName: "All Clinics" },
  appointmentReminder: {},
  currDateType: "day",
  currDateTypeValue: {
    label: "Today",
    value: moment(new Date()).toISOString(),
  },
  currStatus: "all",
  sortOrder: "descending",
  preferences: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FILTER_OPEN:
      return { ...state, filterOpen: action.payload }
    case SET_CURRENT_DATE_TYPE_VALUE:
      return { ...state, currDateTypeValue: action.payload }

    case GET_USER_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_USER_LOADED:
      return {
        ...state,
        isLoading: false,
      }
    case SET_USER:
      return {
        ...state,
        user: { ...action.payload },
        isLoading: false,
      }
    case SET_USER_LANG:
      return {
        ...state,
        user: { ...state?.user, "additionalDetail": {...state?.user?.additionalDetail, "presLang": action.payload} },
        isLoading: false,
      }
    
    case SET_USER_ORGANISATIONS:
      return {
        ...state,
        organisations: action.payload,
      }
    case SET_PATIENTS:
      return {
        ...state,
        patients: action.payload,
      }
    case SET_CURRENT_ORGANISATION:
      return {
        ...state,
        currOrganisation: action.payload,
      }
    case SET_ALL_CLINICS:
      return {
        ...state,
        allClinics: action.payload,
      }
    case SET_CUSTOM_VITALS:
      return {
        ...state,
        customVitals: action.payload,
      }
    case SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload,
      }
    case SET_APPOINTMENT_STATUS:
      return {
        ...state,
        currStatus: action.payload,
      }
    case SET_CURRENT_DATE_TYPE:
      return {
        ...state,
        currDateType: action.payload,
      }
    case SET_REMINDER_APPOINTMENT:
      return {
        ...state,
        appointmentReminder: action.payload,
      }
    case REMOVE_REMINDER_APPOINTMENT:
      return {
        ...state,
        appointmentReminder: {},
      }
    case SET_DOCTOR_PRESCRIPTION_PREFERENCE:
      return {
        ...state,
        preferences: action.payload,
      }
    case CLEAR_DOCTOR_PRESCRIPTION_PREFERENCE:
      return {
        ...state,
        preferences: [],
      }
    case REMOVE_USER:
      return initialState

    default:
      return state
  }
}
